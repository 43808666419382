.otherProductsTextWrapper {
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 0;
    display: flex;
    gap: 10px;
    padding: 10px 5%;
}

.otherProductsDivider {
    margin-left: 4%;
    margin-right: 4%;
    padding-bottom: 5%;
}
