.consulting-page-header {
    display: block;
    height: 400px;
    width: 100%;
}

.consulting-page-header-image {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
}

.consulting-content-center {
    color: white;
    position: absolute;
    top: 30%;
    left: 52%;
    width: 90%;
    transform: translate(-50%, -50%);
}
.consulting-page-header-image::before {
    display: block;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
    background-color: rgba(0, 0, 0, 0.5); /* 검은색 배경의 투명도 조절 */
}

.consulting-content-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
    margin-left: 20%;
    margin-right: 10%;
    margin-bottom: 50px;
}

.consulting-page-body-content {
    font-size: 20px;
}

.consulting-page-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* 가로로 왼쪽 정렬 */
    gap: 20px; /* 간격 조절, 원하는 값으로 변경하세요 */
    padding: 5%;
}

.consulting-page-section-div {
    width: 80%;
    margin: 0 auto;
}

.consulting-page-section-title {
    padding-top: 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* 가로로 왼쪽 정렬 */
    align-items: center;
}

.consulting-page-body {
    margin: 10%;
}
