.about-text-big {
    position: absolute;
    top: 180%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 24px;
}

.about-text-medium {
    position: absolute;
    top: 150%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 14px;
}

.about-text-small {
    position: absolute;
    top: 150%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 10px;
}

.about-text-low {
    background-color: rgb(43, 42, 42);
    color: white;
    font-size: 10px;
    padding: 20px;
}

.video-container {
    position: relative;
    overflow: hidden;
}

/* 비디오 스타일 */
video {
    width: 100%;
    height: auto;
    object-fit: cover;
    filter: brightness(0.5);
}
