.consultingBlock {
    width: 420px;
    position: relative;
    border-radius: 10px;
    overflow: hidden; /* overflow 속성 추가 */
}
.consultingBlockImage {
    width: 100%;
    height: 100%;
    vertical-align: middle;
    border-radius: 10px;
    filter: brightness(0.5);
}

.consultingBlockText {
    color: white; /* 글자 색상은 흰색으로 유지 */
    position: absolute;
    top: 50%;
    left: 60%;
    width: 100%;
    transform: translate(-50%, -50%);
    text-align: start;
}
