.master-secondary {
  align-items: flex-start;
  display: inline-flex;
  gap: 8px;
  position: relative;
}

.master-secondary .line-rounded-search {
  height: 18px !important;
  position: relative !important;
  width: 18px !important;
}

.master-secondary .class {
  height: 14px !important;
  position: relative !important;
  width: 13px !important;
}

.master-secondary .class-2 {
  height: 16px !important;
  position: relative !important;
  width: 15px !important;
}

.master-secondary .instance-node {
  height: 20px !important;
  position: relative !important;
  width: 20px !important;
}

.master-secondary .button-text {
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.master-secondary .class-3 {
  height: 16px !important;
  position: relative !important;
  width: 16px !important;
}

.master-secondary .class-4 {
  height: 14px !important;
  position: relative !important;
  width: 14px !important;
}

.master-secondary.large {
  border-radius: 44.9px;
  padding: 28px 56px;
}

.master-secondary.small {
  border-radius: 30px;
  padding: 18px 24px;
}

.master-secondary.secondary {
  border-color: var(--neutral-colorswhite);
}

.master-secondary.default {
  border-radius: 36.55px;
  padding: 24px 36px;
}

.master-secondary.smaller {
  border: 1px solid;
  border-radius: 30px;
  padding: 14px 18px;
}

.master-secondary.primary {
  background-color: var(--neutral-colorswhite);
  border-color: var(--neutral-colorscolor-600);
}

.master-secondary.small.primary {
  border: 1px solid;
}

.master-secondary.default.secondary {
  border: 1.5px solid;
  box-shadow: var(--button-shadows-button-white-01);
}

.master-secondary.small.secondary {
  border: 1.5px solid;
  box-shadow: var(--button-shadows-button-color-01);
}

.master-secondary.large.secondary {
  border: 1.5px solid;
  box-shadow: var(--button-shadows-button-white-01);
}

.master-secondary.default.primary {
  border: 1px solid;
}

.master-secondary.large.primary {
  border: 1.23px solid;
}

.master-secondary.large .button-text {
  font-family: var(--text-single-300-regular-font-family);
  font-size: var(--text-single-300-regular-font-size);
  font-style: var(--text-single-300-regular-font-style);
  font-weight: var(--text-single-300-regular-font-weight);
  letter-spacing: var(--text-single-300-regular-letter-spacing);
  line-height: var(--text-single-300-regular-line-height);
}

.master-secondary.small .button-text {
  font-family: var(--text-single-100-regular-font-family);
  font-size: var(--text-single-100-regular-font-size);
  font-style: var(--text-single-100-regular-font-style);
  font-weight: var(--text-single-100-regular-font-weight);
  letter-spacing: var(--text-single-100-regular-letter-spacing);
  line-height: var(--text-single-100-regular-line-height);
}

.master-secondary.secondary .button-text {
  color: var(--neutral-colorswhite);
}

.master-secondary.default .button-text {
  font-family: var(--text-single-200-regular-font-family);
  font-size: var(--text-single-200-regular-font-size);
  font-style: var(--text-single-200-regular-font-style);
  font-weight: var(--text-single-200-regular-font-weight);
  letter-spacing: var(--text-single-200-regular-letter-spacing);
  line-height: var(--text-single-200-regular-line-height);
}

.master-secondary.smaller .button-text {
  font-family: "DM Sans", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 14px;
  margin-top: -1px;
}

.master-secondary.primary .button-text {
  color: var(--neutral-colorsheadings-black);
}

.master-secondary.small.primary .button-text {
  margin-top: -1px;
}

.master-secondary.default.secondary .button-text {
  margin-top: -1.5px;
}

.master-secondary.small.secondary .button-text {
  margin-top: -1.5px;
}

.master-secondary.large.secondary .button-text {
  margin-top: -1.5px;
}

.master-secondary.default.primary .button-text {
  margin-top: -1px;
}

.master-secondary.large.primary .button-text {
  margin-top: -1.23px;
}