.detail-frame {
    align-items: center; /* 세로 중앙 정렬 */
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    width: 90%;
    margin: 0 auto; /* 가로 중앙 정렬 */
}

.detail-frame .detail-div {
    align-items: center;
    align-self: stretch;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 100%;
    margin: 0 auto; /* 가로 중앙 정렬 */
}

.detail-frame .detail-details {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
    width: 100%;
}

.detail-frame .detail-div-wrapper {
    align-items: center;
    background-color: #000000;
    display: flex;
    gap: 10px;
    justify-content: center;
    padding: 4px 0px;
    position: relative;
    padding: 10px;
}

.detail-frame .detail-text-wrapper {
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 21px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.detail-frame .detail-text-wrapper-2 {
    color: #000000;

    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 33px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.detail-frame .detail-vector {
    align-items: flex-end;
    align-self: stretch;
    height: 1px;
    margin-bottom: -0.5px;
    object-fit: cover;
    position: relative;
    width: 100%;
}

.detail-frame .detail-div-2 {
    display: inline-flex;
    flex: 0 0 auto;
    gap: 60px;
    position: relative;
    width: 100%;
    margin: 0 auto; /* 가로 중앙 정렬 */
}

.detail-frame .detail-frame-image {
    object-fit: cover;
    position: relative;
}

.detail-frame .detail-div-3 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 24px;
    width: 70%;
    position: relative;
    white-space: normal; /* 텍스트 내용이 줄바꿈되도록 설정 */
}

.detail-frame .detail-p {
    color: #6d6d6d;
    text-overflow: ellipsis;
    font-size: 16px;
    text-align: justify;
    font-weight: 400;
    letter-spacing: 0;
    margin-top: -1px;
    word-break: break-all;
    white-space: normal; /* 텍스트 내용이 줄바꿈되도록 설정 */
}

.detail-frame .detail-stepper {
    align-items: center;
    background-color: var(--white);
    border-radius: 12px;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    overflow: hidden;
    padding: 12px 16px;
    position: relative;
    width: 122px;
}

.detail-frame .detail-text-wrapper-3 {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
}

.detail-frame .detail-div-4 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 16px;
    position: relative;
}

.detail-frame .detail-text-wrapper-4 {
    color: #000000;

    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 30px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.detail-frame .detail-text-wrapper-5 {
    color: #000000;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 36px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.detail-frame .detail-div-5 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
    white-space: normal; /* 텍스트 내용이 줄바꿈되도록 설정 */
    width: fit-content;
}

.responsive {
    flex-direction: column;
}

.detail-frame .detail-master-secondary-button {
    align-items: center !important;
    background-color: #e4e4e4 !important;
    border: unset !important;
    border-color: unset !important;
    border-radius: 12px !important;
    display: flex !important;
    justify-content: center !important;
    padding: 16px 40px !important;
    width: 200px !important;
}

.detail-frame .detail-master-secondary-instance {
    color: #000000 !important;
    font-size: 16px !important;
    font-style: unset !important;
    font-weight: 700 !important;
    letter-spacing: 0 !important;
    line-height: 18px !important;
}

.detail-frame .detail-master-secondary-button-instance {
    align-items: center !important;
    background-color: #000000 !important;
    border: unset !important;
    border-color: unset !important;
    border-radius: 12px !important;
    display: flex !important;
    justify-content: center !important;
    padding: 16px 40px !important;
    width: 200px !important;
}

.detail-frame .detail-design-component-instance-node {
    color: #ffffff !important;
    font-size: 16px !important;
    font-style: unset !important;
    font-weight: 700 !important;
    letter-spacing: 0 !important;
    line-height: 18px !important;
}
