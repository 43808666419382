/*!

 =========================================================
 * Now UI Kit React - v1.5.2 based on Now UI Kit - v1.3.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/now-ui-kit-react
 * Copyright 2023 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/now-ui-kit-react/blob/main/LICENSE.md)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
/*     brand Colors              */
/*      light colors         */
/* ========================================================================
 * bootstrap-switch - v3.3.2
 * http://www.bootstrap-switch.org
 * ========================================================================
 * Copyright 2012-2013 Mattia Larentis
 * http://www.apache.org/licenses/LICENSE-2.0
 */
.bootstrap-switch {
    display: inline-block;
    direction: ltr;
    cursor: pointer;
    border-radius: 30px;
    border: 0;
    position: relative;
    text-align: left;
    margin-bottom: 10px;
    line-height: 8px;
    width: 59px !important;
    height: 22px;
    outline: none;
    z-index: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -webkit-transition: border-color ease-in-out 0.15s,
        box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    margin-right: 20px;
    background: rgba(44, 44, 44, 0.2);
}

.bootstrap-switch .bootstrap-switch-container {
    display: inline-flex;
    top: 0;
    height: 22px;
    border-radius: 4px;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    width: 100px !important;
}

.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off,
.bootstrap-switch .bootstrap-switch-label {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block !important;
    height: 100%;
    color: #fff;
    padding: 6px 12px;
    font-size: 11px;
    text-indent: -5px;
    line-height: 15px;
    -webkit-transition: 0.25s ease-out;
    transition: 0.25s ease-out;
}

.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off {
    text-align: center;
    z-index: 1;
    float: left;
    line-height: 11px;
    width: 50% !important;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-brown,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-brown {
    color: #fff;
    background: #f96332;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-blue,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-blue {
    color: #fff;
    background: #2ca8ff;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-green,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-green {
    color: #fff;
    background: #18ce0f;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-orange,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-orange {
    background: #ffb236;
    color: #fff;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-red,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-red {
    color: #fff;
    background: #ff3636;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default {
    color: #fff;
}

.bootstrap-switch .bootstrap-switch-label {
    text-align: center;
    z-index: 100;
    color: #333333;
    background: #ffffff;
    width: 22px !important;
    height: 22px !important;
    margin: 0px -11px;
    border-radius: 20px;
    position: absolute;
    float: left;
    top: 0;
    left: 50%;
    padding: 0;
    box-shadow: 0 1px 11px rgba(0, 0, 0, 0.25);
}

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-label {
    background-color: rgba(23, 23, 23, 0.4);
}

.bootstrap-switch.bootstrap-switch-on:hover .bootstrap-switch-label {
    width: 27px !important;
    margin-left: -16px;
}

.bootstrap-switch.bootstrap-switch-off:hover .bootstrap-switch-label {
    width: 27px !important;
    margin-left: -11px;
}

.bootstrap-switch .bootstrap-switch-handle-on {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
}

.bootstrap-switch .bootstrap-switch-handle-off {
    text-indent: 6px;
}

.bootstrap-switch input[type="radio"],
.bootstrap-switch input[type="checkbox"] {
    position: absolute !important;
    top: 0;
    left: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    z-index: -1;
}

.bootstrap-switch input[type="radio"].form-control,
.bootstrap-switch input[type="checkbox"].form-control {
    height: auto;
}

.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-label {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
}

.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-label {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
}

.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-label {
    padding: 6px 16px;
    font-size: 18px;
    line-height: 1.33;
}

.bootstrap-switch.bootstrap-switch-disabled,
.bootstrap-switch.bootstrap-switch-readonly,
.bootstrap-switch.bootstrap-switch-indeterminate {
    cursor: default !important;
}

.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-label {
    opacity: 0.5;
    filter: alpha(opacity=50);
    cursor: default !important;
}

.bootstrap-switch.bootstrap-switch-animate .bootstrap-switch-container {
    -webkit-transition: margin-left 0.5s;
    transition: margin-left 0.5s;
}

.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-on {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
}

.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-off {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-container {
    margin-left: -2px !important;
}

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-container {
    margin-left: -39px !important;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-label:before {
    background-color: #ffffff;
}

.bootstrap-switch.bootstrap-switch-on
    .bootstrap-switch-red
    ~ .bootstrap-switch-default {
    background-color: #ff3636;
}

.bootstrap-switch.bootstrap-switch-on
    .bootstrap-switch-orange
    ~ .bootstrap-switch-default {
    background-color: #ffb236;
}

.bootstrap-switch.bootstrap-switch-on
    .bootstrap-switch-green
    ~ .bootstrap-switch-default {
    background-color: #18ce0f;
}

.bootstrap-switch.bootstrap-switch-on
    .bootstrap-switch-brown
    ~ .bootstrap-switch-default {
    background-color: #f96332;
}

.bootstrap-switch.bootstrap-switch-on
    .bootstrap-switch-blue
    ~ .bootstrap-switch-default {
    background-color: #2ca8ff;
}

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-red,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-brown,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-blue,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-orange,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-green {
    background-color: #e3e3e3;
}

.bootstrap-switch-off .bootstrap-switch-handle-on {
    opacity: 0;
}

.bootstrap-switch-on .bootstrap-switch-handle-off {
    opacity: 0;
}

/*! nouislider - 13.1.4 */
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    -ms-touch-action: none;
    touch-action: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.noUi-target {
    position: relative;
    direction: ltr;
}

.noUi-base,
.noUi-connects {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
}

/* Wrapper for all connect elements.
 */
.noUi-connects {
    overflow: hidden;
    z-index: 0;
}

.noUi-connect,
.noUi-origin {
    will-change: transform;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    -ms-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
}

/* Offset direction
 */
html:not([dir="rtl"]) .noUi-horizontal .noUi-origin {
    left: auto;
    right: 0;
}

/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
    width: 0;
}

.noUi-horizontal .noUi-origin {
    height: 0;
}

.noUi-handle {
    position: absolute;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
    -webkit-transition: transform 0.3s;
    transition: transform 0.3s;
}

.noUi-state-drag * {
    cursor: inherit !important;
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
    height: 1px;
}

.noUi-horizontal .noUi-handle {
    border-radius: 50%;
    background-color: #ffffff;
    box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.2);
    height: 15px;
    width: 15px;
    cursor: pointer;
    margin-top: -7px;
    outline: none;
}

.noUi-vertical {
    width: 18px;
}

.noUi-vertical .noUi-handle {
    width: 28px;
    height: 34px;
    left: -6px;
    top: -17px;
}

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
    right: -15px;
    left: auto;
}

/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
    background-color: rgba(182, 182, 182, 0.3);
    border-radius: 3px;
}

.noUi-connects {
    border-radius: 3px;
}

.noUi-connect {
    background: #888888;
    border-radius: 3px;
    -webkit-transition: background 450ms;
    transition: background 450ms;
}

/* Handles and cursors;
 */
.noUi-draggable {
    cursor: ew-resize;
}

.noUi-vertical .noUi-draggable {
    cursor: ns-resize;
}

.noUi-handle {
    border-radius: 3px;
    background: #fff;
    cursor: default;
    box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;
    -webkit-transition: 300ms ease 0s;
    -moz-transition: 300ms ease 0s;
    -ms-transition: 300ms ease 0s;
    -o-transform: 300ms ease 0s;
    transition: 300ms ease 0s;
}

.noUi-active {
    -webkit-transform: scale3d(1.5, 1.5, 1);
    -moz-transform: scale3d(1.5, 1.5, 1);
    -ms-transform: scale3d(1.5, 1.5, 1);
    -o-transform: scale3d(1.5, 1.5, 1);
    transform: scale3d(1.5, 1.5, 1);
}

/* Handle stripes;
 */
.noUi-handle:before,
.noUi-handle:after {
    display: none;
}

.noUi-handle:after {
    left: 17px;
}

.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
    width: 14px;
    height: 1px;
    left: 6px;
    top: 14px;
}

.noUi-vertical .noUi-handle:after {
    top: 17px;
}

/* Disabled state;
 */
[disabled] .noUi-connect {
    background: #b8b8b8;
}

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
    cursor: not-allowed;
}

/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.noUi-pips {
    position: absolute;
    color: #999;
}

/* Values;
 *
 */
.noUi-value {
    position: absolute;
    white-space: nowrap;
    text-align: center;
}

.noUi-value-sub {
    color: #ccc;
    font-size: 10px;
}

/* Markings;
 *
 */
.noUi-marker {
    position: absolute;
    background: #ccc;
}

.noUi-marker-sub {
    background: #aaa;
}

.noUi-marker-large {
    background: #aaa;
}

/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
    padding: 10px 0;
    height: 80px;
    top: 100%;
    left: 0;
    width: 100%;
}

.noUi-value-horizontal {
    -webkit-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
}

.noUi-rtl .noUi-value-horizontal {
    -webkit-transform: translate(50%, 50%);
    transform: translate(50%, 50%);
}

.noUi-marker-horizontal.noUi-marker {
    margin-left: -1px;
    width: 2px;
    height: 5px;
}

.noUi-marker-horizontal.noUi-marker-sub {
    height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
    height: 15px;
}

/* Vertical layout;
 *
 */
.noUi-pips-vertical {
    padding: 0 10px;
    height: 100%;
    top: 0;
    left: 100%;
}

.noUi-value-vertical {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%, 0);
    padding-left: 25px;
}

.noUi-rtl .noUi-value-vertical {
    -webkit-transform: translate(0, 50%);
    transform: translate(0, 50%);
}

.noUi-marker-vertical.noUi-marker {
    width: 5px;
    height: 2px;
    margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
    width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
    width: 15px;
}

.noUi-tooltip {
    display: block;
    position: absolute;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    background: #fff;
    color: #000;
    padding: 5px;
    text-align: center;
    white-space: nowrap;
}

.noUi-horizontal .noUi-tooltip {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    left: 50%;
    bottom: 120%;
}

.noUi-vertical .noUi-tooltip {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    top: 50%;
    right: 120%;
}

.slider.slider-neutral .noUi-connect,
.slider.slider-neutral.noUi-connect {
    background-color: #ffffff;
}

.slider.slider-neutral.noUi-target {
    background-color: rgba(255, 255, 255, 0.3);
}

.slider.slider-neutral .noUi-handle {
    background-color: #ffffff;
}

.slider.slider-primary .noUi-connect,
.slider.slider-primary.noUi-connect {
    background-color: #f96332;
}

.slider.slider-primary.noUi-target {
    background-color: rgba(249, 99, 50, 0.3);
}

.slider.slider-primary .noUi-handle {
    background-color: #f96332;
}

.slider.slider-info .noUi-connect,
.slider.slider-info.noUi-connect {
    background-color: #2ca8ff;
}

.slider.slider-info.noUi-target {
    background-color: rgba(44, 168, 255, 0.3);
}

.slider.slider-info .noUi-handle {
    background-color: #2ca8ff;
}

.slider.slider-success .noUi-connect,
.slider.slider-success.noUi-connect {
    background-color: #18ce0f;
}

.slider.slider-success.noUi-target {
    background-color: rgba(24, 206, 15, 0.3);
}

.slider.slider-success .noUi-handle {
    background-color: #18ce0f;
}

.slider.slider-warning .noUi-connect,
.slider.slider-warning.noUi-connect {
    background-color: #ffb236;
}

.slider.slider-warning.noUi-target {
    background-color: rgba(255, 178, 54, 0.3);
}

.slider.slider-warning .noUi-handle {
    background-color: #ffb236;
}

.slider.slider-danger .noUi-connect,
.slider.slider-danger.noUi-connect {
    background-color: #ff3636;
}

.slider.slider-danger.noUi-target {
    background-color: rgba(255, 54, 54, 0.3);
}

.slider.slider-danger .noUi-handle {
    background-color: #ff3636;
}

/*!
 * Datepicker for Bootstrap v1.7.0-dev (https://github.com/uxsolutions/bootstrap-datepicker)
 *
 * Licensed under the Apache License v2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 */
.datepicker {
    padding: 8px 6px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    direction: ltr;
    -webkit-transform: translate3d(0, -40px, 0);
    -moz-transform: translate3d(0, -40px, 0);
    -o-transform: translate3d(0, -40px, 0);
    -ms-transform: translate3d(0, -40px, 0);
    transform: translate3d(0, -40px, 0);
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
        opacity 0.3s ease 0s, height 0s linear 0.35s;
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: hidden;
    display: block;
    width: 254px;
    max-width: 254px;
}
.datepicker.dropdown-menu:before {
    display: none;
}
.datepicker.datepicker-primary {
    background-color: #f96332;
}
.datepicker.datepicker-primary th,
.datepicker.datepicker-primary .day div,
.datepicker.datepicker-primary table tr td span {
    color: #ffffff;
}
.datepicker.datepicker-primary:after {
    border-bottom-color: #f96332;
}
.datepicker.datepicker-primary.datepicker-orient-top:after {
    border-top-color: #f96332;
}
.datepicker.datepicker-primary .dow {
    color: rgba(255, 255, 255, 0.8);
}
.datepicker.datepicker-primary table tr td.old div,
.datepicker.datepicker-primary table tr td.new div,
.datepicker.datepicker-primary table tr td span.old,
.datepicker.datepicker-primary table tr td span.new {
    color: rgba(255, 255, 255, 0.4);
}
.datepicker.datepicker-primary table tr td span:hover,
.datepicker.datepicker-primary table tr td span.focused {
    background: rgba(255, 255, 255, 0.1);
}
.datepicker.datepicker-primary .datepicker-switch:hover,
.datepicker.datepicker-primary .prev:hover,
.datepicker.datepicker-primary .next:hover,
.datepicker.datepicker-primary tfoot tr th:hover {
    background: rgba(255, 255, 255, 0.2);
}
.datepicker.datepicker-primary table tr td.active div,
.datepicker.datepicker-primary table tr td.active:hover div,
.datepicker.datepicker-primary table tr td.active.disabled div,
.datepicker.datepicker-primary table tr td.active.disabled:hover div {
    background-color: #ffffff;
    color: #f96332;
}
.datepicker.datepicker-primary table tr td.day:hover div,
.datepicker.datepicker-primary table tr td.day.focused div {
    background: rgba(255, 255, 255, 0.2);
}
.datepicker.datepicker-primary table tr td.active:hover div,
.datepicker.datepicker-primary table tr td.active:hover:hover div,
.datepicker.datepicker-primary table tr td.active.disabled:hover div,
.datepicker.datepicker-primary table tr td.active.disabled:hover:hover div,
.datepicker.datepicker-primary table tr td.active:active div,
.datepicker.datepicker-primary table tr td.active:hover:active div,
.datepicker.datepicker-primary table tr td.active.disabled:active div,
.datepicker.datepicker-primary table tr td.active.disabled:hover:active div,
.datepicker.datepicker-primary table tr td.active.active div,
.datepicker.datepicker-primary table tr td.active:hover.active div,
.datepicker.datepicker-primary table tr td.active.disabled.active div,
.datepicker.datepicker-primary table tr td.active.disabled:hover.active div,
.datepicker.datepicker-primary table tr td.active.disabled div,
.datepicker.datepicker-primary table tr td.active:hover.disabled div,
.datepicker.datepicker-primary table tr td.active.disabled.disabled div,
.datepicker.datepicker-primary table tr td.active.disabled:hover.disabled div,
.datepicker.datepicker-primary table tr td.active[disabled] div,
.datepicker.datepicker-primary table tr td.active:hover[disabled] div,
.datepicker.datepicker-primary table tr td.active.disabled[disabled] div,
.datepicker.datepicker-primary table tr td.active.disabled:hover[disabled] div,
.datepicker.datepicker-primary table tr td span.active:hover,
.datepicker.datepicker-primary table tr td span.active:hover:hover,
.datepicker.datepicker-primary table tr td span.active.disabled:hover,
.datepicker.datepicker-primary table tr td span.active.disabled:hover:hover,
.datepicker.datepicker-primary table tr td span.active:active,
.datepicker.datepicker-primary table tr td span.active:hover:active,
.datepicker.datepicker-primary table tr td span.active.disabled:active,
.datepicker.datepicker-primary table tr td span.active.disabled:hover:active,
.datepicker.datepicker-primary table tr td span.active.active,
.datepicker.datepicker-primary table tr td span.active:hover.active,
.datepicker.datepicker-primary table tr td span.active.disabled.active,
.datepicker.datepicker-primary table tr td span.active.disabled:hover.active,
.datepicker.datepicker-primary table tr td span.active.disabled,
.datepicker.datepicker-primary table tr td span.active:hover.disabled,
.datepicker.datepicker-primary table tr td span.active.disabled.disabled,
.datepicker.datepicker-primary table tr td span.active.disabled:hover.disabled,
.datepicker.datepicker-primary table tr td span.active[disabled],
.datepicker.datepicker-primary table tr td span.active:hover[disabled],
.datepicker.datepicker-primary table tr td span.active.disabled[disabled],
.datepicker.datepicker-primary
    table
    tr
    td
    span.active.disabled:hover[disabled] {
    background-color: #ffffff;
}
.datepicker.datepicker-primary table tr td span.active:hover,
.datepicker.datepicker-primary table tr td span.active:hover:hover,
.datepicker.datepicker-primary table tr td span.active.disabled:hover,
.datepicker.datepicker-primary table tr td span.active.disabled:hover:hover,
.datepicker.datepicker-primary table tr td span.active:active,
.datepicker.datepicker-primary table tr td span.active:hover:active,
.datepicker.datepicker-primary table tr td span.active.disabled:active,
.datepicker.datepicker-primary table tr td span.active.disabled:hover:active,
.datepicker.datepicker-primary table tr td span.active.active,
.datepicker.datepicker-primary table tr td span.active:hover.active,
.datepicker.datepicker-primary table tr td span.active.disabled.active,
.datepicker.datepicker-primary table tr td span.active.disabled:hover.active,
.datepicker.datepicker-primary table tr td span.active.disabled,
.datepicker.datepicker-primary table tr td span.active:hover.disabled,
.datepicker.datepicker-primary table tr td span.active.disabled.disabled,
.datepicker.datepicker-primary table tr td span.active.disabled:hover.disabled,
.datepicker.datepicker-primary table tr td span.active[disabled],
.datepicker.datepicker-primary table tr td span.active:hover[disabled],
.datepicker.datepicker-primary table tr td span.active.disabled[disabled],
.datepicker.datepicker-primary
    table
    tr
    td
    span.active.disabled:hover[disabled] {
    color: #f96332;
}

.datepicker-inline {
    width: 220px;
}

.datepicker.datepicker-rtl {
    direction: rtl;
}

.datepicker.datepicker-rtl.dropdown-menu {
    left: auto;
}

.datepicker.datepicker-rtl table tr td span {
    float: right;
}

.datepicker-dropdown {
    top: 0;
    left: 0;
}

.datepicker-dropdown:before {
    content: "";
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    position: absolute;
}

.datepicker-dropdown:after {
    content: "";
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-top: 0;
    position: absolute;
}

.datepicker-dropdown.datepicker-orient-left:before {
    left: 6px;
}

.datepicker-dropdown.datepicker-orient-left:after {
    left: 7px;
}

.datepicker-dropdown.datepicker-orient-right:before {
    right: 6px;
}

.datepicker-dropdown.datepicker-orient-right:after {
    right: 7px;
}

.datepicker-dropdown.datepicker-orient-bottom:before {
    top: -7px;
}

.datepicker-dropdown.datepicker-orient-bottom:after {
    top: -6px;
}

.datepicker-dropdown.datepicker-orient-top:before {
    bottom: -7px;
    border-bottom: 0;
    border-top: 7px solid transparent;
}

.datepicker-dropdown.datepicker-orient-top:after {
    bottom: -6px;
    border-bottom: 0;
    border-top: 6px solid #fff;
}

.datepicker table {
    margin: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 241px;
    max-width: 241px;
}

.datepicker .day div,
.datepicker th {
    -webkit-transition: all 300ms ease 0s;
    -moz-transition: all 300ms ease 0s;
    -o-transition: all 300ms ease 0s;
    -ms-transition: all 300ms ease 0s;
    transition: all 300ms ease 0s;
    text-align: center;
    width: 30px;
    height: 30px;
    line-height: 2.2;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 50%;
    font-weight: 300;
    font-size: 14px;
    border: none;
    z-index: 1;
    position: relative;
    cursor: pointer;
}

.datepicker th {
    color: #f96332;
}

.table-condensed > tbody > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > thead > tr > th {
    padding: 2px;
    text-align: center;
    cursor: pointer;
}

.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
    background-color: transparent;
}

.datepicker table tr td.day:hover div,
.datepicker table tr td.day.focused div {
    background: #eee;
    cursor: pointer;
}

.datepicker table tr td.old,
.datepicker table tr td.new {
    color: #888888;
}

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
    background: none;
    color: #888888;
    cursor: default;
}

.datepicker table tr td.highlighted {
    background: #d9edf7;
    border-radius: 0;
}

.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
    background-color: #fde19a;
    background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-image: -webkit-gradient(
        linear,
        0 0,
        0 100%,
        from(#fdd49a),
        to(#fdf59a)
    );
    background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
    border-color: #fdf59a #fdf59a #fbed50;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    color: #000;
}

.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today[disabled],
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today.disabled:hover[disabled] {
    background-color: #fdf59a;
}

.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active {
    background-color: #fbf069 \9;
}

.datepicker table tr td.today:hover:hover {
    color: #000;
}

.datepicker table tr td.today.active:hover {
    color: #fff;
}

.datepicker table tr td.range,
.datepicker table tr td.range:hover,
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:hover {
    background: #eee;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.datepicker table tr td.range.today,
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:hover {
    background-color: #f3d17a;
    background-image: -moz-linear-gradient(to bottom, #f3c17a, #f3e97a);
    background-image: -ms-linear-gradient(to bottom, #f3c17a, #f3e97a);
    background-image: -webkit-gradient(
        linear,
        0 0,
        0 100%,
        from(#f3c17a),
        to(#f3e97a)
    );
    background-image: -webkit-linear-gradient(to bottom, #f3c17a, #f3e97a);
    background-image: -o-linear-gradient(to bottom, #f3c17a, #f3e97a);
    background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
    border-color: #f3e97a #f3e97a #edde34;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today:hover:hover,
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today.disabled:hover:hover,
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today:hover.disabled,
.datepicker table tr td.range.today.disabled.disabled,
.datepicker table tr td.range.today.disabled:hover.disabled,
.datepicker table tr td.range.today[disabled],
.datepicker table tr td.range.today:hover[disabled],
.datepicker table tr td.range.today.disabled[disabled],
.datepicker table tr td.range.today.disabled:hover[disabled] {
    background-color: #f3e97a;
}

.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active {
    background-color: #efe24b \9;
}

.datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover {
    background-color: #9e9e9e;
    background-image: -moz-linear-gradient(to bottom, #b3b3b3, #808080);
    background-image: -ms-linear-gradient(to bottom, #b3b3b3, #808080);
    background-image: -webkit-gradient(
        linear,
        0 0,
        0 100%,
        from(#b3b3b3),
        to(#808080)
    );
    background-image: -webkit-linear-gradient(to bottom, #b3b3b3, #808080);
    background-image: -o-linear-gradient(to bottom, #b3b3b3, #808080);
    background-image: linear-gradient(to bottom, #b3b3b3, #808080);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
    border-color: #808080 #808080 #595959;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td.selected:hover,
.datepicker table tr td.selected:hover:hover,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected.disabled:hover:hover,
.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:hover.active,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected:hover.disabled,
.datepicker table tr td.selected.disabled.disabled,
.datepicker table tr td.selected.disabled:hover.disabled,
.datepicker table tr td.selected[disabled],
.datepicker table tr td.selected:hover[disabled],
.datepicker table tr td.selected.disabled[disabled],
.datepicker table tr td.selected.disabled:hover[disabled] {
    background-color: #808080;
}

.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:hover.active {
    background-color: #666666 \9;
}

.datepicker table tr td.active div,
.datepicker table tr td.active:hover div,
.datepicker table tr td.active.disabled div,
.datepicker table tr td.active.disabled:hover div {
    background-color: #f96332;
    color: #ffffff;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
}

.datepicker table tr td.active:hover div,
.datepicker table tr td.active:hover:hover div,
.datepicker table tr td.active.disabled:hover div,
.datepicker table tr td.active.disabled:hover:hover div,
.datepicker table tr td.active:active div,
.datepicker table tr td.active:hover:active div,
.datepicker table tr td.active.disabled:active div,
.datepicker table tr td.active.disabled:hover:active div,
.datepicker table tr td.active.active div,
.datepicker table tr td.active:hover.active div,
.datepicker table tr td.active.disabled.active div,
.datepicker table tr td.active.disabled:hover.active div,
.datepicker table tr td.active.disabled div,
.datepicker table tr td.active:hover.disabled div,
.datepicker table tr td.active.disabled.disabled div,
.datepicker table tr td.active.disabled:hover.disabled div,
.datepicker table tr td.active[disabled] div,
.datepicker table tr td.active:hover[disabled] div,
.datepicker table tr td.active.disabled[disabled] div,
.datepicker table tr td.active.disabled:hover[disabled] div {
    background-color: #f96332;
}

.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active {
    background-color: #003399 \9;
}

.datepicker table tr td span {
    display: block;
    width: 41px;
    height: 41px;
    line-height: 41px;
    float: left;
    margin: 1%;
    font-size: 14px;
    cursor: pointer;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.datepicker table tr td span:hover,
.datepicker table tr td span.focused {
    background: #eee;
}

.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
    background: none;
    color: #888888;
    cursor: default;
}

.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
    color: #fff;
    background-color: #f96332;
}

.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active[disabled],
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active.disabled:hover[disabled] {
    background-color: #f96332;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
}

.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active {
    background-color: #003399 \9;
}

.datepicker table tr td span.old,
.datepicker table tr td span.new {
    color: #888888;
}

.datepicker .datepicker-switch {
    width: auto;
    border-radius: 0.1875rem;
}

.datepicker .datepicker-switch,
.datepicker .prev,
.datepicker .next,
.datepicker tfoot tr th {
    cursor: pointer;
}

.datepicker .prev,
.datepicker .next {
    width: 35px;
    height: 35px;
}

.datepicker i {
    position: relative;
    top: 2px;
}

.datepicker .prev i {
    left: -1px;
}

.datepicker .next i {
    right: -1px;
}

.datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover,
.datepicker tfoot tr th:hover {
    background: #eee;
}

.datepicker .prev.disabled,
.datepicker .next.disabled {
    visibility: hidden;
}

.datepicker .cw {
    font-size: 10px;
    width: 12px;
    padding: 0 2px 0 5px;
    vertical-align: middle;
}

.input-append.date .add-on,
.input-prepend.date .add-on {
    cursor: pointer;
}

.input-append.date .add-on i,
.input-prepend.date .add-on i {
    margin-top: 3px;
}

.input-daterange input {
    text-align: center;
}

.input-daterange input:first-child {
    -webkit-border-radius: 3px 0 0 3px;
    -moz-border-radius: 3px 0 0 3px;
    border-radius: 3px 0 0 3px;
}

.input-daterange input:last-child {
    -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
}

.input-daterange .add-on {
    display: inline-block;
    width: auto;
    min-width: 16px;
    height: 18px;
    padding: 4px 5px;
    font-weight: normal;
    line-height: 18px;
    text-align: center;
    text-shadow: 0 1px 0 #fff;
    vertical-align: middle;
    background-color: #eee;
    border: 1px solid #ccc;
    margin-left: -5px;
    margin-right: -5px;
}

.table-condensed > tbody > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > thead > tr > th {
    position: relative;
    z-index: -1;
}

.btn,
.navbar .navbar-nav > a.btn {
    border-width: 2px;
    font-weight: 400;
    font-size: 0.8571em;
    line-height: 1.35em;
    margin: 10px 1px;
    border: none;
    border-radius: 0.1875rem;
    padding: 11px 22px;
    cursor: pointer;
    background-color: #888888;
    color: #ffffff;
}
.btn:hover,
.btn:focus,
.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active:focus,
.btn:not(:disabled):not(.disabled).active:focus,
.btn:active:hover,
.btn.active:hover,
.show > .btn.dropdown-toggle,
.show > .btn.dropdown-toggle:focus,
.show > .btn.dropdown-toggle:hover,
.navbar .navbar-nav > a.btn:hover,
.navbar .navbar-nav > a.btn:focus,
.navbar .navbar-nav > a.btn:not(:disabled):not(.disabled):active,
.navbar .navbar-nav > a.btn:not(:disabled):not(.disabled).active,
.navbar .navbar-nav > a.btn:not(:disabled):not(.disabled):active:focus,
.navbar .navbar-nav > a.btn:not(:disabled):not(.disabled).active:focus,
.navbar .navbar-nav > a.btn:active:hover,
.navbar .navbar-nav > a.btn.active:hover,
.show > .navbar .navbar-nav > a.btn.dropdown-toggle,
.show > .navbar .navbar-nav > a.btn.dropdown-toggle:focus,
.show > .navbar .navbar-nav > a.btn.dropdown-toggle:hover {
    background-color: #979797;
    color: #ffffff;
    box-shadow: none;
}
.btn:hover,
.navbar .navbar-nav > a.btn:hover {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17);
}
.btn.disabled,
.btn.disabled:hover,
.btn.disabled:focus,
.btn.disabled.focus,
.btn.disabled:active,
.btn.disabled.active,
.btn:disabled,
.btn:disabled:hover,
.btn:disabled:focus,
.btn:disabled.focus,
.btn:disabled:active,
.btn:disabled.active,
.btn[disabled],
.btn[disabled]:hover,
.btn[disabled]:focus,
.btn[disabled].focus,
.btn[disabled]:active,
.btn[disabled].active,
fieldset[disabled] .btn,
fieldset[disabled] .btn:hover,
fieldset[disabled] .btn:focus,
fieldset[disabled] .btn.focus,
fieldset[disabled] .btn:active,
fieldset[disabled] .btn.active,
.navbar .navbar-nav > a.btn.disabled,
.navbar .navbar-nav > a.btn.disabled:hover,
.navbar .navbar-nav > a.btn.disabled:focus,
.navbar .navbar-nav > a.btn.disabled.focus,
.navbar .navbar-nav > a.btn.disabled:active,
.navbar .navbar-nav > a.btn.disabled.active,
.navbar .navbar-nav > a.btn:disabled,
.navbar .navbar-nav > a.btn:disabled:hover,
.navbar .navbar-nav > a.btn:disabled:focus,
.navbar .navbar-nav > a.btn:disabled.focus,
.navbar .navbar-nav > a.btn:disabled:active,
.navbar .navbar-nav > a.btn:disabled.active,
.navbar .navbar-nav > a.btn[disabled],
.navbar .navbar-nav > a.btn[disabled]:hover,
.navbar .navbar-nav > a.btn[disabled]:focus,
.navbar .navbar-nav > a.btn[disabled].focus,
.navbar .navbar-nav > a.btn[disabled]:active,
.navbar .navbar-nav > a.btn[disabled].active,
fieldset[disabled] .navbar .navbar-nav > a.btn,
fieldset[disabled] .navbar .navbar-nav > a.btn:hover,
fieldset[disabled] .navbar .navbar-nav > a.btn:focus,
fieldset[disabled] .navbar .navbar-nav > a.btn.focus,
fieldset[disabled] .navbar .navbar-nav > a.btn:active,
fieldset[disabled] .navbar .navbar-nav > a.btn.active {
    background-color: #888888;
    border-color: #888888;
}
.btn.btn-simple,
.navbar .navbar-nav > a.btn.btn-simple {
    color: #888888;
    border-color: #888888;
}
.btn.btn-simple:hover,
.btn.btn-simple:focus,
.btn.btn-simple:not(:disabled):not(.disabled):active,
.btn.btn-simple:not(:disabled):not(.disabled).active,
.btn.btn-simple:not(:disabled):not(.disabled):active:focus,
.btn.btn-simple:not(:disabled):not(.disabled).active:focus,
.btn.btn-simple:active:hover,
.btn.btn-simple.active:hover,
.show > .btn.btn-simple.dropdown-toggle,
.show > .btn.btn-simple.dropdown-toggle:focus,
.show > .btn.btn-simple.dropdown-toggle:hover,
.navbar .navbar-nav > a.btn.btn-simple:hover,
.navbar .navbar-nav > a.btn.btn-simple:focus,
.navbar .navbar-nav > a.btn.btn-simple:not(:disabled):not(.disabled):active,
.navbar .navbar-nav > a.btn.btn-simple:not(:disabled):not(.disabled).active,
.navbar
    .navbar-nav
    > a.btn.btn-simple:not(:disabled):not(.disabled):active:focus,
.navbar
    .navbar-nav
    > a.btn.btn-simple:not(:disabled):not(.disabled).active:focus,
.navbar .navbar-nav > a.btn.btn-simple:active:hover,
.navbar .navbar-nav > a.btn.btn-simple.active:hover,
.show > .navbar .navbar-nav > a.btn.btn-simple.dropdown-toggle,
.show > .navbar .navbar-nav > a.btn.btn-simple.dropdown-toggle:focus,
.show > .navbar .navbar-nav > a.btn.btn-simple.dropdown-toggle:hover {
    background-color: transparent;
    color: #979797;
    border-color: #979797;
    box-shadow: none;
}
.btn.btn-link,
.navbar .navbar-nav > a.btn.btn-link {
    color: #888888;
}
.btn.btn-link:hover,
.btn.btn-link:focus,
.btn.btn-link:not(:disabled):not(.disabled):active,
.btn.btn-link:not(:disabled):not(.disabled).active,
.btn.btn-link:not(:disabled):not(.disabled):active:focus,
.btn.btn-link:not(:disabled):not(.disabled).active:focus,
.btn.btn-link:active:hover,
.btn.btn-link.active:hover,
.show > .btn.btn-link.dropdown-toggle,
.show > .btn.btn-link.dropdown-toggle:focus,
.show > .btn.btn-link.dropdown-toggle:hover,
.navbar .navbar-nav > a.btn.btn-link:hover,
.navbar .navbar-nav > a.btn.btn-link:focus,
.navbar .navbar-nav > a.btn.btn-link:not(:disabled):not(.disabled):active,
.navbar .navbar-nav > a.btn.btn-link:not(:disabled):not(.disabled).active,
.navbar .navbar-nav > a.btn.btn-link:not(:disabled):not(.disabled):active:focus,
.navbar .navbar-nav > a.btn.btn-link:not(:disabled):not(.disabled).active:focus,
.navbar .navbar-nav > a.btn.btn-link:active:hover,
.navbar .navbar-nav > a.btn.btn-link.active:hover,
.show > .navbar .navbar-nav > a.btn.btn-link.dropdown-toggle,
.show > .navbar .navbar-nav > a.btn.btn-link.dropdown-toggle:focus,
.show > .navbar .navbar-nav > a.btn.btn-link.dropdown-toggle:hover {
    background-color: transparent;
    color: #979797;
    text-decoration: none;
    box-shadow: none;
}
.btn:hover,
.btn:focus,
.navbar .navbar-nav > a.btn:hover,
.navbar .navbar-nav > a.btn:focus {
    opacity: 1;
    filter: alpha(opacity=100);
    outline: 0 !important;
}
.btn:active,
.btn.active,
.open > .btn.dropdown-toggle,
.navbar .navbar-nav > a.btn:active,
.navbar .navbar-nav > a.btn.active,
.open > .navbar .navbar-nav > a.btn.dropdown-toggle {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0 !important;
}
.btn.btn-icon,
.navbar .navbar-nav > a.btn.btn-icon {
    height: 2.375rem;
    min-width: 2.375rem;
    width: 2.375rem;
    padding: 0;
    font-size: 0.9375rem;
    overflow: hidden;
    position: relative;
    line-height: normal;
}
.btn.btn-icon[class*="btn-outline-"],
.navbar .navbar-nav > a.btn.btn-icon[class*="btn-outline-"] {
    padding: 0;
}
.btn.btn-icon.btn-sm,
.navbar .navbar-nav > a.btn.btn-icon.btn-sm {
    height: 1.875rem;
    min-width: 1.875rem;
    width: 1.875rem;
}
.btn.btn-icon.btn-sm i.fab,
.btn.btn-icon.btn-sm i.now-ui-icons,
.navbar .navbar-nav > a.btn.btn-icon.btn-sm i.fab,
.navbar .navbar-nav > a.btn.btn-icon.btn-sm i.now-ui-icons {
    font-size: 0.6875rem;
}
.btn.btn-icon.btn-lg,
.navbar .navbar-nav > a.btn.btn-icon.btn-lg {
    height: 3.6rem;
    min-width: 3.6rem;
    width: 3.6rem;
}
.btn.btn-icon.btn-lg i.now-ui-icons,
.btn.btn-icon.btn-lg i.fab,
.navbar .navbar-nav > a.btn.btn-icon.btn-lg i.now-ui-icons,
.navbar .navbar-nav > a.btn.btn-icon.btn-lg i.fab {
    font-size: 1.325rem;
}
.btn.btn-icon:not(.btn-footer) i.now-ui-icons,
.btn.btn-icon:not(.btn-footer) i.fab,
.navbar .navbar-nav > a.btn.btn-icon:not(.btn-footer) i.now-ui-icons,
.navbar .navbar-nav > a.btn.btn-icon:not(.btn-footer) i.fab {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-12px, -12px);
    line-height: 1.5626rem;
    width: 23px;
}
.btn:not(.btn-icon) .now-ui-icons,
.navbar .navbar-nav > a.btn:not(.btn-icon) .now-ui-icons {
    position: relative;
    top: 1px;
}

.btn-primary {
    background-color: #f96332;
    color: #ffffff;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:active:hover,
.btn-primary.active:hover,
.show > .btn-primary.dropdown-toggle,
.show > .btn-primary.dropdown-toggle:focus,
.show > .btn-primary.dropdown-toggle:hover {
    background-color: #fa7a50;
    color: #ffffff;
    box-shadow: none;
}
.btn-primary:hover {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17);
}
.btn-primary.disabled,
.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary.disabled.focus,
.btn-primary.disabled:active,
.btn-primary.disabled.active,
.btn-primary:disabled,
.btn-primary:disabled:hover,
.btn-primary:disabled:focus,
.btn-primary:disabled.focus,
.btn-primary:disabled:active,
.btn-primary:disabled.active,
.btn-primary[disabled],
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled].focus,
.btn-primary[disabled]:active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active {
    background-color: #f96332;
    border-color: #f96332;
}
.btn-primary.btn-simple {
    color: #f96332;
    border-color: #f96332;
}
.btn-primary.btn-simple:hover,
.btn-primary.btn-simple:focus,
.btn-primary.btn-simple:not(:disabled):not(.disabled):active,
.btn-primary.btn-simple:not(:disabled):not(.disabled).active,
.btn-primary.btn-simple:not(:disabled):not(.disabled):active:focus,
.btn-primary.btn-simple:not(:disabled):not(.disabled).active:focus,
.btn-primary.btn-simple:active:hover,
.btn-primary.btn-simple.active:hover,
.show > .btn-primary.btn-simple.dropdown-toggle,
.show > .btn-primary.btn-simple.dropdown-toggle:focus,
.show > .btn-primary.btn-simple.dropdown-toggle:hover {
    background-color: transparent;
    color: #fa7a50;
    border-color: #fa7a50;
    box-shadow: none;
}
.btn-primary.btn-link {
    color: #f96332;
}
.btn-primary.btn-link:hover,
.btn-primary.btn-link:focus,
.btn-primary.btn-link:not(:disabled):not(.disabled):active,
.btn-primary.btn-link:not(:disabled):not(.disabled).active,
.btn-primary.btn-link:not(:disabled):not(.disabled):active:focus,
.btn-primary.btn-link:not(:disabled):not(.disabled).active:focus,
.btn-primary.btn-link:active:hover,
.btn-primary.btn-link.active:hover,
.show > .btn-primary.btn-link.dropdown-toggle,
.show > .btn-primary.btn-link.dropdown-toggle:focus,
.show > .btn-primary.btn-link.dropdown-toggle:hover {
    background-color: transparent;
    color: #fa7a50;
    text-decoration: none;
    box-shadow: none;
}

.btn-success {
    background-color: #18ce0f;
    color: #ffffff;
}
.btn-success:hover,
.btn-success:focus,
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:active:hover,
.btn-success.active:hover,
.show > .btn-success.dropdown-toggle,
.show > .btn-success.dropdown-toggle:focus,
.show > .btn-success.dropdown-toggle:hover {
    background-color: #1beb11;
    color: #ffffff;
    box-shadow: none;
}
.btn-success:hover {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17);
}
.btn-success.disabled,
.btn-success.disabled:hover,
.btn-success.disabled:focus,
.btn-success.disabled.focus,
.btn-success.disabled:active,
.btn-success.disabled.active,
.btn-success:disabled,
.btn-success:disabled:hover,
.btn-success:disabled:focus,
.btn-success:disabled.focus,
.btn-success:disabled:active,
.btn-success:disabled.active,
.btn-success[disabled],
.btn-success[disabled]:hover,
.btn-success[disabled]:focus,
.btn-success[disabled].focus,
.btn-success[disabled]:active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success.focus,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-success.active {
    background-color: #18ce0f;
    border-color: #18ce0f;
}
.btn-success.btn-simple {
    color: #18ce0f;
    border-color: #18ce0f;
}
.btn-success.btn-simple:hover,
.btn-success.btn-simple:focus,
.btn-success.btn-simple:not(:disabled):not(.disabled):active,
.btn-success.btn-simple:not(:disabled):not(.disabled).active,
.btn-success.btn-simple:not(:disabled):not(.disabled):active:focus,
.btn-success.btn-simple:not(:disabled):not(.disabled).active:focus,
.btn-success.btn-simple:active:hover,
.btn-success.btn-simple.active:hover,
.show > .btn-success.btn-simple.dropdown-toggle,
.show > .btn-success.btn-simple.dropdown-toggle:focus,
.show > .btn-success.btn-simple.dropdown-toggle:hover {
    background-color: transparent;
    color: #1beb11;
    border-color: #1beb11;
    box-shadow: none;
}
.btn-success.btn-link {
    color: #18ce0f;
}
.btn-success.btn-link:hover,
.btn-success.btn-link:focus,
.btn-success.btn-link:not(:disabled):not(.disabled):active,
.btn-success.btn-link:not(:disabled):not(.disabled).active,
.btn-success.btn-link:not(:disabled):not(.disabled):active:focus,
.btn-success.btn-link:not(:disabled):not(.disabled).active:focus,
.btn-success.btn-link:active:hover,
.btn-success.btn-link.active:hover,
.show > .btn-success.btn-link.dropdown-toggle,
.show > .btn-success.btn-link.dropdown-toggle:focus,
.show > .btn-success.btn-link.dropdown-toggle:hover {
    background-color: transparent;
    color: #1beb11;
    text-decoration: none;
    box-shadow: none;
}

.btn-info {
    background-color: #2ca8ff;
    color: #ffffff;
}
.btn-info:hover,
.btn-info:focus,
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:active:hover,
.btn-info.active:hover,
.show > .btn-info.dropdown-toggle,
.show > .btn-info.dropdown-toggle:focus,
.show > .btn-info.dropdown-toggle:hover {
    background-color: #4bb5ff;
    color: #ffffff;
    box-shadow: none;
}
.btn-info:hover {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17);
}
.btn-info.disabled,
.btn-info.disabled:hover,
.btn-info.disabled:focus,
.btn-info.disabled.focus,
.btn-info.disabled:active,
.btn-info.disabled.active,
.btn-info:disabled,
.btn-info:disabled:hover,
.btn-info:disabled:focus,
.btn-info:disabled.focus,
.btn-info:disabled:active,
.btn-info:disabled.active,
.btn-info[disabled],
.btn-info[disabled]:hover,
.btn-info[disabled]:focus,
.btn-info[disabled].focus,
.btn-info[disabled]:active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info,
fieldset[disabled] .btn-info:hover,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info.focus,
fieldset[disabled] .btn-info:active,
fieldset[disabled] .btn-info.active {
    background-color: #2ca8ff;
    border-color: #2ca8ff;
}
.btn-info.btn-simple {
    color: #2ca8ff;
    border-color: #2ca8ff;
}
.btn-info.btn-simple:hover,
.btn-info.btn-simple:focus,
.btn-info.btn-simple:not(:disabled):not(.disabled):active,
.btn-info.btn-simple:not(:disabled):not(.disabled).active,
.btn-info.btn-simple:not(:disabled):not(.disabled):active:focus,
.btn-info.btn-simple:not(:disabled):not(.disabled).active:focus,
.btn-info.btn-simple:active:hover,
.btn-info.btn-simple.active:hover,
.show > .btn-info.btn-simple.dropdown-toggle,
.show > .btn-info.btn-simple.dropdown-toggle:focus,
.show > .btn-info.btn-simple.dropdown-toggle:hover {
    background-color: transparent;
    color: #4bb5ff;
    border-color: #4bb5ff;
    box-shadow: none;
}
.btn-info.btn-link {
    color: #2ca8ff;
}
.btn-info.btn-link:hover,
.btn-info.btn-link:focus,
.btn-info.btn-link:not(:disabled):not(.disabled):active,
.btn-info.btn-link:not(:disabled):not(.disabled).active,
.btn-info.btn-link:not(:disabled):not(.disabled):active:focus,
.btn-info.btn-link:not(:disabled):not(.disabled).active:focus,
.btn-info.btn-link:active:hover,
.btn-info.btn-link.active:hover,
.show > .btn-info.btn-link.dropdown-toggle,
.show > .btn-info.btn-link.dropdown-toggle:focus,
.show > .btn-info.btn-link.dropdown-toggle:hover {
    background-color: transparent;
    color: #4bb5ff;
    text-decoration: none;
    box-shadow: none;
}

.btn-warning {
    background-color: #ffb236;
    color: #ffffff;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:active:hover,
.btn-warning.active:hover,
.show > .btn-warning.dropdown-toggle,
.show > .btn-warning.dropdown-toggle:focus,
.show > .btn-warning.dropdown-toggle:hover {
    background-color: #ffbe55;
    color: #ffffff;
    box-shadow: none;
}
.btn-warning:hover {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17);
}
.btn-warning.disabled,
.btn-warning.disabled:hover,
.btn-warning.disabled:focus,
.btn-warning.disabled.focus,
.btn-warning.disabled:active,
.btn-warning.disabled.active,
.btn-warning:disabled,
.btn-warning:disabled:hover,
.btn-warning:disabled:focus,
.btn-warning:disabled.focus,
.btn-warning:disabled:active,
.btn-warning:disabled.active,
.btn-warning[disabled],
.btn-warning[disabled]:hover,
.btn-warning[disabled]:focus,
.btn-warning[disabled].focus,
.btn-warning[disabled]:active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning,
fieldset[disabled] .btn-warning:hover,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning.focus,
fieldset[disabled] .btn-warning:active,
fieldset[disabled] .btn-warning.active {
    background-color: #ffb236;
    border-color: #ffb236;
}
.btn-warning.btn-simple {
    color: #ffb236;
    border-color: #ffb236;
}
.btn-warning.btn-simple:hover,
.btn-warning.btn-simple:focus,
.btn-warning.btn-simple:not(:disabled):not(.disabled):active,
.btn-warning.btn-simple:not(:disabled):not(.disabled).active,
.btn-warning.btn-simple:not(:disabled):not(.disabled):active:focus,
.btn-warning.btn-simple:not(:disabled):not(.disabled).active:focus,
.btn-warning.btn-simple:active:hover,
.btn-warning.btn-simple.active:hover,
.show > .btn-warning.btn-simple.dropdown-toggle,
.show > .btn-warning.btn-simple.dropdown-toggle:focus,
.show > .btn-warning.btn-simple.dropdown-toggle:hover {
    background-color: transparent;
    color: #ffbe55;
    border-color: #ffbe55;
    box-shadow: none;
}
.btn-warning.btn-link {
    color: #ffb236;
}
.btn-warning.btn-link:hover,
.btn-warning.btn-link:focus,
.btn-warning.btn-link:not(:disabled):not(.disabled):active,
.btn-warning.btn-link:not(:disabled):not(.disabled).active,
.btn-warning.btn-link:not(:disabled):not(.disabled):active:focus,
.btn-warning.btn-link:not(:disabled):not(.disabled).active:focus,
.btn-warning.btn-link:active:hover,
.btn-warning.btn-link.active:hover,
.show > .btn-warning.btn-link.dropdown-toggle,
.show > .btn-warning.btn-link.dropdown-toggle:focus,
.show > .btn-warning.btn-link.dropdown-toggle:hover {
    background-color: transparent;
    color: #ffbe55;
    text-decoration: none;
    box-shadow: none;
}

.btn-danger {
    background-color: #ff3636;
    color: #ffffff;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:active:hover,
.btn-danger.active:hover,
.show > .btn-danger.dropdown-toggle,
.show > .btn-danger.dropdown-toggle:focus,
.show > .btn-danger.dropdown-toggle:hover {
    background-color: #ff5555;
    color: #ffffff;
    box-shadow: none;
}
.btn-danger:hover {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17);
}
.btn-danger.disabled,
.btn-danger.disabled:hover,
.btn-danger.disabled:focus,
.btn-danger.disabled.focus,
.btn-danger.disabled:active,
.btn-danger.disabled.active,
.btn-danger:disabled,
.btn-danger:disabled:hover,
.btn-danger:disabled:focus,
.btn-danger:disabled.focus,
.btn-danger:disabled:active,
.btn-danger:disabled.active,
.btn-danger[disabled],
.btn-danger[disabled]:hover,
.btn-danger[disabled]:focus,
.btn-danger[disabled].focus,
.btn-danger[disabled]:active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger,
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger.focus,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger.active {
    background-color: #ff3636;
    border-color: #ff3636;
}
.btn-danger.btn-simple {
    color: #ff3636;
    border-color: #ff3636;
}
.btn-danger.btn-simple:hover,
.btn-danger.btn-simple:focus,
.btn-danger.btn-simple:not(:disabled):not(.disabled):active,
.btn-danger.btn-simple:not(:disabled):not(.disabled).active,
.btn-danger.btn-simple:not(:disabled):not(.disabled):active:focus,
.btn-danger.btn-simple:not(:disabled):not(.disabled).active:focus,
.btn-danger.btn-simple:active:hover,
.btn-danger.btn-simple.active:hover,
.show > .btn-danger.btn-simple.dropdown-toggle,
.show > .btn-danger.btn-simple.dropdown-toggle:focus,
.show > .btn-danger.btn-simple.dropdown-toggle:hover {
    background-color: transparent;
    color: #ff5555;
    border-color: #ff5555;
    box-shadow: none;
}
.btn-danger.btn-link {
    color: #ff3636;
}
.btn-danger.btn-link:hover,
.btn-danger.btn-link:focus,
.btn-danger.btn-link:not(:disabled):not(.disabled):active,
.btn-danger.btn-link:not(:disabled):not(.disabled).active,
.btn-danger.btn-link:not(:disabled):not(.disabled):active:focus,
.btn-danger.btn-link:not(:disabled):not(.disabled).active:focus,
.btn-danger.btn-link:active:hover,
.btn-danger.btn-link.active:hover,
.show > .btn-danger.btn-link.dropdown-toggle,
.show > .btn-danger.btn-link.dropdown-toggle:focus,
.show > .btn-danger.btn-link.dropdown-toggle:hover {
    background-color: transparent;
    color: #ff5555;
    text-decoration: none;
    box-shadow: none;
}

.btn-neutral {
    background-color: #ffffff;
    color: #f96332;
}
.btn-neutral:hover,
.btn-neutral:focus,
.btn-neutral:not(:disabled):not(.disabled):active,
.btn-neutral:not(:disabled):not(.disabled).active,
.btn-neutral:not(:disabled):not(.disabled):active:focus,
.btn-neutral:not(:disabled):not(.disabled).active:focus,
.btn-neutral:active:hover,
.btn-neutral.active:hover,
.show > .btn-neutral.dropdown-toggle,
.show > .btn-neutral.dropdown-toggle:focus,
.show > .btn-neutral.dropdown-toggle:hover {
    background-color: #ffffff;
    color: #ffffff;
    box-shadow: none;
}
.btn-neutral:hover {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17);
}
.btn-neutral.disabled,
.btn-neutral.disabled:hover,
.btn-neutral.disabled:focus,
.btn-neutral.disabled.focus,
.btn-neutral.disabled:active,
.btn-neutral.disabled.active,
.btn-neutral:disabled,
.btn-neutral:disabled:hover,
.btn-neutral:disabled:focus,
.btn-neutral:disabled.focus,
.btn-neutral:disabled:active,
.btn-neutral:disabled.active,
.btn-neutral[disabled],
.btn-neutral[disabled]:hover,
.btn-neutral[disabled]:focus,
.btn-neutral[disabled].focus,
.btn-neutral[disabled]:active,
.btn-neutral[disabled].active,
fieldset[disabled] .btn-neutral,
fieldset[disabled] .btn-neutral:hover,
fieldset[disabled] .btn-neutral:focus,
fieldset[disabled] .btn-neutral.focus,
fieldset[disabled] .btn-neutral:active,
fieldset[disabled] .btn-neutral.active {
    background-color: #ffffff;
    border-color: #ffffff;
}
.btn-neutral.btn-danger {
    color: #ff3636;
}
.btn-neutral.btn-danger:hover,
.btn-neutral.btn-danger:focus,
.btn-neutral.btn-danger:active {
    color: #ff5555 !important;
}
.btn-neutral.btn-primary {
    color: #f96332;
}
.btn-neutral.btn-primary:hover,
.btn-neutral.btn-primary:focus,
.btn-neutral.btn-primary:active {
    color: #fa7a50 !important;
}
.btn-neutral.btn-info {
    color: #2ca8ff;
}
.btn-neutral.btn-info:hover,
.btn-neutral.btn-info:focus,
.btn-neutral.btn-info:active {
    color: #4bb5ff !important;
}
.btn-neutral.btn-warning {
    color: #ffb236;
}
.btn-neutral.btn-warning:hover,
.btn-neutral.btn-warning:focus,
.btn-neutral.btn-warning:active {
    color: #ffbe55 !important;
}
.btn-neutral.btn-success {
    color: #18ce0f;
}
.btn-neutral.btn-success:hover,
.btn-neutral.btn-success:focus,
.btn-neutral.btn-success:active {
    color: #1beb11 !important;
}
.btn-neutral.btn-default {
    color: #888888;
}
.btn-neutral.btn-default:hover,
.btn-neutral.btn-default:focus,
.btn-neutral.btn-default:active {
    color: #979797 !important;
}
.btn-neutral:hover,
.btn-neutral:focus,
.btn-neutral:not(:disabled):not(.disabled):active,
.btn-neutral:not(:disabled):not(.disabled).active,
.btn-neutral:not(:disabled):not(.disabled):active:focus,
.btn-neutral:not(:disabled):not(.disabled).active:focus,
.btn-neutral:active:hover,
.btn-neutral.active:hover,
.show > .btn-neutral.dropdown-toggle,
.show > .btn-neutral.dropdown-toggle:focus,
.show > .btn-neutral.dropdown-toggle:hover {
    background-color: #ffffff;
    color: #fa7a50;
    box-shadow: none;
}
.btn-neutral:hover,
.btn-neutral:focus {
    color: #fa7a50 !important;
}
.btn-neutral:hover:not(.nav-link),
.btn-neutral:focus:not(.nav-link) {
    box-shadow: none;
}
.btn-neutral.btn-simple {
    color: #ffffff;
    border-color: #ffffff;
}
.btn-neutral.btn-simple:hover,
.btn-neutral.btn-simple:focus,
.btn-neutral.btn-simple:not(:disabled):not(.disabled):active,
.btn-neutral.btn-simple:not(:disabled):not(.disabled).active,
.btn-neutral.btn-simple:not(:disabled):not(.disabled):active:focus,
.btn-neutral.btn-simple:not(:disabled):not(.disabled).active:focus,
.btn-neutral.btn-simple:active:hover,
.btn-neutral.btn-simple.active:hover,
.show > .btn-neutral.btn-simple.dropdown-toggle,
.show > .btn-neutral.btn-simple.dropdown-toggle:focus,
.show > .btn-neutral.btn-simple.dropdown-toggle:hover {
    background-color: transparent;
    color: #ffffff;
    border-color: #ffffff;
    box-shadow: none;
}
.btn-neutral.btn-link {
    color: #ffffff;
}
.btn-neutral.btn-link:hover,
.btn-neutral.btn-link:focus,
.btn-neutral.btn-link:not(:disabled):not(.disabled):active,
.btn-neutral.btn-link:not(:disabled):not(.disabled).active,
.btn-neutral.btn-link:not(:disabled):not(.disabled):active:focus,
.btn-neutral.btn-link:not(:disabled):not(.disabled).active:focus,
.btn-neutral.btn-link:active:hover,
.btn-neutral.btn-link.active:hover,
.show > .btn-neutral.btn-link.dropdown-toggle,
.show > .btn-neutral.btn-link.dropdown-toggle:focus,
.show > .btn-neutral.btn-link.dropdown-toggle:hover {
    background-color: transparent;
    color: #ffffff;
    text-decoration: none;
    box-shadow: none;
}

.btn-outline-primary {
    color: #f96332;
    border-color: #f96332;
}
.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:active:hover,
.btn-outline-primary.active:hover,
.show > .btn-outline-primary.dropdown-toggle,
.show > .btn-outline-primary.dropdown-toggle:focus,
.show > .btn-outline-primary.dropdown-toggle:hover {
    background-color: transparent;
    color: #fa7a50;
    border-color: #fa7a50;
    box-shadow: none;
}

.btn-outline-success {
    color: #18ce0f;
    border-color: #18ce0f;
}
.btn-outline-success:hover,
.btn-outline-success:focus,
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:active:hover,
.btn-outline-success.active:hover,
.show > .btn-outline-success.dropdown-toggle,
.show > .btn-outline-success.dropdown-toggle:focus,
.show > .btn-outline-success.dropdown-toggle:hover {
    background-color: transparent;
    color: #1beb11;
    border-color: #1beb11;
    box-shadow: none;
}

.btn-outline-info {
    color: #2ca8ff;
    border-color: #2ca8ff;
}
.btn-outline-info:hover,
.btn-outline-info:focus,
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:active:hover,
.btn-outline-info.active:hover,
.show > .btn-outline-info.dropdown-toggle,
.show > .btn-outline-info.dropdown-toggle:focus,
.show > .btn-outline-info.dropdown-toggle:hover {
    background-color: transparent;
    color: #4bb5ff;
    border-color: #4bb5ff;
    box-shadow: none;
}

.btn-outline-warning {
    color: #ffb236;
    border-color: #ffb236;
}
.btn-outline-warning:hover,
.btn-outline-warning:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:active:hover,
.btn-outline-warning.active:hover,
.show > .btn-outline-warning.dropdown-toggle,
.show > .btn-outline-warning.dropdown-toggle:focus,
.show > .btn-outline-warning.dropdown-toggle:hover {
    background-color: transparent;
    color: #ffbe55;
    border-color: #ffbe55;
    box-shadow: none;
}

.btn-outline-danger {
    color: #ff3636;
    border-color: #ff3636;
}
.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:active:hover,
.btn-outline-danger.active:hover,
.show > .btn-outline-danger.dropdown-toggle,
.show > .btn-outline-danger.dropdown-toggle:focus,
.show > .btn-outline-danger.dropdown-toggle:hover {
    background-color: transparent;
    color: #ff5555;
    border-color: #ff5555;
    box-shadow: none;
}

.btn-outline-default {
    color: #888888;
    border-color: #888888;
}
.btn-outline-default:hover,
.btn-outline-default:focus,
.btn-outline-default:not(:disabled):not(.disabled):active,
.btn-outline-default:not(:disabled):not(.disabled).active,
.btn-outline-default:not(:disabled):not(.disabled):active:focus,
.btn-outline-default:not(:disabled):not(.disabled).active:focus,
.btn-outline-default:active:hover,
.btn-outline-default.active:hover,
.show > .btn-outline-default.dropdown-toggle,
.show > .btn-outline-default.dropdown-toggle:focus,
.show > .btn-outline-default.dropdown-toggle:hover {
    background-color: transparent;
    color: #979797;
    border-color: #979797;
    box-shadow: none;
}

.btn-round {
    border-width: 1px;
    border-radius: 30px !important;
    padding: 11px 23px;
}
.btn-round[class*="btn-outline-"] {
    padding: 10px 22px;
}

[class*="btn-outline-"] {
    border: 1px solid;
    padding: 10px 22px;
    background-color: transparent;
}

[class*="btn-outline-"].disabled,
[class*="btn-outline-"].disabled:hover,
[class*="btn-outline-"].disabled:focus,
[class*="btn-outline-"].disabled.focus,
[class*="btn-outline-"].disabled:active,
[class*="btn-outline-"].disabled.active,
[class*="btn-outline-"]:disabled,
[class*="btn-outline-"]:disabled:hover,
[class*="btn-outline-"]:disabled:focus,
[class*="btn-outline-"]:disabled.focus,
[class*="btn-outline-"]:disabled:active,
[class*="btn-outline-"]:disabled.active,
[class*="btn-outline-"][disabled],
[class*="btn-outline-"][disabled]:hover,
[class*="btn-outline-"][disabled]:focus,
[class*="btn-outline-"][disabled].focus,
[class*="btn-outline-"][disabled]:active,
[class*="btn-outline-"][disabled].active,
fieldset[disabled] [class*="btn-outline-"],
fieldset[disabled] [class*="btn-outline-"]:hover,
fieldset[disabled] [class*="btn-outline-"]:focus,
fieldset[disabled] [class*="btn-outline-"].focus,
fieldset[disabled] [class*="btn-outline-"]:active,
fieldset[disabled] [class*="btn-outline-"].active,
.btn-link.disabled,
.btn-link.disabled:hover,
.btn-link.disabled:focus,
.btn-link.disabled.focus,
.btn-link.disabled:active,
.btn-link.disabled.active,
.btn-link:disabled,
.btn-link:disabled:hover,
.btn-link:disabled:focus,
.btn-link:disabled.focus,
.btn-link:disabled:active,
.btn-link:disabled.active,
.btn-link[disabled],
.btn-link[disabled]:hover,
.btn-link[disabled]:focus,
.btn-link[disabled].focus,
.btn-link[disabled]:active,
.btn-link[disabled].active,
fieldset[disabled] .btn-link,
fieldset[disabled] .btn-link:hover,
fieldset[disabled] .btn-link:focus,
fieldset[disabled] .btn-link.focus,
fieldset[disabled] .btn-link:active,
fieldset[disabled] .btn-link.active {
    background-color: transparent;
}

.btn-lg {
    font-size: 1em;
    border-radius: 0.25rem;
    padding: 15px 48px;
}
.btn-lg[class*="btn-outline-"] {
    padding: 14px 47px;
}

.btn-sm {
    font-size: 14px;
    border-radius: 0.1875rem;
    padding: 5px 15px;
}
.btn-sm[class*="btn-outline-"] {
    padding: 4px 14px;
}

.btn-link {
    border: 0;
    padding: 0.5rem 0.7rem;
    background-color: transparent;
}

.btn-wd {
    min-width: 140px;
}

.btn-group.select {
    width: 100%;
}

.btn-group.select .btn {
    text-align: left;
}

.btn-group.select .caret {
    position: absolute;
    top: 50%;
    margin-top: -1px;
    right: 8px;
}

.btn-facebook,
.btn-facebook:hover,
.btn-facebookfocus,
.btn-facebook:active,
.btn-facebook:active:focus {
    color: #3b5998 !important;
}

.btn-twitter,
.btn-twitter:hover,
.btn-twitterfocus,
.btn-twitter:active,
.btn-twitter:active:focus {
    color: #55acee !important;
}

.btn-google,
.btn-google:hover,
.btn-googlefocus,
.btn-google:active,
.btn-google:active:focus {
    color: #dd4b39 !important;
}

.btn-github,
.btn-github:hover,
.btn-githubfocus,
.btn-github:active,
.btn-github:active:focus {
    color: #333333 !important;
}

.btn-linkedin,
.btn-linkedin:hover,
.btn-linkedinfocus,
.btn-linkedin:active,
.btn-linkedin:active:focus {
    color: #0077b5 !important;
}

.form-control::-moz-placeholder {
    color: #888888;
    opacity: 0.8;
    filter: alpha(opacity=80);
}

.form-control:-moz-placeholder {
    color: #888888;
    opacity: 0.8;
    filter: alpha(opacity=80);
}

.form-control::-webkit-input-placeholder {
    color: #888888;
    opacity: 0.8;
    filter: alpha(opacity=80);
}

.form-control:-ms-input-placeholder {
    color: #888888;
    opacity: 0.8;
    filter: alpha(opacity=80);
}

.form-control {
    background-color: transparent;
    border: 1px solid #e3e3e3;
    border-radius: 30px;
    color: #2c2c2c;
    height: auto;
    line-height: normal;
    font-size: 0.8571em;
    -webkit-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
        background-color 0.3s ease-in-out;
    -moz-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
        background-color 0.3s ease-in-out;
    -o-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
        background-color 0.3s ease-in-out;
    -ms-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
        background-color 0.3s ease-in-out;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
        background-color 0.3s ease-in-out;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.has-success .form-control {
    border-color: #e3e3e3;
}
.form-control:focus {
    border: 1px solid #f96332;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0 !important;
    color: #2c2c2c;
}
.form-control:focus + .input-group-text,
.form-control:focus ~ .input-group-text {
    border: 1px solid #f96332;
    border-left: none;
    background-color: transparent;
}
.has-success .form-control,
.has-error .form-control,
.has-success .form-control:focus,
.has-error .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.has-success .form-control:focus {
    border-color: #1be611;
}
.has-danger .form-control.form-control-success,
.has-danger .form-control.form-control-danger,
.has-success .form-control.form-control-success,
.has-success .form-control.form-control-danger {
    background-image: none;
}
.has-danger .form-control {
    border-color: #ffcfcf;
    color: #ff3636;
    background-color: rgba(222, 222, 222, 0.1);
}
.has-danger .form-control:focus {
    background-color: #ffffff;
}
.form-control + .form-control-feedback {
    border-radius: 0.25rem;
    font-size: 14px;
    margin-top: -7px;
    position: absolute;
    right: 10px;
    top: 50%;
    vertical-align: middle;
}
.open .form-control {
    border-radius: 0.25rem 0.25rem 0 0;
    border-bottom-color: transparent;
}

.has-success:after,
.has-danger:after {
    font-family: "Nucleo Outline";
    content: "\ea22";
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 12px;
    color: #18ce0f;
    font-size: 11px;
}

.has-success.input-lg:after,
.has-danger.input-lg:after {
    font-size: 13px;
    top: 13px;
}

.has-danger:after {
    content: "\ea53";
    color: #ff3636;
}

.form-group.no-border.input-lg .input-group-text,
.input-group.no-border.input-lg .input-group-text {
    padding: 15px 0 15px 19px;
}

.form-group.no-border.input-lg .form-control,
.input-group.no-border.input-lg .form-control {
    padding: 15px 19px;
}
.form-group.no-border.input-lg .form-control + .input-group-text,
.input-group.no-border.input-lg .form-control + .input-group-text {
    padding: 15px 19px 15px 0;
}

.form-group.input-lg .form-control,
.input-group.input-lg .form-control {
    padding: 14px 18px;
}
.form-group.input-lg .form-control + .input-group-text,
.input-group.input-lg .form-control + .input-group-text {
    padding: 14px 18px 14px 0;
}

.form-group.input-lg .input-group-text,
.input-group.input-lg .input-group-text {
    padding: 14px 0 15px 18px;
}
.form-group.input-lg .input-group-text + .form-control,
.input-group.input-lg .input-group-text + .form-control {
    padding: 15px 18px 15px 16px;
}

.form-group.no-border .form-control,
.input-group.no-border .form-control {
    padding: 11px 19px;
}
.form-group.no-border .form-control + .input-group-text,
.input-group.no-border .form-control + .input-group-text {
    padding: 11px 19px 11px 0;
}

.form-group.no-border .input-group-text,
.input-group.no-border .input-group-text {
    padding: 11px 0 11px 19px;
}

.form-group .form-control,
.input-group .form-control {
    padding: 10px 18px 10px 18px;
}
.form-group .form-control + .input-group-text,
.input-group .form-control + .input-group-text {
    padding: 10px 18px 10px 0;
}

.form-group .input-group-text,
.input-group .input-group-text {
    padding: 10px 0 10px 18px;
}
.form-group .input-group-text + .form-control,
.form-group .input-group-text ~ .form-control,
.input-group .input-group-text + .form-control,
.input-group .input-group-text ~ .form-control {
    padding: 10px 19px 11px 16px;
}

.form-group.no-border .form-control,
.form-group.no-border .form-control + .input-group-text,
.input-group.no-border .form-control,
.input-group.no-border .form-control + .input-group-text {
    background-color: rgba(222, 222, 222, 0.3);
    border: medium none;
}
.form-group.no-border .form-control:focus,
.form-group.no-border .form-control:active,
.form-group.no-border .form-control:active,
.form-group.no-border .form-control + .input-group-text:focus,
.form-group.no-border .form-control + .input-group-text:active,
.form-group.no-border .form-control + .input-group-text:active,
.input-group.no-border .form-control:focus,
.input-group.no-border .form-control:active,
.input-group.no-border .form-control:active,
.input-group.no-border .form-control + .input-group-text:focus,
.input-group.no-border .form-control + .input-group-text:active,
.input-group.no-border .form-control + .input-group-text:active {
    border: medium none;
    background-color: rgba(222, 222, 222, 0.5);
}

.form-group.no-border .form-control:focus + .input-group-text,
.input-group.no-border .form-control:focus + .input-group-text {
    background-color: rgba(222, 222, 222, 0.5);
}

.form-group.no-border .input-group-prepend .input-group-text,
.input-group.no-border .input-group-prepend .input-group-text {
    background-color: rgba(222, 222, 222, 0.3);
    border: none;
    border-left: transparent !important;
}

.form-group.no-border.input-group-focus.no-border .input-group-text,
.input-group.no-border.input-group-focus.no-border .input-group-text {
    background-color: rgba(222, 222, 222, 0.5);
}

.has-error .form-control-feedback,
.has-error .control-label {
    color: #ff3636;
}

.has-success .form-control-feedback,
.has-success .control-label {
    color: #18ce0f;
}

.input-group-text {
    background-color: #ffffff;
    border: 1px solid #e3e3e3;
    border-radius: 30px;
    color: #555555;
    -webkit-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
        background-color 0.3s ease-in-out;
    -moz-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
        background-color 0.3s ease-in-out;
    -o-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
        background-color 0.3s ease-in-out;
    -ms-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
        background-color 0.3s ease-in-out;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
        background-color 0.3s ease-in-out;
}
.input-group-focus .input-group-text {
    background-color: #ffffff;
    border-color: #f96332;
}
.has-success .input-group-text,
.has-danger .input-group-text {
    background-color: #ffffff;
}
.has-danger .form-control:focus + .input-group-text {
    color: #ff3636;
}
.has-success .form-control:focus + .input-group-text {
    color: #18ce0f;
}
.input-group-text + .form-control,
.input-group-text ~ .form-control {
    padding: -0.5rem 0.7rem;
    padding-left: 18px;
}
.input-group-text i {
    width: 17px;
}

.input-group,
.form-group {
    margin-bottom: 10px;
    position: relative;
}

.input-group[disabled] .input-group-text {
    background-color: #e3e3e3;
}

.input-group .input-group-prepend,
.form-group .input-group-prepend {
    margin-right: 0;
}
.input-group .input-group-prepend .input-group-text,
.form-group .input-group-prepend .input-group-text {
    border-left: 1px solid #e3e3e3;
}

.input-group .input-group-append .input-group-text,
.form-group .input-group-append .input-group-text {
    border-right: 1px solid #e3e3e3;
    padding: 10px 18px 10px 0;
}

.input-group.input-group-focus .input-group-prepend .input-group-text,
.form-group.input-group-focus .input-group-prepend .input-group-text {
    border-left: 1px solid #f96332;
}

.input-group.input-group-focus .input-group-append .input-group-text,
.form-group.input-group-focus .input-group-append .input-group-text {
    border-right: 1px solid #f96332;
}

.input-group.input-group-focus.no-border .input-group-prepend .input-group-text,
.form-group.input-group-focus.no-border .input-group-prepend .input-group-text {
    border-left: transparent;
}

.input-group .form-control:first-child,
.input-group-text:first-child,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
    border-right: 0 none;
}

.input-group .form-control:last-child,
.input-group-text:last-child,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child) {
    border-left: 0 none;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    background-color: #e3e3e3;
    color: #888888;
    cursor: not-allowed;
}

.input-group-btn .btn {
    border-width: 1px;
    padding: 11px 0.7rem;
}

.input-group-btn .btn-default:not(.btn-fill) {
    border-color: #dddddd;
}

.input-group-btn:last-child > .btn {
    margin-left: 0;
}

textarea.form-control {
    max-width: 100%;
    max-height: 80px;
    padding: 10px 10px 0 0;
    resize: none;
    border: none;
    border-bottom: 1px solid #e3e3e3;
    border-radius: 0;
    line-height: 2;
}
textarea.form-control:focus,
textarea.form-control:active {
    border-left: none;
    border-top: none;
    border-right: none;
}

.has-success.form-group .form-control,
.has-success.form-group.no-border .form-control,
.has-danger.form-group .form-control,
.has-danger.form-group.no-border .form-control {
    padding-right: 40px;
}

.form.form-newsletter .form-group {
    float: left;
    width: 78%;
    margin-right: 2%;
    margin-top: 9px;
}

.input-group .input-group-btn {
    padding: 0 12px;
}

.form-group input[type="file"] {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.form-check {
    margin-top: 0.65rem;
    padding-left: 0;
}

.form-check .form-check-label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    padding-left: 35px;
    line-height: 26px;
    margin-bottom: 0;
    -webkit-transition: color 0.3s linear;
    -moz-transition: color 0.3s linear;
    -o-transition: color 0.3s linear;
    -ms-transition: color 0.3s linear;
    transition: color 0.3s linear;
}

.radio .form-check-sign {
    padding-left: 28px;
}

.form-check .form-check-sign::before,
.form-check .form-check-sign::after {
    content: " ";
    display: inline-block;
    position: absolute;
    width: 26px;
    height: 26px;
    left: 0;
    cursor: pointer;
    border-radius: 3px;
    top: 0;
    background-color: transparent;
    border: 1px solid #e3e3e3;
    -webkit-transition: opacity 0.3s linear;
    -moz-transition: opacity 0.3s linear;
    -o-transition: opacity 0.3s linear;
    -ms-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear;
}

.form-check .form-check-sign::after {
    font-family: "Nucleo Outline";
    content: "\ea22";
    top: 0px;
    text-align: center;
    font-size: 14px;
    opacity: 0;
    color: #555555;
    border: 0;
    background-color: inherit;
}

.form-check.disabled .form-check-label,
.form-check.disabled .form-check-label {
    color: #9a9a9a;
    opacity: 0.5;
    cursor: not-allowed;
}

.form-check input[type="checkbox"],
.radio input[type="radio"] {
    opacity: 0;
    position: absolute;
    visibility: hidden;
}

.form-check input[type="checkbox"]:checked + .form-check-sign::after {
    opacity: 1;
}

.form-control input[type="checkbox"]:disabled + .form-check-sign::before,
.checkbox input[type="checkbox"]:disabled + .form-check-sign::after {
    cursor: not-allowed;
}

.form-check input[type="checkbox"]:disabled + .form-check-sign,
.form-check input[type="radio"]:disabled + .form-check-sign {
    pointer-events: none;
}

.form-check-radio .form-check-sign::before,
.form-check-radio .form-check-sign::after {
    content: " ";
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #e3e3e3;
    display: inline-block;
    position: absolute;
    left: 3px;
    top: 3px;
    padding: 1px;
    -webkit-transition: opacity 0.3s linear;
    -moz-transition: opacity 0.3s linear;
    -o-transition: opacity 0.3s linear;
    -ms-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear;
}

.form-check-radio input[type="radio"] + .form-check-sign:after,
.form-check-radio input[type="radio"] {
    opacity: 0;
}

.form-check-radio input[type="radio"]:checked + .form-check-sign::after {
    width: 4px;
    height: 4px;
    background-color: #555555;
    border-color: #555555;
    top: 11px;
    left: 11px;
    opacity: 1;
}

.form-check-radio input[type="radio"]:checked + .form-check-sign::after {
    opacity: 1;
}

.form-check-radio input[type="radio"]:disabled + .form-check-sign {
    color: #9a9a9a;
}

.form-check-radio input[type="radio"]:disabled + .form-check-sign::before,
.form-check-radio input[type="radio"]:disabled + .form-check-sign::after {
    color: #9a9a9a;
}

.progress-container {
    position: relative;
}
.progress-container + .progress-container,
.progress-container ~ .progress-container {
    margin-top: 15px;
}
.progress-container .progress-badge {
    color: #888888;
    font-size: 0.8571em;
    text-transform: uppercase;
}
.progress-container .progress {
    height: 1px;
    border-radius: 0;
    box-shadow: none;
    background: rgba(222, 222, 222, 0.8);
    margin-top: 14px;
}
.progress-container .progress .progress-bar {
    box-shadow: none;
    background-color: #888888;
}
.progress-container .progress .progress-value {
    position: absolute;
    top: 2px;
    right: 0;
    color: #888888;
    font-size: 0.8571em;
}
.progress-container.progress-neutral .progress {
    background: rgba(255, 255, 255, 0.3);
}
.progress-container.progress-neutral .progress-bar {
    background: #ffffff;
}
.progress-container.progress-neutral .progress-value,
.progress-container.progress-neutral .progress-badge {
    color: #ffffff;
}
.progress-container.progress-primary .progress {
    background: rgba(249, 99, 50, 0.3);
}
.progress-container.progress-primary .progress-bar {
    background: #f96332;
}
.progress-container.progress-primary .progress-value,
.progress-container.progress-primary .progress-badge {
    color: #f96332;
}
.progress-container.progress-info .progress {
    background: rgba(44, 168, 255, 0.3);
}
.progress-container.progress-info .progress-bar {
    background: #2ca8ff;
}
.progress-container.progress-info .progress-value,
.progress-container.progress-info .progress-badge {
    color: #2ca8ff;
}
.progress-container.progress-success .progress {
    background: rgba(24, 206, 15, 0.3);
}
.progress-container.progress-success .progress-bar {
    background: #18ce0f;
}
.progress-container.progress-success .progress-value,
.progress-container.progress-success .progress-badge {
    color: #18ce0f;
}
.progress-container.progress-warning .progress {
    background: rgba(255, 178, 54, 0.3);
}
.progress-container.progress-warning .progress-bar {
    background: #ffb236;
}
.progress-container.progress-warning .progress-value,
.progress-container.progress-warning .progress-badge {
    color: #ffb236;
}
.progress-container.progress-danger .progress {
    background: rgba(255, 54, 54, 0.3);
}
.progress-container.progress-danger .progress-bar {
    background: #ff3636;
}
.progress-container.progress-danger .progress-value,
.progress-container.progress-danger .progress-badge {
    color: #ff3636;
}

/*           badges             */
.badge {
    border-radius: 8px;
    padding: 4px 8px;
    text-transform: uppercase;
    font-size: 0.7142em;
    line-height: 12px;
    background-color: transparent;
    border: 1px solid;
    margin-bottom: 5px;
    color: #ffffff;
    border-radius: 0.875rem;
}
.badge:hover,
.badge:focus {
    text-decoration: none;
}

.badge-icon {
    padding: 0.4em 0.55em;
}
.badge-icon i {
    font-size: 0.8em;
}

.badge-default,
.badge-default[href]:focus,
.badge-default[href]:hover {
    border-color: #888888;
    background-color: #888888;
    color: #ffffff;
}

.badge-primary,
.badge-primary[href]:focus,
.badge-primary[href]:hover {
    border-color: #f96332;
    background-color: #f96332;
    color: #ffffff;
}

.badge-info,
.badge-info[href]:focus,
.badge-info[href]:hover {
    border-color: #2ca8ff;
    background-color: #2ca8ff;
    color: #ffffff;
}

.badge-success,
.badge-success[href]:focus,
.badge-success[href]:hover {
    border-color: #18ce0f;
    background-color: #18ce0f;
    color: #ffffff;
}

.badge-warning,
.badge-warning[href]:focus,
.badge-warning[href]:hover {
    border-color: #ffb236;
    background-color: #ffb236;
    color: #ffffff;
}

.badge-danger,
.badge-danger[href]:focus,
.badge-danger[href]:hover {
    border-color: #ff3636;
    background-color: #ff3636;
    color: #ffffff;
}

.badge-neutral {
    color: inherit !important;
}
.badge-neutral,
.badge-neutral[href]:focus,
.badge-neutral[href]:hover {
    border-color: #ffffff;
    background-color: #ffffff;
    color: #ffffff;
}

.pagination .page-item .page-link {
    border: 0;
    border-radius: 30px !important;
    transition: all 0.3s;
    padding: 0px 11px;
    margin: 0 3px;
    min-width: 30px;
    text-align: center;
    box-shadow: none;
    height: 30px;
    line-height: 30px;
    color: #2c2c2c;
    cursor: pointer;
    font-size: 14px;
    text-transform: uppercase;
    background: transparent;
}
.pagination .page-item .page-link:hover,
.pagination .page-item .page-link:focus {
    color: #2c2c2c;
    background-color: rgba(222, 222, 222, 0.3);
    border: none;
}

.pagination .arrow-margin-left,
.pagination .arrow-margin-right {
    position: absolute;
}

.pagination .arrow-margin-right {
    right: 0;
}

.pagination .arrow-margin-left {
    left: 0;
}

.pagination .page-item.active > .page-link {
    color: #e3e3e3;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
}
.pagination .page-item.active > .page-link,
.pagination .page-item.active > .page-link:focus,
.pagination .page-item.active > .page-link:hover {
    background-color: #888888;
    border-color: #888888;
    color: #ffffff;
}

.pagination .page-item.disabled > .page-link {
    opacity: 0.5;
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
}

.pagination.pagination-info .page-item.active > .page-link,
.pagination.pagination-info .page-item.active > .page-link:focus,
.pagination.pagination-info .page-item.active > .page-link:hover {
    background-color: #2ca8ff;
    border-color: #2ca8ff;
}

.pagination.pagination-success .page-item.active > .page-link,
.pagination.pagination-success .page-item.active > .page-link:focus,
.pagination.pagination-success .page-item.active > .page-link:hover {
    background-color: #18ce0f;
    border-color: #18ce0f;
}

.pagination.pagination-primary .page-item.active > .page-link,
.pagination.pagination-primary .page-item.active > .page-link:focus,
.pagination.pagination-primary .page-item.active > .page-link:hover {
    background-color: #f96332;
    border-color: #f96332;
}

.pagination.pagination-warning .page-item.active > .page-link,
.pagination.pagination-warning .page-item.active > .page-link:focus,
.pagination.pagination-warning .page-item.active > .page-link:hover {
    background-color: #ffb236;
    border-color: #ffb236;
}

.pagination.pagination-danger .page-item.active > .page-link,
.pagination.pagination-danger .page-item.active > .page-link:focus,
.pagination.pagination-danger .page-item.active > .page-link:hover {
    background-color: #ff3636;
    border-color: #ff3636;
}

.pagination.pagination-neutral .page-item > .page-link {
    color: #ffffff;
}
.pagination.pagination-neutral .page-item > .page-link:focus,
.pagination.pagination-neutral .page-item > .page-link:hover {
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
}

.pagination.pagination-neutral .page-item.active > .page-link,
.pagination.pagination-neutral .page-item.active > .page-link:focus,
.pagination.pagination-neutral .page-item.active > .page-link:hover {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #f96332;
}

.pagination-container {
    display: flex;
    align-items: center;
}

button,
input,
optgroup,
select,
textarea {
    font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
}

small {
    font-size: 60%;
}

a {
    color: #f96332;
}
a:hover,
a:focus {
    color: #f96332;
}

h1,
.h1 {
    font-size: 3.5em;
    line-height: 1.15;
    margin-bottom: 30px;
}
h1 small,
.h1 small {
    font-weight: 700;
    text-transform: uppercase;
    opacity: 0.8;
}

h2,
.h2 {
    font-size: 2.5em;
    margin-bottom: 30px;
}

h3,
.h3 {
    font-size: 1.825em;
    margin-bottom: 30px;
    line-height: 1.4em;
}

h4,
.h4 {
    font-size: 1.5em;
    line-height: 1.45em;
    margin-top: 30px;
    margin-bottom: 15px;
}
h4 + .category,
h4.title + .category,
.h4 + .category,
.h4.title + .category {
    margin-top: -5px;
}

h5,
.h5 {
    font-size: 1.3em;
    line-height: 1.4em;
    margin-bottom: 15px;
}
h5.category,
.h5.category {
    font-weight: 400;
}

h6,
.h6 {
    font-size: 0.9em;
    font-weight: 700;
    text-transform: uppercase;
}

p {
    line-height: 1.61em;
    font-weight: 300;
    font-size: 1.2em;
}

.title {
    font-weight: 700;
    padding-top: 30px;
}
.title.title-up {
    text-transform: uppercase;
}
.title.title-up a {
    color: #2c2c2c;
    text-decoration: none;
}
.title + .category {
    margin-top: -25px;
}

.description,
.card-description,
.footer-big p {
    color: #9a9a9a;
    font-weight: 300;
}

.category {
    text-transform: capitalize;
    font-weight: 700;
    color: #9a9a9a;
}

.text-primary {
    color: #f96332 !important;
}

.text-info {
    color: #2ca8ff !important;
}

.text-success {
    color: #18ce0f !important;
}

.text-warning {
    color: #ffb236 !important;
}

.text-danger {
    color: #ff3636 !important;
}

.text-black {
    color: #444;
}

.blockquote {
    border-left: none;
    border: 1px solid #888888;
    padding: 20px;
    font-size: 1.1em;
    line-height: 1.8;
}
.blockquote small {
    color: #888888;
    font-size: 0.8571em;
    text-transform: uppercase;
}
.blockquote.blockquote-primary {
    border-color: #f96332;
    color: #f96332;
}
.blockquote.blockquote-primary small {
    color: #f96332;
}
.blockquote.blockquote-danger {
    border-color: #ff3636;
    color: #ff3636;
}
.blockquote.blockquote-danger small {
    color: #ff3636;
}
.blockquote.blockquote-white {
    border-color: rgba(255, 255, 255, 0.8);
    color: #ffffff;
}
.blockquote.blockquote-white small {
    color: rgba(255, 255, 255, 0.8);
}

body {
    background-color: #f2f2f2;
    color: #2c2c2c;
    font-size: 14px;
    font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
    overflow-x: hidden;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.main {
    position: relative;
    background: #ffffff;
}

/* Animations */
.nav-pills .nav-link,
.nav-item .nav-link,
.navbar,
.nav-tabs .nav-link,
.tag,
.tag [data-role="remove"] {
    -webkit-transition: all 300ms ease 0s;
    -moz-transition: all 300ms ease 0s;
    -o-transition: all 300ms ease 0s;
    -ms-transition: all 300ms ease 0s;
    transition: all 300ms ease 0s;
}

.card a,
.bootstrap-switch-label:before {
    -webkit-transition: all 150ms ease 0s;
    -moz-transition: all 150ms ease 0s;
    -o-transition: all 150ms ease 0s;
    -ms-transition: all 150ms ease 0s;
    transition: all 150ms ease 0s;
}

.dropdown-toggle:after,
[data-toggle="collapse"][data-parent="#accordion"] i {
    -webkit-transition: transform 150ms ease 0s;
    -moz-transition: transform 150ms ease 0s;
    -o-transition: transform 150ms ease 0s;
    -ms-transition: all 150ms ease 0s;
    transition: transform 150ms ease 0s;
}

.dropdown-toggle[aria-expanded="true"]:after,
[data-toggle="collapse"][data-parent="#accordion"][aria-expanded="true"] i {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.button-bar {
    display: block;
    position: relative;
    width: 22px;
    height: 1px;
    border-radius: 1px;
    background: #ffffff;
}
.button-bar + .button-bar {
    margin-top: 7px;
}
.button-bar:nth-child(2) {
    width: 17px;
}

.separator-line {
    height: 2px;
    width: 44px;
    background-color: #888888;
    margin: 20px auto;
}
.separator-line.separator-primary {
    background-color: #f96332;
}

.section-space {
    height: 62px;
    display: block;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.title-up {
    text-transform: uppercase;
}

.nav-pills.nav-pills-just-icons .nav-item .nav-link {
    text-align: center;
    border-radius: 50%;
    height: 80px;
    width: 80px;
    padding: 0;
    max-width: 80px;
    min-width: auto;
    margin-bottom: 4px;
}
.nav-pills.nav-pills-just-icons .nav-item .nav-link i {
    line-height: 80px;
}

.nav-pills:not(.flex-column) .nav-item:not(:last-child) .nav-link {
    margin-right: 19px;
}

.nav-pills .nav-item .nav-link {
    padding: 10px 23px;
    background-color: rgba(222, 222, 222, 0.3);
    min-width: 100px;
    font-weight: 400;
    text-align: center;
    color: #444;
}
.nav-pills .nav-item .nav-link:hover {
    background-color: rgba(222, 222, 222, 0.3);
}
.nav-pills .nav-item .nav-link.active,
.nav-pills .nav-item .nav-link.active:focus,
.nav-pills .nav-item .nav-link.active:hover {
    background-color: #9a9a9a;
    color: #ffffff;
    box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.3);
}
.nav-pills .nav-item .nav-link.disabled,
.nav-pills .nav-item .nav-link:disabled,
.nav-pills .nav-item .nav-link[disabled] {
    opacity: 0.5;
}

.nav-pills .nav-item i {
    display: block;
    line-height: 60px;
    font-size: 24px;
}

.nav-pills.nav-pills-neutral .nav-item .nav-link {
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
}
.nav-pills.nav-pills-neutral .nav-item .nav-link.active,
.nav-pills.nav-pills-neutral .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-neutral .nav-item .nav-link.active:hover {
    background-color: #ffffff;
    color: #f96332;
}

.nav-pills.nav-pills-primary .nav-item .nav-link.active,
.nav-pills.nav-pills-primary .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-primary .nav-item .nav-link.active:hover {
    background-color: #f96332;
}

.nav-pills.nav-pills-info .nav-item .nav-link.active,
.nav-pills.nav-pills-info .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-info .nav-item .nav-link.active:hover {
    background-color: #2ca8ff;
}

.nav-pills.nav-pills-success .nav-item .nav-link.active,
.nav-pills.nav-pills-success .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-success .nav-item .nav-link.active:hover {
    background-color: #18ce0f;
}

.nav-pills.nav-pills-warning .nav-item .nav-link.active,
.nav-pills.nav-pills-warning .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-warning .nav-item .nav-link.active:hover {
    background-color: #ffb236;
}

.nav-pills.nav-pills-danger .nav-item .nav-link.active,
.nav-pills.nav-pills-danger .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-danger .nav-item .nav-link.active:hover {
    background-color: #ff3636;
}

.tab-space {
    padding: 20px 0 50px 0px;
}

.nav-align-center {
    text-align: center;
}
.nav-align-center .nav-pills {
    display: inline-flex;
}

/* --------------------------------

Nucleo Outline Web Font - nucleoapp.com/
License - nucleoapp.com/license/
Created using IcoMoon - icomoon.io

-------------------------------- */
@font-face {
    font-family: "Nucleo Outline";
    src: url("../fonts/nucleo-outline.eot");
    src: url("../fonts/nucleo-outline.eot") format("embedded-opentype"),
        url("../fonts/nucleo-outline.woff2") format("woff2"),
        url("../fonts/nucleo-outline.woff") format("woff"),
        url("../fonts/nucleo-outline.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

/*------------------------
	base class definition
-------------------------*/
.now-ui-icons {
    display: inline-block;
    font: normal normal normal 14px/1 "Nucleo Outline";
    font-size: inherit;
    speak: none;
    text-transform: none;
    /* Better Font Rendering */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/*------------------------
  change icon size
-------------------------*/
/*----------------------------------
  add a square/circle background
-----------------------------------*/
.now-ui-icons.circle {
    padding: 0.33333333em;
    vertical-align: -16%;
    background-color: #eee;
}

.now-ui-icons.circle {
    border-radius: 50%;
}

/*------------------------
  list icons
-------------------------*/
.nc-icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}

.nc-icon-ul > li {
    position: relative;
}

.nc-icon-ul > li > .now-ui-icons {
    position: absolute;
    left: -1.57142857em;
    top: 0.14285714em;
    text-align: center;
}

.nc-icon-ul > li > .now-ui-icons.circle {
    top: -0.19047619em;
    left: -1.9047619em;
}

/*------------------------
  spinning icons
-------------------------*/
.now-ui-icons.spin {
    -webkit-animation: nc-icon-spin 2s infinite linear;
    -moz-animation: nc-icon-spin 2s infinite linear;
    animation: nc-icon-spin 2s infinite linear;
}

@-webkit-keyframes nc-icon-spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes nc-icon-spin {
    0% {
        -moz-transform: rotate(0deg);
    }
    100% {
        -moz-transform: rotate(360deg);
    }
}

@keyframes nc-icon-spin {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/*------------------------
  rotated/flipped icons
-------------------------*/
/*------------------------
	font icons
-------------------------*/
.now-ui-icons.ui-1_check:before {
    content: "\ea22";
}

.now-ui-icons.ui-1_email-85:before {
    content: "\ea2a";
}

.now-ui-icons.arrows-1_cloud-download-93:before {
    content: "\ea21";
}

.now-ui-icons.arrows-1_cloud-upload-94:before {
    content: "\ea24";
}

.now-ui-icons.arrows-1_minimal-down:before {
    content: "\ea39";
}

.now-ui-icons.arrows-1_minimal-left:before {
    content: "\ea3a";
}

.now-ui-icons.arrows-1_minimal-right:before {
    content: "\ea3b";
}

.now-ui-icons.arrows-1_minimal-up:before {
    content: "\ea3c";
}

.now-ui-icons.arrows-1_refresh-69:before {
    content: "\ea44";
}

.now-ui-icons.arrows-1_share-66:before {
    content: "\ea4c";
}

.now-ui-icons.business_badge:before {
    content: "\ea09";
}

.now-ui-icons.business_bank:before {
    content: "\ea0a";
}

.now-ui-icons.business_briefcase-24:before {
    content: "\ea13";
}

.now-ui-icons.business_bulb-63:before {
    content: "\ea15";
}

.now-ui-icons.business_chart-bar-32:before {
    content: "\ea1e";
}

.now-ui-icons.business_chart-pie-36:before {
    content: "\ea1f";
}

.now-ui-icons.business_globe:before {
    content: "\ea2f";
}

.now-ui-icons.business_money-coins:before {
    content: "\ea40";
}

.now-ui-icons.clothes_tie-bow:before {
    content: "\ea5b";
}

.now-ui-icons.design_vector:before {
    content: "\ea61";
}

.now-ui-icons.design_app:before {
    content: "\ea08";
}

.now-ui-icons.design_bullet-list-67:before {
    content: "\ea14";
}

.now-ui-icons.design_image:before {
    content: "\ea33";
}

.now-ui-icons.design_palette:before {
    content: "\ea41";
}

.now-ui-icons.design_scissors:before {
    content: "\ea4a";
}

.now-ui-icons.design-2_html5:before {
    content: "\ea32";
}

.now-ui-icons.design-2_ruler-pencil:before {
    content: "\ea48";
}

.now-ui-icons.emoticons_satisfied:before {
    content: "\ea49";
}

.now-ui-icons.files_box:before {
    content: "\ea12";
}

.now-ui-icons.files_paper:before {
    content: "\ea43";
}

.now-ui-icons.files_single-copy-04:before {
    content: "\ea52";
}

.now-ui-icons.health_ambulance:before {
    content: "\ea07";
}

.now-ui-icons.loader_gear:before {
    content: "\ea4e";
}

.now-ui-icons.loader_refresh:before {
    content: "\ea44";
}

.now-ui-icons.location_bookmark:before {
    content: "\ea10";
}

.now-ui-icons.location_compass-05:before {
    content: "\ea25";
}

.now-ui-icons.location_map-big:before {
    content: "\ea3d";
}

.now-ui-icons.location_pin:before {
    content: "\ea47";
}

.now-ui-icons.location_world:before {
    content: "\ea63";
}

.now-ui-icons.media-1_album:before {
    content: "\ea02";
}

.now-ui-icons.media-1_button-pause:before {
    content: "\ea16";
}

.now-ui-icons.media-1_button-play:before {
    content: "\ea18";
}

.now-ui-icons.media-1_button-power:before {
    content: "\ea19";
}

.now-ui-icons.media-1_camera-compact:before {
    content: "\ea1c";
}

.now-ui-icons.media-2_note-03:before {
    content: "\ea3f";
}

.now-ui-icons.media-2_sound-wave:before {
    content: "\ea57";
}

.now-ui-icons.objects_diamond:before {
    content: "\ea29";
}

.now-ui-icons.objects_globe:before {
    content: "\ea2f";
}

.now-ui-icons.objects_key-25:before {
    content: "\ea38";
}

.now-ui-icons.objects_planet:before {
    content: "\ea46";
}

.now-ui-icons.objects_spaceship:before {
    content: "\ea55";
}

.now-ui-icons.objects_support-17:before {
    content: "\ea56";
}

.now-ui-icons.objects_umbrella-13:before {
    content: "\ea5f";
}

.now-ui-icons.education_agenda-bookmark:before {
    content: "\ea01";
}

.now-ui-icons.education_atom:before {
    content: "\ea0c";
}

.now-ui-icons.education_glasses:before {
    content: "\ea2d";
}

.now-ui-icons.education_hat:before {
    content: "\ea30";
}

.now-ui-icons.education_paper:before {
    content: "\ea42";
}

.now-ui-icons.shopping_bag-16:before {
    content: "\ea0d";
}

.now-ui-icons.shopping_basket:before {
    content: "\ea0b";
}

.now-ui-icons.shopping_box:before {
    content: "\ea11";
}

.now-ui-icons.shopping_cart-simple:before {
    content: "\ea1d";
}

.now-ui-icons.shopping_credit-card:before {
    content: "\ea28";
}

.now-ui-icons.shopping_delivery-fast:before {
    content: "\ea27";
}

.now-ui-icons.shopping_shop:before {
    content: "\ea50";
}

.now-ui-icons.shopping_tag-content:before {
    content: "\ea59";
}

.now-ui-icons.sport_trophy:before {
    content: "\ea5d";
}

.now-ui-icons.sport_user-run:before {
    content: "\ea60";
}

.now-ui-icons.tech_controller-modern:before {
    content: "\ea26";
}

.now-ui-icons.tech_headphones:before {
    content: "\ea31";
}

.now-ui-icons.tech_laptop:before {
    content: "\ea36";
}

.now-ui-icons.tech_mobile:before {
    content: "\ea3e";
}

.now-ui-icons.tech_tablet:before {
    content: "\ea58";
}

.now-ui-icons.tech_tv:before {
    content: "\ea5e";
}

.now-ui-icons.tech_watch-time:before {
    content: "\ea62";
}

.now-ui-icons.text_align-center:before {
    content: "\ea05";
}

.now-ui-icons.text_align-left:before {
    content: "\ea06";
}

.now-ui-icons.text_bold:before {
    content: "\ea0e";
}

.now-ui-icons.text_caps-small:before {
    content: "\ea1b";
}

.now-ui-icons.gestures_tap-01:before {
    content: "\ea5a";
}

.now-ui-icons.transportation_air-baloon:before {
    content: "\ea03";
}

.now-ui-icons.transportation_bus-front-12:before {
    content: "\ea17";
}

.now-ui-icons.travel_info:before {
    content: "\ea04";
}

.now-ui-icons.travel_istanbul:before {
    content: "\ea34";
}

.now-ui-icons.ui-1_bell-53:before {
    content: "\ea0f";
}

.now-ui-icons.ui-1_calendar-60:before {
    content: "\ea1a";
}

.now-ui-icons.ui-1_lock-circle-open:before {
    content: "\ea35";
}

.now-ui-icons.ui-1_send:before {
    content: "\ea4d";
}

.now-ui-icons.ui-1_settings-gear-63:before {
    content: "\ea4e";
}

.now-ui-icons.ui-1_simple-add:before {
    content: "\ea4f";
}

.now-ui-icons.ui-1_simple-delete:before {
    content: "\ea54";
}

.now-ui-icons.ui-1_simple-remove:before {
    content: "\ea53";
}

.now-ui-icons.ui-1_zoom-bold:before {
    content: "\ea64";
}

.now-ui-icons.ui-2_chat-round:before {
    content: "\ea20";
}

.now-ui-icons.ui-2_favourite-28:before {
    content: "\ea2b";
}

.now-ui-icons.ui-2_like:before {
    content: "\ea37";
}

.now-ui-icons.ui-2_settings-90:before {
    content: "\ea4b";
}

.now-ui-icons.ui-2_time-alarm:before {
    content: "\ea5c";
}

.now-ui-icons.users_circle-08:before {
    content: "\ea23";
}

.now-ui-icons.users_single-02:before {
    content: "\ea51";
}

.all-icons .font-icon-detail {
    text-align: center;
    padding: 45px 0px 30px;
    border: 1px solid #e5e5e5;
    border-radius: 0.1875rem;
    margin: 15px 0;
    min-height: 168px;
}

.all-icons [class*="now-ui-icons"] {
    font-size: 32px;
}

.all-icons .font-icon-detail p {
    margin: 25px auto 0;
    width: 100%;
    text-align: center;
    display: block;
    color: #b8b8b8;
    padding: 0 10px;
    font-size: 0.7142em;
}

.nav-tabs {
    border: 0;
    padding: 15px 0.7rem;
}
.nav-tabs:not(.nav-tabs-neutral) > .nav-item > .nav-link.active {
    box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.3);
}
.card .nav-tabs {
    border-top-right-radius: 0.1875rem;
    border-top-left-radius: 0.1875rem;
}
.nav-tabs > .nav-item > .nav-link {
    color: #888888;
    margin: 0;
    margin-right: 5px;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 30px;
    font-size: 14px;
    padding: 11px 23px;
    line-height: 1.5;
}
.nav-tabs > .nav-item > .nav-link:hover {
    background-color: transparent;
}
.nav-tabs > .nav-item > .nav-link.active {
    background-color: #444;
    border-radius: 30px;
    color: #ffffff;
}
.nav-tabs > .nav-item > .nav-link i.now-ui-icons {
    font-size: 14px;
    position: relative;
    top: 1px;
    margin-right: 3px;
}
.nav-tabs > .nav-item.disabled > .nav-link,
.nav-tabs > .nav-item.disabled > .nav-link:hover {
    color: rgba(255, 255, 255, 0.5);
}
.nav-tabs.nav-tabs-neutral > .nav-item > .nav-link {
    color: #ffffff;
}
.nav-tabs.nav-tabs-neutral > .nav-item > .nav-link.active {
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
}
.nav-tabs.nav-tabs-primary > .nav-item > .nav-link.active {
    background-color: #f96332;
}
.nav-tabs.nav-tabs-info > .nav-item > .nav-link.active {
    background-color: #2ca8ff;
}
.nav-tabs.nav-tabs-danger > .nav-item > .nav-link.active {
    background-color: #ff3636;
}
.nav-tabs.nav-tabs-warning > .nav-item > .nav-link.active {
    background-color: #ffb236;
}
.nav-tabs.nav-tabs-success > .nav-item > .nav-link.active {
    background-color: #18ce0f;
}

.navbar {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    min-height: 53px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
}
.navbar a {
    vertical-align: middle;
}
.navbar a:not(.btn):not(.dropdown-item) {
    color: #ffffff;
}
.navbar p {
    display: inline-block;
    margin: 0;
    line-height: 21px;
    font-weight: inherit;
    font-size: inherit;
}
.navbar .navbar-nav.navbar-logo {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 49px;
    top: -4px;
}
.navbar .navbar-nav .nav-link.btn {
    padding: 11px 22px;
}
.navbar .navbar-nav .nav-link.btn.btn-lg {
    padding: 15px 48px;
}
.navbar .navbar-nav .nav-link.btn.btn-sm {
    padding: 5px 15px;
}
.navbar .navbar-nav .nav-link:not(.btn) {
    text-transform: uppercase;
    font-size: 0.7142em;
    padding: 0.5rem 0.7rem;
    line-height: 1.625rem;
}
.navbar .navbar-nav .nav-link:not(.btn) i.fab + p,
.navbar .navbar-nav .nav-link:not(.btn) i.now-ui-icons + p {
    margin-left: 3px;
}
.navbar .navbar-nav .nav-link:not(.btn) i.fab,
.navbar .navbar-nav .nav-link:not(.btn) i.now-ui-icons {
    font-size: 18px;
    position: relative;
    top: 3px;
    text-align: center;
    width: 21px;
}
.navbar .navbar-nav .nav-link:not(.btn) i.now-ui-icons {
    top: 4px;
    font-size: 16px;
}
.navbar .navbar-nav .nav-link:not(.btn).profile-photo .profile-photo-small {
    width: 27px;
    height: 27px;
}
.navbar .navbar-nav .nav-link:not(.btn).disabled {
    opacity: 0.5;
    color: #ffffff;
}
.navbar .navbar-nav .nav-item.active .nav-link:not(.btn),
.navbar .navbar-nav .nav-item .nav-link:not(.btn):focus,
.navbar .navbar-nav .nav-item .nav-link:not(.btn):hover,
.navbar .navbar-nav .nav-item .nav-link:not(.btn):active {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 0.1875rem;
}
.navbar .logo-container {
    width: 27px;
    height: 27px;
    overflow: hidden;
    margin: 0 auto;
    border-radius: 50%;
    border: 1px solid transparent;
}
.navbar .navbar-brand {
    text-transform: uppercase;
    font-size: 0.8571em;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    line-height: 1.625rem;
}
.navbar .navbar-toggler {
    width: 37px;
    height: 27px;
    outline: 0;
    cursor: pointer;
}
.navbar .navbar-toggler.navbar-toggler-left {
    position: relative;
    left: 0;
    padding-left: 0;
}
.navbar .navbar-toggler .navbar-toggler-bar.middle-bar {
    width: 17px;
    transition: width 0.2s linear;
}
.navbar .navbar-toggler:hover .navbar-toggler-bar.middle-bar {
    width: 22px;
}
.navbar .button-dropdown .navbar-toggler-bar:nth-child(2) {
    width: 17px;
}
.navbar.navbar-transparent {
    background-color: transparent !important;
    box-shadow: none;
    color: #ffffff;
    padding-top: 20px !important;
}
.navbar.navbar-black {
    background-color: black !important;
    box-shadow: none;
    color: #ffffff;
    padding-top: 20px !important;
}
.navbar.bg-white:not(.navbar-transparent) a:not(.dropdown-item):not(.btn) {
    color: #888888;
}
.navbar.bg-white:not(.navbar-transparent)
    a:not(.dropdown-item):not(.btn).disabled {
    opacity: 0.5;
    color: #888888;
}
.navbar.bg-white:not(.navbar-transparent) .button-bar {
    background: #888888;
}
.navbar.bg-white:not(.navbar-transparent) .nav-item.active .nav-link:not(.btn),
.navbar.bg-white:not(.navbar-transparent) .nav-item .nav-link:not(.btn):focus,
.navbar.bg-white:not(.navbar-transparent) .nav-item .nav-link:not(.btn):hover,
.navbar.bg-white:not(.navbar-transparent) .nav-item .nav-link:not(.btn):active {
    background-color: rgba(222, 222, 222, 0.3);
}
.navbar.bg-white:not(.navbar-transparent) .logo-container {
    border: 1px solid #888888;
}

.bg-default {
    background-color: #888888 !important;
}

.bg-primary {
    background-color: #f96332 !important;
}

.bg-info {
    background-color: #2ca8ff !important;
}

.bg-success {
    background-color: #18ce0f !important;
}

.bg-danger {
    background-color: #ff3636 !important;
}

.bg-warning {
    background-color: #ffb236 !important;
}

.bg-white {
    background-color: #ffffff !important;
}

.dropdown-menu {
    border: 0;
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
    border-radius: 0.125rem;
    -webkit-transition: all 150ms linear;
    -moz-transition: all 150ms linear;
    -o-transition: all 150ms linear;
    -ms-transition: all 150ms linear;
    transition: all 150ms linear;
    font-size: 14px;
}
.dropdown-menu.dropdown-menu-right:before {
    left: auto;
    right: 10px;
}
.dropdown .dropdown-menu {
    -webkit-transform: translate3d(0, -25px, 0) !important;
    -moz-transform: translate3d(0, -25px, 0) !important;
    -o-transform: translate3d(0, -25px, 0) !important;
    -ms-transform: translate3d(0, -25px, 0) !important;
    transform: translate3d(0, -25px, 0) !important;
    visibility: hidden;
    display: block;
    opacity: 0;
    filter: alpha(opacity=0);
    top: 100% !important;
}
.dropdown.show .dropdown-menu,
.dropdown-menu.open {
    opacity: 1;
    filter: alpha(opacity=100);
    visibility: visible;
    -webkit-transform: translate3d(0, 0px, 0) !important;
    -moz-transform: translate3d(0, 0px, 0) !important;
    -o-transform: translate3d(0, 0px, 0) !important;
    -ms-transform: translate3d(0, 0px, 0) !important;
    transform: translate3d(0, 0px, 0) !important;
}
.dropdown-menu .dropdown-item,
.bootstrap-select .dropdown-menu.inner li a {
    font-size: 0.8571em;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    margin-top: 5px;
    -webkit-transition: all 150ms linear;
    -moz-transition: all 150ms linear;
    -o-transition: all 150ms linear;
    -ms-transition: all 150ms linear;
    transition: all 150ms linear;
    outline: none;
}
.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus,
.bootstrap-select .dropdown-menu.inner li a:hover,
.bootstrap-select .dropdown-menu.inner li a:focus {
    background-color: rgba(222, 222, 222, 0.3);
}
.dropdown-menu .dropdown-item.disabled,
.dropdown-menu .dropdown-item:disabled,
.bootstrap-select .dropdown-menu.inner li a.disabled,
.bootstrap-select .dropdown-menu.inner li a:disabled {
    color: rgba(182, 182, 182, 0.6);
}
.dropdown-menu .dropdown-item.disabled:hover,
.dropdown-menu .dropdown-item.disabled:focus,
.dropdown-menu .dropdown-item:disabled:hover,
.dropdown-menu .dropdown-item:disabled:focus,
.bootstrap-select .dropdown-menu.inner li a.disabled:hover,
.bootstrap-select .dropdown-menu.inner li a.disabled:focus,
.bootstrap-select .dropdown-menu.inner li a:disabled:hover,
.bootstrap-select .dropdown-menu.inner li a:disabled:focus {
    background-color: transparent;
}
.dropdown-menu:before {
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    vertical-align: middle;
    content: "";
    top: -5px;
    left: 10px;
    right: auto;
    color: #ffffff;
    border-bottom: 0.4em solid;
    border-right: 0.4em solid transparent;
    border-left: 0.4em solid transparent;
}
.dropdown-menu .dropdown-divider {
    background-color: rgba(222, 222, 222, 0.5);
}
.dropdown-menu .dropdown-header:not([href]):not([tabindex]) {
    color: rgba(182, 182, 182, 0.6);
    font-size: 0.7142em;
    text-transform: uppercase;
    font-weight: 700;
}
.dropdown-menu.dropdown-primary {
    background-color: #f95823;
}
.dropdown-menu.dropdown-primary:before {
    color: #f95823;
}
.dropdown-menu.dropdown-primary .dropdown-header:not([href]):not([tabindex]) {
    color: rgba(255, 255, 255, 0.8);
}
.dropdown-menu.dropdown-primary .dropdown-item {
    color: #ffffff;
}
.dropdown-menu.dropdown-primary .dropdown-item:hover,
.dropdown-menu.dropdown-primary .dropdown-item:focus {
    background-color: rgba(255, 255, 255, 0.2);
}
.dropdown-menu.dropdown-primary .dropdown-divider {
    background-color: rgba(255, 255, 255, 0.2);
}
.dropdown-menu.dropdown-info {
    background-color: #1da2ff;
}
.dropdown-menu.dropdown-info:before {
    color: #1da2ff;
}
.dropdown-menu.dropdown-info .dropdown-header:not([href]):not([tabindex]) {
    color: rgba(255, 255, 255, 0.8);
}
.dropdown-menu.dropdown-info .dropdown-item {
    color: #ffffff;
}
.dropdown-menu.dropdown-info .dropdown-item:hover,
.dropdown-menu.dropdown-info .dropdown-item:focus {
    background-color: rgba(255, 255, 255, 0.2);
}
.dropdown-menu.dropdown-info .dropdown-divider {
    background-color: rgba(255, 255, 255, 0.2);
}
.dropdown-menu.dropdown-danger {
    background-color: #ff2727;
}
.dropdown-menu.dropdown-danger:before {
    color: #ff2727;
}
.dropdown-menu.dropdown-danger .dropdown-header:not([href]):not([tabindex]) {
    color: rgba(255, 255, 255, 0.8);
}
.dropdown-menu.dropdown-danger .dropdown-item {
    color: #ffffff;
}
.dropdown-menu.dropdown-danger .dropdown-item:hover,
.dropdown-menu.dropdown-danger .dropdown-item:focus {
    background-color: rgba(255, 255, 255, 0.2);
}
.dropdown-menu.dropdown-danger .dropdown-divider {
    background-color: rgba(255, 255, 255, 0.2);
}
.dropdown-menu.dropdown-success {
    background-color: #16c00e;
}
.dropdown-menu.dropdown-success:before {
    color: #16c00e;
}
.dropdown-menu.dropdown-success .dropdown-header:not([href]):not([tabindex]) {
    color: rgba(255, 255, 255, 0.8);
}
.dropdown-menu.dropdown-success .dropdown-item {
    color: #ffffff;
}
.dropdown-menu.dropdown-success .dropdown-item:hover,
.dropdown-menu.dropdown-success .dropdown-item:focus {
    background-color: rgba(255, 255, 255, 0.2);
}
.dropdown-menu.dropdown-success .dropdown-divider {
    background-color: rgba(255, 255, 255, 0.2);
}
.dropdown-menu.dropdown-warning {
    background-color: #ffac27;
}
.dropdown-menu.dropdown-warning:before {
    color: #ffac27;
}
.dropdown-menu.dropdown-warning .dropdown-header:not([href]):not([tabindex]) {
    color: rgba(255, 255, 255, 0.8);
}
.dropdown-menu.dropdown-warning .dropdown-item {
    color: #ffffff;
}
.dropdown-menu.dropdown-warning .dropdown-item:hover,
.dropdown-menu.dropdown-warning .dropdown-item:focus {
    background-color: rgba(255, 255, 255, 0.2);
}
.dropdown-menu.dropdown-warning .dropdown-divider {
    background-color: rgba(255, 255, 255, 0.2);
}

.button-dropdown {
    padding-right: 0.7rem;
    cursor: pointer;
}
.button-dropdown .dropdown-toggle {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: block;
}
.button-dropdown .dropdown-toggle:after {
    display: none;
}

.alert {
    border: 0;
    border-radius: 0;
    color: #ffffff;
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
}
.alert .alert-link {
    color: #ffffff;
    opacity: 0.8;
}
.alert.alert-success {
    background-color: rgba(24, 206, 15, 0.8);
}
.alert.alert-danger {
    background-color: rgba(255, 54, 54, 0.8);
}
.alert.alert-warning {
    background-color: rgba(255, 178, 54, 0.8);
}
.alert.alert-info {
    background-color: rgba(44, 168, 255, 0.8);
}
.alert.alert-primary {
    background-color: rgba(249, 99, 50, 0.8);
}
.alert .alert-icon {
    display: block;
    float: left;
    margin-right: 15px;
    margin-top: -1px;
}
.alert strong {
    text-transform: uppercase;
    font-size: 12px;
}
.alert i.fa,
.alert i.now-ui-icons {
    font-size: 20px;
}
.alert .close {
    color: #ffffff;
    opacity: 0.9;
    text-shadow: none;
    line-height: 0;
    outline: 0;
    display: inline-block;
    cursor: pointer;
}

img {
    max-width: 100%;
    border-radius: 1px;
}

.img-raised {
    box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
}

.popover {
    font-size: 14px;
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
    border: none;
    line-height: 1.7;
    max-width: 240px;
}
.popover.bs-popover-top .arrow:before,
.popover.bs-popover-left .arrow:before,
.popover.bs-popover-right .arrow:before,
.popover.bs-popover-bottom .arrow:before {
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
}
.popover .popover-header {
    color: rgba(182, 182, 182, 0.6);
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 600;
    margin: 0;
    margin-top: 5px;
    border: none;
    background-color: transparent;
}
.popover:before {
    display: none;
}
.popover.bs-tether-element-attached-top:after {
    border-bottom-color: #ffffff;
    top: -9px;
}
.popover.popover-primary {
    background-color: #f96332;
}
.popover.popover-primary .popover-body {
    color: #ffffff;
}
.popover.popover-primary.bs-popover-right .arrow:after {
    border-right-color: #f96332;
}
.popover.popover-primary.bs-popover-top .arrow:after {
    border-top-color: #f96332;
}
.popover.popover-primary.bs-popover-bottom .arrow:after {
    border-bottom-color: #f96332;
}
.popover.popover-primary.bs-popover-left .arrow:after {
    border-left-color: #f96332;
}
.popover.popover-primary .popover-header {
    color: #ffffff;
    opacity: 0.6;
}
.popover.popover-info {
    background-color: #2ca8ff;
}
.popover.popover-info .popover-body {
    color: #ffffff;
}
.popover.popover-info.bs-popover-right .arrow:after {
    border-right-color: #2ca8ff;
}
.popover.popover-info.bs-popover-top .arrow:after {
    border-top-color: #2ca8ff;
}
.popover.popover-info.bs-popover-bottom .arrow:after {
    border-bottom-color: #2ca8ff;
}
.popover.popover-info.bs-popover-left .arrow:after {
    border-left-color: #2ca8ff;
}
.popover.popover-info .popover-header {
    color: #ffffff;
    opacity: 0.6;
}
.popover.popover-warning {
    background-color: #ffb236;
}
.popover.popover-warning .popover-body {
    color: #ffffff;
}
.popover.popover-warning.bs-popover-right .arrow:after {
    border-right-color: #ffb236;
}
.popover.popover-warning.bs-popover-top .arrow:after {
    border-top-color: #ffb236;
}
.popover.popover-warning.bs-popover-bottom .arrow:after {
    border-bottom-color: #ffb236;
}
.popover.popover-warning.bs-popover-left .arrow:after {
    border-left-color: #ffb236;
}
.popover.popover-warning .popover-header {
    color: #ffffff;
    opacity: 0.6;
}
.popover.popover-danger {
    background-color: #ff3636;
}
.popover.popover-danger .popover-body {
    color: #ffffff;
}
.popover.popover-danger.bs-popover-right .arrow:after {
    border-right-color: #ff3636;
}
.popover.popover-danger.bs-popover-top .arrow:after {
    border-top-color: #ff3636;
}
.popover.popover-danger.bs-popover-bottom .arrow:after {
    border-bottom-color: #ff3636;
}
.popover.popover-danger.bs-popover-left .arrow:after {
    border-left-color: #ff3636;
}
.popover.popover-danger .popover-header {
    color: #ffffff;
    opacity: 0.6;
}
.popover.popover-success {
    background-color: #18ce0f;
}
.popover.popover-success .popover-body {
    color: #ffffff;
}
.popover.popover-success.bs-popover-right .arrow:after {
    border-right-color: #18ce0f;
}
.popover.popover-success.bs-popover-top .arrow:after {
    border-top-color: #18ce0f;
}
.popover.popover-success.bs-popover-bottom .arrow:after {
    border-bottom-color: #18ce0f;
}
.popover.popover-success.bs-popover-left .arrow:after {
    border-left-color: #18ce0f;
}
.popover.popover-success .popover-header {
    color: #ffffff;
    opacity: 0.6;
}

.tooltip.bs-tooltip-right .arrow:before {
    border-right-color: #ffffff;
}

.tooltip.bs-tooltip-top .arrow:before {
    border-top-color: #ffffff;
}

.tooltip.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #ffffff;
}

.tooltip.bs-tooltip-left .arrow:before {
    border-left-color: #ffffff;
}

.tooltip-inner {
    padding: 0.5rem 0.7rem;
    min-width: 130px;
    background-color: #ffffff;
    font-size: 14px;
    color: inherit;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
}

.modal-content {
    border-radius: 0.1875rem;
    border: none;
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.5);
}
.modal-content .modal-header {
    border-bottom: none;
    padding-top: 24px;
    padding-right: 24px;
    padding-bottom: 0;
    padding-left: 24px;
}
.modal-content .modal-header button {
    position: absolute;
    right: 27px;
    top: 30px;
    outline: 0;
}
.modal-content .modal-header .title {
    margin-top: 5px;
    margin-bottom: 0;
}
.modal-content .modal-body {
    padding-top: 24px;
    padding-right: 24px;
    padding-bottom: 16px;
    padding-left: 24px;
    line-height: 1.9;
}
.modal-content .modal-footer {
    border-top: none;
    padding-right: 24px;
    padding-bottom: 16px;
    padding-left: 24px;
    -webkit-justify-content: space-between;
    /* Safari 6.1+ */
    justify-content: space-between;
}
.modal-content .modal-footer button {
    margin: 0;
    padding-left: 16px;
    padding-right: 16px;
    width: auto;
}
.modal-content .modal-footer button.pull-left {
    padding-left: 5px;
    padding-right: 5px;
    position: relative;
    left: -5px;
}
.modal-content .modal-body + .modal-footer {
    padding-top: 0;
}

.modal-backdrop {
    background: rgba(0, 0, 0, 0.3);
}

.modal.modal-mini p {
    text-align: center;
}

.modal.modal-mini .modal-dialog {
    max-width: 255px;
    margin: 0 auto;
}

.modal.modal-mini .modal-profile {
    width: 70px;
    height: 70px;
    background-color: #ffffff;
    border-radius: 50%;
    text-align: center;
    line-height: 5.7;
    box-shadow: 0px 5px 50px 0px rgba(0, 0, 0, 0.3);
}
.modal.modal-mini .modal-profile i {
    color: #f96332;
    font-size: 21px;
}
.modal.modal-mini .modal-profile[class*="modal-profile-"] i {
    color: #ffffff;
}
.modal.modal-mini .modal-profile.modal-profile-primary {
    background-color: #f96332;
}
.modal.modal-mini .modal-profile.modal-profile-danger {
    background-color: #ff3636;
}
.modal.modal-mini .modal-profile.modal-profile-warning {
    background-color: #ffb236;
}
.modal.modal-mini .modal-profile.modal-profile-success {
    background-color: #18ce0f;
}
.modal.modal-mini .modal-profile.modal-profile-info {
    background-color: #2ca8ff;
}

.modal.modal-mini .modal-footer button {
    text-transform: uppercase;
}
.modal.modal-mini .modal-footer button:first-child {
    opacity: 0.5;
}

.modal.modal-default .modal-content {
    background-color: #ffffff;
    color: #2c2c2c;
}

.modal.modal-default .modal-header .close {
    color: #2c2c2c;
}

.modal.modal-primary .modal-content {
    background-color: #f96332;
    color: #ffffff;
}

.modal.modal-primary .modal-header .close {
    color: #ffffff;
}

.modal.modal-danger .modal-content {
    background-color: #ff3636;
    color: #ffffff;
}

.modal.modal-danger .modal-header .close {
    color: #ffffff;
}

.modal.modal-warning .modal-content {
    background-color: #ffb236;
    color: #ffffff;
}

.modal.modal-warning .modal-header .close {
    color: #ffffff;
}

.modal.modal-success .modal-content {
    background-color: #18ce0f;
    color: #ffffff;
}

.modal.modal-success .modal-header .close {
    color: #ffffff;
}

.modal.modal-info .modal-content {
    background-color: #2ca8ff;
    color: #ffffff;
}

.modal.modal-info .modal-header .close {
    color: #ffffff;
}

.modal.show.modal-mini .modal-dialog {
    -webkit-transform: translate(0, 30%);
    -o-transform: translate(0, 30%);
    transform: translate(0, 30%);
}

.modal .modal-header .close {
    color: #ff3636;
    text-shadow: none;
}
.modal .modal-header .close:hover,
.modal .modal-header .close:focus {
    opacity: 1;
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
    display: block;
}

.carousel .carousel-inner {
    box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
    border-radius: 0.1875rem;
}

.carousel .card {
    max-width: 650px;
    margin: 60px auto;
    display: block;
}

.footer {
    padding: 24px 0;
}
.footer.footer-default {
    background-color: #f2f2f2;
}
.footer nav {
    float: left;
}
.footer ul {
    margin-bottom: 0;
    padding: 0;
    list-style: none;
}
.footer ul li {
    display: inline-block;
}
.footer ul li a {
    color: inherit;
    padding: 0.5rem;
    font-size: 0.8571em;
    text-decoration: none;
}
.footer ul li a:hover {
    text-decoration: none;
}
.footer .copyright {
    font-size: 0.8571em;
}
.footer:after {
    display: table;
    clear: both;
    content: " ";
}

.card {
    border: 0;
    border-radius: 0.1875rem;
    display: inline-block;
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
}
.card .card-header {
    background-color: transparent;
    border-bottom: 0;
    background-color: transparent;
    border-radius: 0;
    padding: 0;
}
.card .card-footer {
    padding: 0;
    margin-top: 15px;
    background-color: transparent;
    border: 0;
}
.card[data-background-color="orange"] {
    background-color: #f96332;
}
.card[data-background-color="red"] {
    background-color: #ff3636;
}
.card[data-background-color="yellow"] {
    background-color: #ffb236;
}
.card[data-background-color="blue"] {
    background-color: #2ca8ff;
}
.card[data-background-color="green"] {
    background-color: #15b60d;
}

.card-plain {
    background: transparent;
    box-shadow: none;
}
.card-plain .card-body,
.card-plain .card-header {
    padding-left: 5px;
    padding-right: 5px;
}
.card-plain .card-header:after {
    width: 100%;
    left: 0;
}

.card-signup {
    max-width: 350px;
    margin: 0 auto;
}
.card-signup .card-header {
    margin: 0 20px;
    padding: 30px 0;
}
.card-signup .card-title {
    margin-top: 15px;
    margin-bottom: 15px;
}
.card-signup .card-footer {
    margin-bottom: 10px;
    margin-top: 24px;
    padding: 24px 0;
}
.card-signup .card-body {
    padding-top: 0px;
    padding-bottom: 0px;
    min-height: auto;
}
.card-signup .form-check,
.card-signup .form-check.form-check-radio {
    margin-top: 20px;
}
.card-signup .form-check label,
.card-signup .form-check.form-check-radio label {
    margin-left: 11px;
    color: initial;
    padding-left: 40px;
}
.card-signup .card-description {
    margin-top: 15px;
    margin-bottom: 20px;
}
.card-signup .social-line {
    margin-top: 20px;
    text-align: center;
}
.card-signup .social-line .btn.btn-icon,
.card-signup .social-line .btn.btn-icon .btn-icon {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 0;
    margin-bottom: 0;
    box-shadow: 0px 5px 50px 0px rgba(0, 0, 0, 0.2);
}

.index-page .page-header {
    height: 125vh;
}
.index-page .page-header .container > .content-center {
    top: 37%;
}
.index-page .page-header .category-absolute {
    position: absolute;
    top: 100vh;
    margin-top: -60px;
    padding: 0 15px;
    width: 100%;
    color: rgba(255, 255, 255, 0.5);
}

.landing-page .header {
    height: 100vh;
    position: relative;
}
.landing-page .header .container {
    padding-top: 26vh;
    color: #ffffff;
    z-index: 2;
    position: relative;
}
.landing-page .header .share {
    margin-top: 150px;
}
.landing-page .header h1 {
    font-weight: 600;
}
.landing-page .header .title {
    color: #ffffff;
}

.landing-page .section-team .team .team-player img {
    max-width: 100px;
}

.landing-page .section-team .team-player {
    margin-bottom: 15px;
}

.landing-page .section-contact-us .title {
    margin-bottom: 15px;
}

.landing-page .section-contact-us .description {
    margin-bottom: 30px;
}

.landing-page .section-contact-us .input-group,
.landing-page .section-contact-us .send-button,
.landing-page .section-contact-us .textarea-container {
    padding: 0 40px;
}

.landing-page .section-contact-us .textarea-container {
    margin: 40px 0;
}

.landing-page .section-contact-us a.btn {
    margin-top: 35px;
}

.profile-page .page-header {
    min-height: 550px;
}

.profile-page .profile-container {
    color: #ffffff;
}

.profile-page .photo-container {
    width: 123px;
    height: 123px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
}

.profile-page .title {
    text-align: center;
    margin-top: 30px;
}

.profile-page .description,
.profile-page .category {
    text-align: center;
}

.profile-page h5.description {
    max-width: 700px;
    margin: 20px auto 75px;
}

.profile-page .nav-align-center {
    margin-top: 30px;
}

.profile-page .content {
    max-width: 450px;
    margin: 0 auto;
}
.profile-page .content .social-description {
    display: inline-block;
    max-width: 150px;
    width: 145px;
    text-align: center;
    margin: 15px 0 0px;
}
.profile-page .content .social-description h2 {
    margin-bottom: 15px;
}

.profile-page .button-container {
    text-align: center;
    margin-top: -106px;
}

.profile-page .collections img {
    margin-bottom: 30px;
}

.profile-page .gallery {
    margin-top: 45px;
    padding-bottom: 50px;
}

.section-full-page:after,
.section-full-page:before {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
}

.section-full-page:before {
    background-color: rgba(0, 0, 0, 0.5);
}

.section-full-page[filter-color="purple"]:after,
.section-full-page[filter-color="primary"]:after {
    background: rgba(227, 227, 227, 0.26);
    /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(
        90deg,
        rgba(227, 227, 227, 0.26),
        rgba(249, 99, 50, 0.95)
    );
    /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(
        90deg,
        rgba(227, 227, 227, 0.26),
        rgba(249, 99, 50, 0.95)
    );
    /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(
        90deg,
        rgba(227, 227, 227, 0.26),
        rgba(249, 99, 50, 0.95)
    );
    /* For Firefox 3.6 to 15 */
    background: linear-gradient(
        0deg,
        rgba(227, 227, 227, 0.26),
        rgba(249, 99, 50, 0.95)
    );
    /* Standard syntax */
}

.section-full-page[data-image]:after {
    opacity: 0.5;
}

.section-full-page > .content,
.section-full-page > .footer {
    position: relative;
    z-index: 4;
}

.section-full-page > .content {
    min-height: calc(100vh - 80px);
}

.section-full-page .full-page-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
}

.section-full-page .footer nav > ul a:not(.btn),
.section-full-page .footer,
.section-full-page .footer .copyright a {
    color: #ffffff;
}

.login-page .page-header:before,
.profile-page .page-header:before {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
}

.login-page .card-login {
    border-radius: 0.25rem;
    padding-bottom: 0.7rem;
    max-width: 320px;
}
.login-page .card-login .btn-wd {
    min-width: 180px;
}
.login-page .card-login .logo-container {
    width: 65px;
    margin: 0 auto;
    margin-bottom: 55px;
}
.login-page .card-login .logo-container img {
    width: 100%;
}
.login-page .card-login .input-group:last-child {
    margin-bottom: 40px;
}
.login-page .card-login.card-plain .form-control::-moz-placeholder {
    color: #ebebeb;
    opacity: 1;
    filter: alpha(opacity=100);
}
.login-page .card-login.card-plain .form-control:-moz-placeholder {
    color: #ebebeb;
    opacity: 1;
    filter: alpha(opacity=100);
}
.login-page .card-login.card-plain .form-control::-webkit-input-placeholder {
    color: #ebebeb;
    opacity: 1;
    filter: alpha(opacity=100);
}
.login-page .card-login.card-plain .form-control:-ms-input-placeholder {
    color: #ebebeb;
    opacity: 1;
    filter: alpha(opacity=100);
}
.login-page .card-login.card-plain .form-control {
    border-color: rgba(255, 255, 255, 0.5);
    color: #ffffff;
}
.login-page .card-login.card-plain .form-control:focus {
    border-color: #ffffff;
    background-color: transparent;
    color: #ffffff;
}
.login-page .card-login.card-plain .has-success:after,
.login-page .card-login.card-plain .has-danger:after {
    color: #ffffff;
}
.login-page .card-login.card-plain .has-danger .form-control {
    background-color: transparent;
}
.login-page .card-login.card-plain .input-group-text {
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.5);
    color: #ffffff;
}
.login-page .card-login.card-plain .input-group-focus .input-group-text {
    background-color: transparent;
    border-color: #ffffff;
    color: #ffffff;
}
.login-page .card-login.card-plain .form-group.no-border .form-control,
.login-page .card-login.card-plain .input-group.no-border .form-control {
    background-color: rgba(255, 255, 255, 0.1);
    color: #ffffff;
}
.login-page .card-login.card-plain .form-group.no-border .form-control:focus,
.login-page .card-login.card-plain .form-group.no-border .form-control:active,
.login-page .card-login.card-plain .form-group.no-border .form-control:active,
.login-page .card-login.card-plain .input-group.no-border .form-control:focus,
.login-page .card-login.card-plain .input-group.no-border .form-control:active,
.login-page .card-login.card-plain .input-group.no-border .form-control:active {
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
}
.login-page
    .card-login.card-plain
    .form-group.no-border
    .form-control
    + .input-group-text,
.login-page
    .card-login.card-plain
    .input-group.no-border
    .form-control
    + .input-group-text {
    background-color: rgba(255, 255, 255, 0.1);
}
.login-page
    .card-login.card-plain
    .form-group.no-border
    .form-control
    + .input-group-text:focus,
.login-page
    .card-login.card-plain
    .form-group.no-border
    .form-control
    + .input-group-text:active,
.login-page
    .card-login.card-plain
    .form-group.no-border
    .form-control
    + .input-group-text:active,
.login-page
    .card-login.card-plain
    .input-group.no-border
    .form-control
    + .input-group-text:focus,
.login-page
    .card-login.card-plain
    .input-group.no-border
    .form-control
    + .input-group-text:active,
.login-page
    .card-login.card-plain
    .input-group.no-border
    .form-control
    + .input-group-text:active {
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
}
.login-page
    .card-login.card-plain
    .form-group.no-border
    .form-control:focus
    + .input-group-text,
.login-page
    .card-login.card-plain
    .input-group.no-border
    .form-control:focus
    + .input-group-text {
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
}
.login-page .card-login.card-plain .form-group.no-border .input-group-text,
.login-page .card-login.card-plain .input-group.no-border .input-group-text {
    background-color: rgba(255, 255, 255, 0.1);
    border: none;
    color: #ffffff;
}
.login-page
    .card-login.card-plain
    .form-group.no-border.input-group-focus
    .input-group-text,
.login-page
    .card-login.card-plain
    .input-group.no-border.input-group-focus
    .input-group-text {
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
}
.login-page .card-login.card-plain .input-group-text,
.login-page .card-login.card-plain .form-group.no-border .input-group-text,
.login-page .card-login.card-plain .input-group.no-border .input-group-text {
    color: rgba(255, 255, 255, 0.8);
}

.login-page .link {
    font-size: 10px;
    color: #ffffff;
    text-decoration: none;
}

.section {
    padding: 70px 0;
    position: relative;
    background: #ffffff;
}
.section .row + .category {
    margin-top: 15px;
}

.section-navbars {
    padding-bottom: 0;
}

.section-full-screen {
    height: 100vh;
}

.section-signup {
    padding-top: 20vh;
}

.page-header {
    min-height: 100vh;
    max-height: 999px;
    padding: 0;
    color: #ffffff;
    position: relative;
    overflow: hidden;
}
.page-header > .content {
    margin-top: 12%;
    text-align: center;
    margin-bottom: 50px;
}
.page-header.page-header-small {
    min-height: 60vh;
    max-height: 440px;
}
.page-header:before {
    background-color: rgba(0, 0, 0, 0.3);
}
.page-header > .container {
    z-index: 2;
    padding-top: 12vh;
    padding-bottom: 40px;
}
.page-header .page-header-image {
    position: absolute;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.page-header .content-center {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    color: #ffffff;
    padding: 0 15px;
    width: 100%;
    max-width: 880px;
}
.page-header footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}
.page-header .container {
    height: 100%;
    z-index: 1;
    text-align: center;
    position: relative;
}
.page-header .category,
.page-header .description {
    color: rgba(255, 255, 255, 0.8);
}
.page-header:after,
.page-header:before {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    content: "";
}

.clear-filter:after,
.clear-filter:before {
    display: none;
}

.clear-filter[filter-color="orange"] {
    background: rgba(44, 44, 44, 0.2);
    /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(
        90deg,
        rgba(44, 44, 44, 0.2),
        rgba(224, 23, 3, 0.6)
    );
    /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(
        90deg,
        rgba(44, 44, 44, 0.2),
        rgba(224, 23, 3, 0.6)
    );
    /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(
        90deg,
        rgba(44, 44, 44, 0.2),
        rgba(224, 23, 3, 0.6)
    );
    /* For Firefox 3.6 to 15 */
    background: linear-gradient(
        0deg,
        rgba(44, 44, 44, 0.2),
        rgba(224, 23, 3, 0.6)
    );
    /* Standard syntax */
}

.section-story-overview {
    padding: 50px 0;
}
.section-story-overview .image-container {
    height: 335px;
    position: relative;
    background-position: center center;
    background-size: cover;
    box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
    border-radius: 0.25rem;
}
.section-story-overview .image-container + .category {
    padding-top: 15px;
}
.section-story-overview .image-container.image-right {
    z-index: 2;
}
.section-story-overview .image-container.image-right + h3.title {
    margin-top: 120px;
}
.section-story-overview .image-container.image-left {
    z-index: 1;
}
.section-story-overview .image-container:nth-child(2) {
    margin-top: 420px;
    margin-left: -105px;
}
.section-story-overview p.blockquote {
    width: 220px;
    min-height: 180px;
    text-align: left;
    position: absolute;
    top: 376px;
    right: 155px;
    z-index: 0;
}

.section-nucleo-icons .nucleo-container img {
    width: auto;
    left: 0;
    top: 0;
    height: 100%;
    position: absolute;
}

.section-nucleo-icons .nucleo-container {
    height: 335px;
    position: relative;
}

.section-nucleo-icons h5 {
    margin-bottom: 35px;
}

.section-nucleo-icons .icons-container {
    position: relative;
    max-width: 450px;
    height: 300px;
    max-height: 300px;
    margin: 0 auto;
}

.section-nucleo-icons .icons-container i {
    font-size: 34px;
    position: absolute;
    left: 0;
    top: 0;
}

.section-nucleo-icons .icons-container i:nth-child(1) {
    top: 5%;
    left: 7%;
}

.section-nucleo-icons .icons-container i:nth-child(2) {
    top: 28%;
    left: 24%;
}

.section-nucleo-icons .icons-container i:nth-child(3) {
    top: 40%;
}

.section-nucleo-icons .icons-container i:nth-child(4) {
    top: 18%;
    left: 62%;
}

.section-nucleo-icons .icons-container i:nth-child(5) {
    top: 74%;
    left: 3%;
}

.section-nucleo-icons .icons-container i:nth-child(6) {
    top: 36%;
    left: 44%;
    font-size: 65px;
    color: #f96332;
    padding: 1px;
}

.section-nucleo-icons .icons-container i:nth-child(7) {
    top: 59%;
    left: 26%;
}

.section-nucleo-icons .icons-container i:nth-child(8) {
    top: 60%;
    left: 69%;
}

.section-nucleo-icons .icons-container i:nth-child(9) {
    top: 72%;
    left: 47%;
}

.section-nucleo-icons .icons-container i:nth-child(10) {
    top: 88%;
    left: 27%;
}

.section-nucleo-icons .icons-container i:nth-child(11) {
    top: 31%;
    left: 80%;
}

.section-nucleo-icons .icons-container i:nth-child(12) {
    top: 88%;
    left: 68%;
}

.section-nucleo-icons .icons-container i:nth-child(13) {
    top: 5%;
    left: 81%;
}

.section-nucleo-icons .icons-container i:nth-child(14) {
    top: 58%;
    left: 90%;
}

.section-nucleo-icons .icons-container i:nth-child(15) {
    top: 6%;
    left: 40%;
}

.section-images {
    max-height: 670px;
    height: 670px;
}
.section-images .hero-images-container,
.section-images .hero-images-container-1,
.section-images .hero-images-container-2 {
    margin-top: -38vh;
}
.section-images .hero-images-container {
    max-width: 670px;
}
.section-images .hero-images-container-1 {
    max-width: 390px;
    position: absolute;
    top: 55%;
    right: 18%;
}
.section-images .hero-images-container-2 {
    max-width: 225px;
    position: absolute;
    top: 68%;
    right: 12%;
}

[data-background-color="orange"] {
    background-color: #e95e38;
}

[data-background-color="black"] {
    background-color: #2c2c2c;
}

[data-background-color]:not([data-background-color="gray"]) {
    color: #ffffff;
}
[data-background-color]:not([data-background-color="gray"]) .title,
[data-background-color]:not([data-background-color="gray"])
    .social-description
    h2,
[data-background-color]:not([data-background-color="gray"]) p,
[data-background-color]:not([data-background-color="gray"]) p.blockquote,
[data-background-color]:not([data-background-color="gray"]) p.blockquote small {
    color: #ffffff;
}
[data-background-color]:not([data-background-color="gray"]) .separator {
    background-color: #ffffff;
}
[data-background-color]:not([data-background-color="gray"]) .navbar.bg-white p {
    color: #888888;
}
[data-background-color]:not([data-background-color="gray"]) h1,
[data-background-color]:not([data-background-color="gray"]) h2,
[data-background-color]:not([data-background-color="gray"]) h3,
[data-background-color]:not([data-background-color="gray"]) h4,
[data-background-color]:not([data-background-color="gray"]) h5,
[data-background-color]:not([data-background-color="gray"]) h6,
[data-background-color]:not([data-background-color="gray"])
    a:not(.btn):not(.dropdown-item),
[data-background-color]:not([data-background-color="gray"]) .icons-container {
    color: #ffffff;
}
[data-background-color]:not([data-background-color="gray"]) .input-group-text,
[data-background-color]:not([data-background-color="gray"])
    .form-group.no-border
    .input-group-text,
[data-background-color]:not([data-background-color="gray"])
    .input-group.no-border
    .input-group-text {
    color: rgba(255, 255, 255, 0.8);
}
[data-background-color]:not([data-background-color="gray"]) .description,
[data-background-color]:not([data-background-color="gray"])
    .social-description
    p {
    color: rgba(255, 255, 255, 0.8);
}
[data-background-color]:not([data-background-color="gray"]) p.blockquote {
    border-color: rgba(255, 255, 255, 0.2);
}
[data-background-color]:not([data-background-color="gray"])
    .checkbox
    label::before,
[data-background-color]:not([data-background-color="gray"])
    .checkbox
    label::after,
[data-background-color]:not([data-background-color="gray"])
    .radio
    label::before,
[data-background-color]:not([data-background-color="gray"])
    .radio
    label::after {
    border-color: rgba(255, 255, 255, 0.2);
}
[data-background-color]:not([data-background-color="gray"])
    .checkbox
    label::after,
[data-background-color]:not([data-background-color="gray"]) .checkbox label,
[data-background-color]:not([data-background-color="gray"]) .radio label {
    color: #ffffff;
}
[data-background-color]:not([data-background-color="gray"])
    .checkbox
    input[type="checkbox"]:disabled
    + label,
[data-background-color]:not([data-background-color="gray"])
    .radio
    input[type="radio"]:disabled
    + label {
    color: #ffffff;
}
[data-background-color]:not([data-background-color="gray"])
    .radio
    input[type="radio"]:not(:disabled):hover
    + label::after,
[data-background-color]:not([data-background-color="gray"])
    .radio
    input[type="radio"]:checked
    + label::after {
    background-color: #ffffff;
    border-color: #ffffff;
}
[data-background-color]:not([data-background-color="gray"])
    .form-control::-moz-placeholder {
    color: #ebebeb;
    opacity: 1;
    filter: alpha(opacity=100);
}
[data-background-color]:not([data-background-color="gray"])
    .form-control:-moz-placeholder {
    color: #ebebeb;
    opacity: 1;
    filter: alpha(opacity=100);
}
[data-background-color]:not([data-background-color="gray"])
    .form-control::-webkit-input-placeholder {
    color: #ebebeb;
    opacity: 1;
    filter: alpha(opacity=100);
}
[data-background-color]:not([data-background-color="gray"])
    .form-control:-ms-input-placeholder {
    color: #ebebeb;
    opacity: 1;
    filter: alpha(opacity=100);
}
[data-background-color]:not([data-background-color="gray"]) .form-control {
    border-color: rgba(255, 255, 255, 0.5);
    color: #ffffff;
}
[data-background-color]:not([data-background-color="gray"])
    .form-control:focus {
    border-color: #ffffff;
    background-color: transparent;
    color: #ffffff;
}
[data-background-color]:not([data-background-color="gray"]) .has-success:after,
[data-background-color]:not([data-background-color="gray"]) .has-danger:after {
    color: #ffffff;
}
[data-background-color]:not([data-background-color="gray"])
    .has-danger
    .form-control {
    background-color: transparent;
}
[data-background-color]:not([data-background-color="gray"]) .input-group-text {
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.5);
    color: #ffffff;
}
[data-background-color]:not([data-background-color="gray"])
    .input-group-focus
    .input-group-text {
    background-color: transparent;
    border-color: #ffffff;
    color: #ffffff;
}
[data-background-color]:not([data-background-color="gray"])
    .form-group.no-border
    .form-control,
[data-background-color]:not([data-background-color="gray"])
    .input-group.no-border
    .form-control {
    background-color: rgba(255, 255, 255, 0.1);
    color: #ffffff;
}
[data-background-color]:not([data-background-color="gray"])
    .form-group.no-border
    .form-control:focus,
[data-background-color]:not([data-background-color="gray"])
    .form-group.no-border
    .form-control:active,
[data-background-color]:not([data-background-color="gray"])
    .form-group.no-border
    .form-control:active,
[data-background-color]:not([data-background-color="gray"])
    .input-group.no-border
    .form-control:focus,
[data-background-color]:not([data-background-color="gray"])
    .input-group.no-border
    .form-control:active,
[data-background-color]:not([data-background-color="gray"])
    .input-group.no-border
    .form-control:active {
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
}
[data-background-color]:not([data-background-color="gray"])
    .form-group.no-border
    .form-control
    + .input-group-text,
[data-background-color]:not([data-background-color="gray"])
    .input-group.no-border
    .form-control
    + .input-group-text {
    background-color: rgba(255, 255, 255, 0.1);
}
[data-background-color]:not([data-background-color="gray"])
    .form-group.no-border
    .form-control
    + .input-group-text:focus,
[data-background-color]:not([data-background-color="gray"])
    .form-group.no-border
    .form-control
    + .input-group-text:active,
[data-background-color]:not([data-background-color="gray"])
    .form-group.no-border
    .form-control
    + .input-group-text:active,
[data-background-color]:not([data-background-color="gray"])
    .input-group.no-border
    .form-control
    + .input-group-text:focus,
[data-background-color]:not([data-background-color="gray"])
    .input-group.no-border
    .form-control
    + .input-group-text:active,
[data-background-color]:not([data-background-color="gray"])
    .input-group.no-border
    .form-control
    + .input-group-text:active {
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
}
[data-background-color]:not([data-background-color="gray"])
    .form-group.no-border
    .form-control:focus
    + .input-group-text,
[data-background-color]:not([data-background-color="gray"])
    .input-group.no-border
    .form-control:focus
    + .input-group-text {
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
}
[data-background-color]:not([data-background-color="gray"])
    .form-group.no-border
    .input-group-text,
[data-background-color]:not([data-background-color="gray"])
    .input-group.no-border
    .input-group-text {
    background-color: rgba(255, 255, 255, 0.1);
    border: none;
    color: #ffffff;
}
[data-background-color]:not([data-background-color="gray"])
    .form-group.no-border.input-group-focus
    .input-group-text,
[data-background-color]:not([data-background-color="gray"])
    .input-group.no-border.input-group-focus
    .input-group-text {
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
}
[data-background-color]:not([data-background-color="gray"])
    .btn[class*="btn-outline-"] {
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.5);
    color: #ffffff;
}
[data-background-color]:not([data-background-color="gray"])
    .btn[class*="btn-outline-"]:hover,
[data-background-color]:not([data-background-color="gray"])
    .btn[class*="btn-outline-"]:focus,
[data-background-color]:not([data-background-color="gray"])
    .btn[class*="btn-outline-"]:not(:disabled):not(.disabled):active,
[data-background-color]:not([data-background-color="gray"])
    .btn[class*="btn-outline-"]:not(:disabled):not(.disabled).active,
[data-background-color]:not([data-background-color="gray"])
    .btn[class*="btn-outline-"]:not(:disabled):not(.disabled):active:focus,
[data-background-color]:not([data-background-color="gray"])
    .btn[class*="btn-outline-"]:not(:disabled):not(.disabled).active:focus,
[data-background-color]:not([data-background-color="gray"])
    .btn[class*="btn-outline-"]:active:hover,
[data-background-color]:not([data-background-color="gray"])
    .btn[class*="btn-outline-"].active:hover,
.show
    > [data-background-color]:not([data-background-color="gray"])
    .btn[class*="btn-outline-"].dropdown-toggle,
.show
    > [data-background-color]:not([data-background-color="gray"])
    .btn[class*="btn-outline-"].dropdown-toggle:focus,
.show
    > [data-background-color]:not([data-background-color="gray"])
    .btn[class*="btn-outline-"].dropdown-toggle:hover {
    background-color: transparent;
    border-color: #ffffff;
    color: #ffffff;
}
[data-background-color]:not([data-background-color="gray"])
    .nav-tabs
    > .nav-item
    > .nav-link
    i.now-ui-icons {
    color: #ffffff;
}
[data-background-color]:not([data-background-color="gray"]).section-nucleo-icons
    .icons-container
    i:nth-child(6) {
    color: #ffffff;
}

@media screen and (max-width: 991px) {
    .sidebar-collapse .navbar-collapse {
        position: fixed;
        display: block !important;
        top: 0;
        height: 100% !important;
        width: 300px;
        right: 0;
        z-index: 1032;
        visibility: visible;
        background-color: #999;
        overflow-y: visible;
        border-top: none;
        text-align: left;
        max-height: none !important;
        -webkit-transform: translate3d(300px, 0, 0);
        -moz-transform: translate3d(300px, 0, 0);
        -o-transform: translate3d(300px, 0, 0);
        -ms-transform: translate3d(300px, 0, 0);
        transform: translate3d(300px, 0, 0);
        -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -moz-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -ms-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
    .sidebar-collapse .navbar-collapse:before {
        background: #f96332;
        /* For browsers that do not support gradients */
        background: -webkit-linear-gradient(#f96332 0%, #000 80%);
        /* For Safari 5.1 to 6.0 */
        background: -o-linear-gradient(#f96332 0%, #000 80%);
        /* For Opera 11.1 to 12.0 */
        background: -moz-linear-gradient(#f96332 0%, #000 80%);
        /* For Firefox 3.6 to 15 */
        background: linear-gradient(#f96332 0%, #000 80%);
        /* Standard syntax (must be last) */
        opacity: 0.76;
        filter: alpha(opacity=76);
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
    }
    .sidebar-collapse .navbar-collapse .navbar-nav:not(.navbar-logo) .nav-link {
        margin: 0 1rem;
        margin-top: 0.3125rem;
    }
    .sidebar-collapse
        .navbar-collapse
        .navbar-nav:not(.navbar-logo)
        .nav-link:not(.btn) {
        color: #ffffff !important;
    }
    .sidebar-collapse .navbar-collapse .dropdown-menu .dropdown-item {
        color: #ffffff;
    }
    .sidebar-collapse.menu-on-left .navbar-translate {
        flex-direction: row-reverse;
    }
    .sidebar-collapse .navbar .navbar-nav {
        margin-top: 53px;
        position: relative;
        max-height: calc(100vh - 75px);
        min-height: 100%;
        overflow: auto;
    }
    .sidebar-collapse .navbar .navbar-nav.navbar-logo {
        top: 0;
        height: 53px;
    }
    .sidebar-collapse .navbar .dropdown.show .dropdown-menu,
    .sidebar-collapse .navbar .dropdown .dropdown-menu {
        background-color: transparent;
        border: 0;
        transition: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        width: auto;
        margin: 0 1rem;
        margin-bottom: 15px;
        padding-top: 0;
        height: 125px;
        overflow-y: scroll;
    }
    .sidebar-collapse .navbar .dropdown.show .dropdown-menu:before,
    .sidebar-collapse .navbar .dropdown .dropdown-menu:before {
        display: none;
    }
    .sidebar-collapse .navbar .dropdown .dropdown-item {
        padding-left: 2.5rem;
    }
    .sidebar-collapse .navbar .dropdown .dropdown-menu {
        display: none;
    }
    .sidebar-collapse .navbar .dropdown.show .dropdown-menu {
        display: block;
    }
    .sidebar-collapse .navbar .dropdown-menu .dropdown-item:focus,
    .sidebar-collapse .navbar .dropdown-menu .dropdown-item:hover {
        color: #ffffff;
        border-radius: 0.1875rem;
    }
    .sidebar-collapse .navbar .navbar-translate {
        width: 100%;
        position: relative;
        display: flex;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
        -ms-flex-align: center;
        align-items: center;
        -webkit-transform: translate3d(0px, 0, 0);
        -moz-transform: translate3d(0px, 0, 0);
        -o-transform: translate3d(0px, 0, 0);
        -ms-transform: translate3d(0px, 0, 0);
        transform: translate3d(0px, 0, 0);
        -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -moz-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -ms-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
    .sidebar-collapse .navbar .navbar-toggler-bar {
        display: block;
        position: relative;
        width: 22px;
        height: 1px;
        border-radius: 1px;
        background: #ffffff;
    }
    .sidebar-collapse .navbar .navbar-toggler-bar + .navbar-toggler-bar {
        margin-top: 7px;
    }
    .sidebar-collapse .navbar .navbar-toggler-bar.bar2 {
        width: 17px;
        transition: width 0.2s linear;
    }
    .sidebar-collapse
        .navbar.bg-white:not(.navbar-transparent)
        .navbar-toggler-bar {
        background: #888888;
    }
    .sidebar-collapse .top-bar {
        transform: rotate(0);
    }
    .sidebar-collapse .middle-bar {
        opacity: 1;
    }
    .sidebar-collapse .bottom-bar {
        transform: rotate(0);
    }
    .sidebar-collapse .top-bar,
    .sidebar-collapse .middle-bar,
    .sidebar-collapse .bottom-bar {
        transition: all 0.2s;
    }
    .sidebar-collapse .toggled .top-bar {
        transform: rotate(45deg);
        transform-origin: 0%;
    }
    .sidebar-collapse .toggled .bottom-bar {
        transform: rotate(-45deg);
        transform-origin: 0%;
    }
    .sidebar-collapse .toggled .middle-bar {
        opacity: 0;
    }
    .sidebar-collapse [class*="navbar-expand-"] .navbar-collapse {
        width: 300px;
    }
    .sidebar-collapse .wrapper {
        -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -moz-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -ms-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
    .sidebar-collapse #bodyClick {
        height: 100%;
        width: 100%;
        position: fixed;
        opacity: 1;
        top: 0;
        left: auto;
        right: 300px;
        content: "";
        z-index: 9999;
        overflow-x: hidden;
        background-color: transparent;
        -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -moz-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -ms-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
    .sidebar-collapse.menu-on-left .navbar-collapse {
        right: auto;
        left: 0;
        -webkit-transform: translate3d(-300px, 0, 0);
        -moz-transform: translate3d(-300px, 0, 0);
        -o-transform: translate3d(-300px, 0, 0);
        -ms-transform: translate3d(-300px, 0, 0);
        transform: translate3d(-300px, 0, 0);
    }
    .nav-open .sidebar-collapse .navbar-collapse {
        -webkit-transform: translate3d(0px, 0, 0);
        -moz-transform: translate3d(0px, 0, 0);
        -o-transform: translate3d(0px, 0, 0);
        -ms-transform: translate3d(0px, 0, 0);
        transform: translate3d(0px, 0, 0);
    }
    .nav-open .sidebar-collapse .wrapper {
        -webkit-transform: translate3d(-150px, 0, 0);
        -moz-transform: translate3d(-150px, 0, 0);
        -o-transform: translate3d(-150px, 0, 0);
        -ms-transform: translate3d(-150px, 0, 0);
        transform: translate3d(-150px, 0, 0);
    }
    .nav-open .sidebar-collapse .navbar-translate {
        -webkit-transform: translate3d(-300px, 0, 0);
        -moz-transform: translate3d(-300px, 0, 0);
        -o-transform: translate3d(-300px, 0, 0);
        -ms-transform: translate3d(-300px, 0, 0);
        transform: translate3d(-300px, 0, 0);
    }
    .nav-open .sidebar-collapse.menu-on-left .navbar-collapse {
        -webkit-transform: translate3d(0px, 0, 0);
        -moz-transform: translate3d(0px, 0, 0);
        -o-transform: translate3d(0px, 0, 0);
        -ms-transform: translate3d(0px, 0, 0);
        transform: translate3d(0px, 0, 0);
    }
    .nav-open .sidebar-collapse.menu-on-left .navbar-translate {
        -webkit-transform: translate3d(300px, 0, 0);
        -moz-transform: translate3d(300px, 0, 0);
        -o-transform: translate3d(300px, 0, 0);
        -ms-transform: translate3d(300px, 0, 0);
        transform: translate3d(300px, 0, 0);
    }
    .nav-open .sidebar-collapse.menu-on-left .wrapper {
        -webkit-transform: translate3d(150px, 0, 0);
        -moz-transform: translate3d(150px, 0, 0);
        -o-transform: translate3d(150px, 0, 0);
        -ms-transform: translate3d(150px, 0, 0);
        transform: translate3d(150px, 0, 0);
    }
    .nav-open .sidebar-collapse.menu-on-left #bodyClick {
        right: auto;
        left: 300px;
    }
    .navbar-nav .nav-link i.fab,
    .navbar-nav .nav-link i.now-ui-icons {
        opacity: 0.5;
    }
    .bootstrap-collapse .navbar .navbar-collapse {
        background: none !important;
    }
    .bootstrap-collapse.menu-on-left .navbar-translate {
        flex-direction: row-reverse;
    }
    .bootstrap-collapse .navbar .navbar-nav {
        margin-top: 53px;
        position: relative;
        max-height: calc(100vh - 75px);
        min-height: 100%;
        overflow: auto;
    }
    .bootstrap-collapse .navbar .navbar-nav.navbar-logo {
        top: 0;
        height: 53px;
    }
    .bootstrap-collapse .navbar .dropdown.show .dropdown-menu,
    .bootstrap-collapse .navbar .dropdown .dropdown-menu {
        background-color: transparent;
        border: 0;
        transition: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        width: auto;
        margin: 0 1rem;
        margin-bottom: 15px;
        padding-top: 0;
        height: 125px;
        overflow-y: scroll;
    }
    .bootstrap-collapse .navbar .dropdown.show .dropdown-menu:before,
    .bootstrap-collapse .navbar .dropdown .dropdown-menu:before {
        display: none;
    }
    .bootstrap-collapse .navbar .dropdown .dropdown-item {
        padding-left: 2.5rem;
    }
    .bootstrap-collapse .navbar .dropdown .dropdown-menu {
        display: none;
    }
    .bootstrap-collapse .navbar .dropdown.show .dropdown-menu {
        display: block;
    }
    .bootstrap-collapse .navbar .dropdown-menu .dropdown-item:focus,
    .bootstrap-collapse .navbar .dropdown-menu .dropdown-item:hover {
        color: #ffffff;
        border-radius: 0.1875rem;
    }
    .bootstrap-collapse .navbar .navbar-translate {
        width: 100%;
        position: relative;
        display: flex;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
        -ms-flex-align: center;
        align-items: center;
        -webkit-transform: translate3d(0px, 0, 0);
        -moz-transform: translate3d(0px, 0, 0);
        -o-transform: translate3d(0px, 0, 0);
        -ms-transform: translate3d(0px, 0, 0);
        transform: translate3d(0px, 0, 0);
        -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -moz-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -ms-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
    .bootstrap-collapse .navbar .navbar-toggler-bar {
        display: block;
        position: relative;
        width: 22px;
        height: 1px;
        border-radius: 1px;
        background: #ffffff;
    }
    .bootstrap-collapse .navbar .navbar-toggler-bar + .navbar-toggler-bar {
        margin-top: 7px;
    }
    .bootstrap-collapse .navbar .navbar-toggler-bar.bar2 {
        width: 17px;
        transition: width 0.2s linear;
    }
    .bootstrap-collapse
        .navbar.bg-white:not(.navbar-transparent)
        .navbar-toggler-bar {
        background: #888888;
    }
    .bootstrap-collapse .top-bar {
        transform: rotate(0);
    }
    .bootstrap-collapse .middle-bar {
        opacity: 1;
    }
    .bootstrap-collapse .bottom-bar {
        transform: rotate(0);
    }
    .bootstrap-collapse .top-bar,
    .bootstrap-collapse .middle-bar,
    .bootstrap-collapse .bottom-bar {
        transition: all 0.2s;
    }
    .bootstrap-collapse .toggled .top-bar {
        transform: rotate(45deg);
        transform-origin: 0%;
    }
    .bootstrap-collapse .toggled .bottom-bar {
        transform: rotate(-45deg);
        transform-origin: 0%;
    }
    .bootstrap-collapse .toggled .middle-bar {
        opacity: 0;
    }
    .profile-photo .profile-photo-small {
        margin-left: -2px;
    }
    .button-dropdown {
        display: none;
    }
    .section-nucleo-icons .container .row > [class*="col-"]:first-child {
        text-align: center;
    }
    .footer .copyright {
        text-align: right;
    }
    .section-nucleo-icons .icons-container {
        margin-top: 65px;
    }
    .navbar-nav .nav-link i.fa,
    .navbar-nav .nav-link i.now-ui-icons {
        opacity: 0.5;
    }
    .section-images {
        height: 500px;
        max-height: 500px;
    }
    .section-images .hero-images-container {
        max-width: 500px;
    }
    .section-images .hero-images-container-1 {
        right: 10%;
        top: 68%;
        max-width: 269px;
    }
    .section-images .hero-images-container-2 {
        right: 5%;
        max-width: 135px;
        top: 93%;
    }
}

@media screen and (min-width: 992px) {
    .navbar-collapse:not(.has-image) {
        background: transparent !important;
    }
    .burger-menu .navbar-collapse {
        position: fixed;
        display: block !important;
        top: 0;
        height: 100% !important;
        width: 300px;
        right: 0;
        z-index: 1032;
        visibility: visible;
        background-color: #999;
        overflow-y: visible;
        border-top: none;
        text-align: left;
        max-height: none !important;
        -webkit-transform: translate3d(300px, 0, 0);
        -moz-transform: translate3d(300px, 0, 0);
        -o-transform: translate3d(300px, 0, 0);
        -ms-transform: translate3d(300px, 0, 0);
        transform: translate3d(300px, 0, 0);
        -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -moz-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -ms-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
    .burger-menu .navbar-collapse:before {
        background: #f96332;
        /* For browsers that do not support gradients */
        background: -webkit-linear-gradient(#f96332 0%, #000 80%);
        /* For Safari 5.1 to 6.0 */
        background: -o-linear-gradient(#f96332 0%, #000 80%);
        /* For Opera 11.1 to 12.0 */
        background: -moz-linear-gradient(#f96332 0%, #000 80%);
        /* For Firefox 3.6 to 15 */
        background: linear-gradient(#f96332 0%, #000 80%);
        /* Standard syntax (must be last) */
        opacity: 0.76;
        filter: alpha(opacity=76);
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
    }
    .burger-menu .navbar-collapse .navbar-nav:not(.navbar-logo) .nav-link {
        margin: 0 1rem;
        margin-top: 0.3125rem;
    }
    .burger-menu
        .navbar-collapse
        .navbar-nav:not(.navbar-logo)
        .nav-link:not(.btn) {
        color: #ffffff !important;
    }
    .burger-menu .navbar-collapse .dropdown-menu .dropdown-item {
        color: #ffffff;
    }
    .burger-menu.menu-on-left .navbar-translate {
        flex-direction: row-reverse;
    }
    .burger-menu .navbar .navbar-nav {
        margin-top: 53px;
        position: relative;
        max-height: calc(100vh - 75px);
        min-height: 100%;
        overflow: auto;
    }
    .burger-menu .navbar .navbar-nav.navbar-logo {
        top: 0;
        height: 53px;
    }
    .burger-menu .navbar .dropdown.show .dropdown-menu,
    .burger-menu .navbar .dropdown .dropdown-menu {
        background-color: transparent;
        border: 0;
        transition: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        width: auto;
        margin: 0 1rem;
        margin-bottom: 15px;
        padding-top: 0;
        height: 125px;
        overflow-y: scroll;
    }
    .burger-menu .navbar .dropdown.show .dropdown-menu:before,
    .burger-menu .navbar .dropdown .dropdown-menu:before {
        display: none;
    }
    .burger-menu .navbar .dropdown .dropdown-item {
        padding-left: 2.5rem;
    }
    .burger-menu .navbar .dropdown .dropdown-menu {
        display: none;
    }
    .burger-menu .navbar .dropdown.show .dropdown-menu {
        display: block;
    }
    .burger-menu .navbar .dropdown-menu .dropdown-item:focus,
    .burger-menu .navbar .dropdown-menu .dropdown-item:hover {
        color: #ffffff;
        border-radius: 0.1875rem;
    }
    .burger-menu .navbar .navbar-translate {
        width: 100%;
        position: relative;
        display: flex;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
        -ms-flex-align: center;
        align-items: center;
        -webkit-transform: translate3d(0px, 0, 0);
        -moz-transform: translate3d(0px, 0, 0);
        -o-transform: translate3d(0px, 0, 0);
        -ms-transform: translate3d(0px, 0, 0);
        transform: translate3d(0px, 0, 0);
        -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -moz-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -ms-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
    .burger-menu .navbar .navbar-toggler-bar {
        display: block;
        position: relative;
        width: 22px;
        height: 1px;
        border-radius: 1px;
        background: #ffffff;
    }
    .burger-menu .navbar .navbar-toggler-bar + .navbar-toggler-bar {
        margin-top: 7px;
    }
    .burger-menu .navbar .navbar-toggler-bar.bar2 {
        width: 17px;
        transition: width 0.2s linear;
    }
    .burger-menu .navbar.bg-white:not(.navbar-transparent) .navbar-toggler-bar {
        background: #888888;
    }
    .burger-menu .top-bar {
        transform: rotate(0);
    }
    .burger-menu .middle-bar {
        opacity: 1;
    }
    .burger-menu .bottom-bar {
        transform: rotate(0);
    }
    .burger-menu .top-bar,
    .burger-menu .middle-bar,
    .burger-menu .bottom-bar {
        transition: all 0.2s;
    }
    .burger-menu .toggled .top-bar {
        transform: rotate(45deg);
        transform-origin: 0%;
    }
    .burger-menu .toggled .bottom-bar {
        transform: rotate(-45deg);
        transform-origin: 0%;
    }
    .burger-menu .toggled .middle-bar {
        opacity: 0;
    }
    .burger-menu [class*="navbar-expand-"] .navbar-collapse {
        width: 300px;
    }
    .burger-menu .wrapper {
        -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -moz-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -ms-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
    .burger-menu #bodyClick {
        height: 100%;
        width: 100%;
        position: fixed;
        opacity: 1;
        top: 0;
        left: auto;
        right: 300px;
        content: "";
        z-index: 9999;
        overflow-x: hidden;
        background-color: transparent;
        -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -moz-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -ms-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
    .nav-open .burger-menu .navbar-collapse {
        -webkit-transform: translate3d(0px, 0, 0);
        -moz-transform: translate3d(0px, 0, 0);
        -o-transform: translate3d(0px, 0, 0);
        -ms-transform: translate3d(0px, 0, 0);
        transform: translate3d(0px, 0, 0);
    }
    .burger-menu .navbar-collapse {
        display: block !important;
    }
    .burger-menu .navbar-collapse .navbar-nav {
        margin-top: 53px;
        height: 100%;
        z-index: 2;
        position: relative;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .burger-menu .navbar-collapse .navbar-nav .nav-item {
        margin: 0;
    }
    .burger-menu.menu-on-left .navbar-collapse {
        right: auto;
        left: 0;
        -webkit-transform: translate3d(-300px, 0, 0);
        -moz-transform: translate3d(-300px, 0, 0);
        -o-transform: translate3d(-300px, 0, 0);
        -ms-transform: translate3d(-300px, 0, 0);
        transform: translate3d(-300px, 0, 0);
    }
    .burger-menu [class*="navbar-expand-"] .navbar-nav .dropdown-menu {
        position: static;
        float: none;
    }
    .burger-menu [class*="navbar-expand-"] .navbar-toggler {
        display: inline-block;
    }
    .burger-menu .section-navbars .navbar-collapse {
        display: none !important;
    }
    .nav-open .burger-menu.menu-on-left .navbar .navbar-translate {
        -webkit-transform: translate3d(300px, 0, 0);
        -moz-transform: translate3d(300px, 0, 0);
        -o-transform: translate3d(300px, 0, 0);
        -ms-transform: translate3d(300px, 0, 0);
        transform: translate3d(300px, 0, 0);
    }
    .nav-open .burger-menu .navbar .navbar-translate {
        -webkit-transform: translate3d(-300px, 0, 0);
        -moz-transform: translate3d(-300px, 0, 0);
        -o-transform: translate3d(-300px, 0, 0);
        -ms-transform: translate3d(-300px, 0, 0);
        transform: translate3d(-300px, 0, 0);
    }
    .nav-open .burger-menu.menu-on-left .navbar-collapse {
        -webkit-transform: translate3d(0px, 0, 0);
        -moz-transform: translate3d(0px, 0, 0);
        -o-transform: translate3d(0px, 0, 0);
        -ms-transform: translate3d(0px, 0, 0);
        transform: translate3d(0px, 0, 0);
    }
    .nav-open .burger-menu.menu-on-left #bodyClick {
        right: auto;
        left: 300px;
    }
    .burger-menu.menu-on-left .navbar-brand {
        float: right;
        margin-right: 0;
        margin-left: 1rem;
    }
    .navbar-nav .nav-link.profile-photo {
        padding: 0;
        margin: 7px 0.7rem;
    }
    .navbar-nav .nav-link.btn:not(.btn-sm) {
        margin: 0;
    }
    .navbar-nav .nav-item:not(:last-child) {
        margin-right: 5px;
    }
    .section-nucleo-icons .icons-container {
        margin: 0 0 0 auto;
    }
    .dropdown-menu .dropdown-item {
        color: inherit;
        display: flex;
        align-items: center;
    }
    .dropdown-menu .dropdown-item i {
        margin-right: 5px;
        position: relative;
        top: 1px;
    }
    .footer .copyright {
        float: right;
        top: 10px;
    }
}

@media screen and (min-width: 768px) {
    .image-container.image-right {
        top: 80px;
        margin-left: -100px;
        margin-bottom: 130px;
    }
    .image-container.image-left {
        margin-right: -100px;
    }
}

@media screen and (max-width: 768px) {
    .image-container.image-left {
        margin-bottom: 220px;
    }
    .image-container.image-left p.blockquote {
        margin: 0 auto;
        position: relative;
        right: 0;
    }
    .nav-tabs {
        display: inline-block;
        width: 100%;
        padding-left: 100px;
        padding-right: 100px;
        text-align: center;
    }
    .nav-tabs .nav-item > .nav-link {
        margin-bottom: 5px;
    }
    .landing-page .section-story-overview .image-container:nth-child(2) {
        margin-left: 0;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 576px) {
    .navbar[class*="navbar-expand-"] .container {
        margin-left: 0;
        margin-right: 0;
    }
    .footer .copyright {
        text-align: center;
    }
    .section-nucleo-icons .icons-container i {
        font-size: 30px;
    }
    .section-nucleo-icons .icons-container i:nth-child(6) {
        font-size: 48px;
    }
    .page-header .container h6.category-absolute {
        width: 90%;
    }
}

@media screen and (min-width: 991px) and (max-width: 1200px) {
    .section-images .hero-images-container-1 {
        right: 9%;
        max-width: 370px;
    }
    .section-images .hero-images-container-2 {
        right: 2%;
        max-width: 216px;
    }
}

@media screen and (max-width: 768px) {
    .section-images {
        height: 300px;
        max-height: 300px;
    }
    .section-images .hero-images-container {
        max-width: 380px;
    }
    .section-images .hero-images-container-1 {
        right: 7%;
        top: 87%;
        max-width: 210px;
    }
    .section-images .hero-images-container-2 {
        right: 1%;
        max-width: 133px;
        top: 99%;
    }
}

@media screen and (max-width: 517px) {
    .alert .alert-icon {
        margin-top: 10px;
    }
}

@media screen and (min-width: 1200px) {
    .section-images .hero-images-container-1 {
        top: 51%;
        right: 21%;
    }
    .section-images .hero-images-container-2 {
        top: 66%;
        right: 14%;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .section-images .hero-images-container,
    .section-images .hero-images-container-1,
    .section-images .hero-images-container-2 {
        margin-top: -15vh;
        margin-left: 80px;
    }
    .section-images .hero-images-container {
        max-width: 300px;
    }
    .section-images .hero-images-container-1 {
        right: 28%;
        top: 40%;
    }
    .section-images .hero-images-container-2 {
        right: 21%;
        top: 55%;
    }
    .index-page .category-absolute {
        top: 90vh;
    }
}

@media screen and (max-width: 580px) {
    .alert button.close {
        position: absolute;
        right: 11px;
        top: 50%;
        transform: translateY(-50%);
    }
}

@media only screen and (min-height: 950px) {
    .index-page .page-header .category-absolute {
        margin-top: -330px;
    }
}

/*! nouislider - 14.0.2 - 6/28/2019 */
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
/*

This file was modified by Creative-Tim

*/
.noUi-target,
.noUi-target * {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    -ms-touch-action: none;
    touch-action: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.noUi-target {
    position: relative;
    direction: ltr;
}

.noUi-base,
.noUi-connects {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
}

/* Wrapper for all connect elements.
 */
.noUi-connects {
    overflow: hidden;
    z-index: 0;
}

.noUi-connect,
.noUi-origin {
    will-change: transform;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    -ms-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    -webkit-transform-style: preserve-3d;
    transform-origin: 0 0;
    transform-style: flat;
}

.noUi-connect {
    height: 100%;
    width: 100%;
}

.noUi-origin {
    height: 10%;
    width: 10%;
}

/* Offset direction
 */
html:not([dir="rtl"]) .noUi-horizontal .noUi-origin {
    left: auto;
    right: 0;
}

/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
    width: 0;
}

.noUi-horizontal .noUi-origin {
    height: 0;
}

.noUi-handle {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
}

.noUi-touch-area {
    height: 100%;
    width: 100%;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
    -webkit-transition: transform 0.3s;
    transition: transform 0.3s;
}

.noUi-state-drag * {
    cursor: inherit !important;
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
    height: 1px;
}

.noUi-horizontal .noUi-handle {
    width: 15px;
    height: 15px;
    left: -7px;
    top: 0px;
}

.noUi-vertical {
    width: 18px;
}

.noUi-vertical .noUi-handle {
    width: 15px;
    height: 15px;
    left: 0px;
    top: -7px;
}

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
    right: -7px;
    left: auto;
}

/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
    background-color: rgba(182, 182, 182, 0.3);
    border-radius: 3px;
}

.noUi-connects {
    border-radius: 3px;
}

/* Handles and cursors;
 */
.noUi-draggable {
    cursor: ew-resize;
}

.noUi-vertical .noUi-draggable {
    cursor: ns-resize;
}

.noUi-handle {
    border-radius: 3px;
    background: #fff;
    cursor: default;
    box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;
}

.noUi-active {
    box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ddd, 0 3px 6px -3px #bbb;
}

/* Disabled state;
 */
[disabled] .noUi-connect {
    background: #b8b8b8;
}

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
    cursor: not-allowed;
}

/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.noUi-pips {
    position: absolute;
    color: #999;
}

/* Values;
 *
 */
.noUi-value {
    position: absolute;
    white-space: nowrap;
    text-align: center;
}

.noUi-value-sub {
    color: #ccc;
    font-size: 10px;
}

/* Markings;
 *
 */
.noUi-marker {
    position: absolute;
    background: #ccc;
}

.noUi-marker-sub {
    background: #aaa;
}

.noUi-marker-large {
    background: #aaa;
}

/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
    padding: 10px 0;
    height: 80px;
    top: 100%;
    left: 0;
    width: 100%;
}

.noUi-value-horizontal {
    -webkit-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
}

.noUi-rtl .noUi-value-horizontal {
    -webkit-transform: translate(50%, 50%);
    transform: translate(50%, 50%);
}

.noUi-marker-horizontal.noUi-marker {
    margin-left: -1px;
    width: 2px;
    height: 5px;
}

.noUi-marker-horizontal.noUi-marker-sub {
    height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
    height: 15px;
}

/* Vertical layout;
 *
 */
.noUi-pips-vertical {
    padding: 0 10px;
    height: 100%;
    top: 0;
    left: 100%;
}

.noUi-value-vertical {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding-left: 25px;
}

.noUi-rtl .noUi-value-vertical {
    -webkit-transform: translate(0, 50%);
    transform: translate(0, 50%);
}

.noUi-marker-vertical.noUi-marker {
    width: 5px;
    height: 2px;
    margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
    width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
    width: 15px;
}

.noUi-tooltip {
    display: block;
    position: absolute;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    background: #fff;
    color: #000;
    padding: 5px;
    text-align: center;
    white-space: nowrap;
}

.noUi-horizontal .noUi-tooltip {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    left: 50%;
    bottom: 120%;
}

.noUi-vertical .noUi-tooltip {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    top: 50%;
    right: 120%;
}

/*!
* https://github.com/YouCanBookMe/react-datetime
*/
.rdt {
    position: relative;
}
.rdt .rdtPicker {
    -webkit-transition: all 150ms linear;
    -moz-transition: all 150ms linear;
    -o-transition: all 150ms linear;
    -ms-transition: all 150ms linear;
    transition: all 150ms linear;
    margin-top: -20px;
    visibility: hidden;
    display: block;
    opacity: 0;
}
.rdt.rdtOpen .rdtPicker {
    opacity: 1;
    visibility: visible;
    margin-top: 0;
}

.rdtPicker {
    display: none;
    position: absolute;
    width: 260px;
    padding: 4px;
    margin-top: 1px;
    z-index: 99999 !important;
    background: #fff;
    border-radius: 0.125rem;
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    min-width: 160px;
}
.rdtPicker:before {
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    vertical-align: middle;
    content: "";
    top: -5px;
    left: 10px;
    right: auto;
    color: #ffffff;
    border-bottom: 0.4em solid;
    border-right: 0.4em solid transparent;
    border-left: 0.4em solid transparent;
}
.rdtPicker:after {
    border-bottom: 0.4em solid #ffffff;
    border-right: 0.4em solid transparent;
    border-left: 0.4em solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
    top: -5px;
    left: 10px;
}

.rdtPicker {
    display: block;
    top: 40px;
}

.rdtStatic .rdtPicker {
    box-shadow: none;
    position: static;
}

.rdtPicker .rdtTimeToggle {
    text-align: center;
    padding: 5px;
    border-radius: 4px;
}

.rdtPicker table {
    width: 100%;
    margin: 0;
    border-color: #ffffff !important;
}

.rdtPicker td,
.rdtPicker th {
    text-align: center;
    padding: 1px;
}

.rdtPicker td {
    cursor: pointer;
}

.rdtDay {
    height: 30px;
    line-height: 33px;
    width: 30px;
    text-align: center;
    padding: 0px;
    border-radius: 50%;
}
.rdtDay.rdtToday.rdtActive,
.rdtDay.rdtActive,
.rdtDay.rdtActive:hover {
    background-color: #f96332 !important;
    color: #ffffff;
}

.rdtDays tr .dow {
    text-align: center;
    border-bottom: 1px solid #e3e3e3;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: inherit;
    padding-bottom: 5px;
    padding-top: 10px;
}

.rdtDays tr .rdtOld,
.rdtDays tr .rdtNew {
    color: #dddddd;
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
    background: #eeeeee;
    cursor: pointer;
}

.rdtPicker td.rdtToday {
    position: relative;
}

.rdtPicker td.rdtActive.rdtToday:before {
    border-bottom-color: #fff;
}

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
    color: #999999;
}

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
}

.rdtPicker .dow {
    width: 14.2857%;
    border-bottom: none;
}

.rdtPicker th.rdtSwitch {
    width: 50px;
    padding: 5px;
    border-radius: 4px;
}

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
    font-size: 21px;
    vertical-align: top;
    border-radius: 50%;
    line-height: 33px;
}

.rdtPicker .dow,
.rdtPicker th.rdtSwitch,
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev,
.rdtPicker .rdtTimeToggle {
    color: #f96332;
}

.rdtPrev span,
.rdtNext span {
    display: block;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Chrome/Safari/Opera */
    -khtml-user-select: none;
    /* Konqueror */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
}

.rdtPicker thead tr:first-child th {
    cursor: pointer;
}

.rdtPicker thead tr:first-child th:hover {
    background: #eeeeee;
}

.rdtPicker button {
    border: none;
    background: none;
    cursor: pointer;
}

.rdtPicker button:hover {
    background-color: #eee;
}

.rdtPicker thead button {
    width: 100%;
    height: 100%;
}

td.rdtMonth,
td.rdtYear {
    height: 50px;
    width: 25%;
    cursor: pointer;
}

td.rdtMonth:hover,
td.rdtYear:hover {
    background: #eee;
}

.rdtCounters {
    display: inline-block;
}

.rdtCounters > div {
    float: left;
    width: 40px;
    font-weight: inherit;
    margin: 3px;
    border-radius: 50%;
}

.rdtCounters .rdtCounterSeparator {
    width: 0;
    border: 1px solid transparent;
}

.rdtCounter {
    height: 100px;
}

.rdtCounter {
    width: 40px;
}
.rdtCounter .rdtCount {
    padding: 7px;
    height: 40px;
    border: 1px solid transparent;
}

.rdtCounters .rdtCounter:last-child .rdtCount {
    color: #f96332;
    border-radius: 50%;
    border: 1px solid #f96332;
}

.rdtCounterSeparator {
    padding: 7px;
    line-height: 100px;
}

.rdtCounter .rdtBtn {
    line-height: 40px;
    cursor: pointer;
    display: block;
    border-radius: 50%;
    color: #dddddd;
    -webkit-transition: all 60ms ease-in;
    -moz-transition: all 60ms ease-in;
    -o-transition: all 60ms ease-in;
    -ms-transition: all 60ms ease-in;
    transition: all 60ms ease-in;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Chrome/Safari/Opera */
    -khtml-user-select: none;
    /* Konqueror */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
}

.rdtCounter .rdtBtn:hover {
    background: #eee;
    color: #797979;
}

.rdtCounter .rdtCount {
    font-size: inherit;
    line-height: 25px;
}

.rdtMilli {
    vertical-align: middle;
    padding-left: 8px;
    width: 48px;
}

.rdtMilli input {
    width: 100%;
    font-size: inherit;
    margin-top: 37px;
}

.rdtMonths,
.rdtYears {
    padding-bottom: 10px;
}
.rdtMonths .rdtMonth,
.rdtMonths .rdtYear,
.rdtYears .rdtMonth,
.rdtYears .rdtYear {
    display: inline-block;
    width: 56px;
    height: 56px;
    line-height: 56px;
    margin: 3px 3px;
    cursor: pointer;
    border-radius: 50%;
    text-align: center;
}
.rdtMonths .rdtMonth.rdtActive,
.rdtMonths .rdtYear.rdtActive,
.rdtYears .rdtMonth.rdtActive,
.rdtYears .rdtYear.rdtActive {
    background-color: #f96332 !important;
    color: #ffffff;
}

*:focus {
    outline: none;
}

.blockquote.blockquote-info {
    border-color: #2ca8ff;
    color: #2ca8ff;
}
.blockquote.blockquote-info small {
    color: #2ca8ff;
}

.dropdown-menu a.dropdown-header[tabindex="-1"] {
    color: rgba(181, 181, 181, 0.6);
    font-size: 0.7142em;
    text-transform: uppercase;
    font-weight: 700;
}

.dropdown-menu.dropdown-menu-right {
    right: 0 !important;
    left: auto !important;
}

.modal.modal-mini .modal-profile i {
    color: #2ca8ff;
    font-size: 21px;
}

.popover-primary .popover {
    background-color: #f96332;
}
.popover-primary .popover .popover-body {
    color: #ffffff;
}
.popover-primary .popover.bs-popover-right .arrow:after {
    border-right-color: #f96332;
}
.popover-primary .popover.bs-popover-top .arrow:after {
    border-top-color: #f96332;
}
.popover-primary .popover.bs-popover-bottom .arrow:after {
    border-bottom-color: #f96332;
}
.popover-primary .popover.bs-popover-left .arrow:after {
    border-left-color: #f96332;
}
.popover-primary .popover .popover-header {
    color: #ffffff;
    opacity: 0.6;
}

.popover-info .popover {
    background-color: #2ca8ff;
}
.popover-info .popover .popover-body {
    color: #ffffff;
}
.popover-info .popover.bs-popover-right .arrow:after {
    border-right-color: #2ca8ff;
}
.popover-info .popover.bs-popover-top .arrow:after {
    border-top-color: #2ca8ff;
}
.popover-info .popover.bs-popover-bottom .arrow:after {
    border-bottom-color: #2ca8ff;
}
.popover-info .popover.bs-popover-left .arrow:after {
    border-left-color: #2ca8ff;
}
.popover-info .popover .popover-header {
    color: #ffffff;
    opacity: 0.6;
}

.popover-warning .popover {
    background-color: #ffb236;
}
.popover-warning .popover .popover-body {
    color: #ffffff;
}
.popover-warning .popover.bs-popover-right .arrow:after {
    border-right-color: #ffb236;
}
.popover-warning .popover.bs-popover-top .arrow:after {
    border-top-color: #ffb236;
}
.popover-warning .popover.bs-popover-bottom .arrow:after {
    border-bottom-color: #ffb236;
}
.popover-warning .popover.bs-popover-left .arrow:after {
    border-left-color: #ffb236;
}
.popover-warning .popover .popover-header {
    color: #ffffff;
    opacity: 0.6;
}

.popover-danger .popover {
    background-color: #ff3636;
}
.popover-danger .popover .popover-body {
    color: #ffffff;
}
.popover-danger .popover.bs-popover-right .arrow:after {
    border-right-color: #ff3636;
}
.popover-danger .popover.bs-popover-top .arrow:after {
    border-top-color: #ff3636;
}
.popover-danger .popover.bs-popover-bottom .arrow:after {
    border-bottom-color: #ff3636;
}
.popover-danger .popover.bs-popover-left .arrow:after {
    border-left-color: #ff3636;
}
.popover-danger .popover .popover-header {
    color: #ffffff;
    opacity: 0.6;
}

.popover-success .popover {
    background-color: #18ce0f;
}
.popover-success .popover .popover-body {
    color: #ffffff;
}
.popover-success .popover.bs-popover-right .arrow:after {
    border-right-color: #18ce0f;
}
.popover-success .popover.bs-popover-top .arrow:after {
    border-top-color: #18ce0f;
}
.popover-success .popover.bs-popover-bottom .arrow:after {
    border-bottom-color: #18ce0f;
}
.popover-success .popover.bs-popover-left .arrow:after {
    border-left-color: #18ce0f;
}
.popover-success .popover .popover-header {
    color: #ffffff;
    opacity: 0.6;
}

.clear-filter[filter-color="blue"] {
    background: rgba(44, 44, 44, 0.2);
    /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(
        90deg,
        rgba(44, 44, 44, 0.2),
        rgba(3, 161, 224, 0.6)
    );
    /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(
        90deg,
        rgba(44, 44, 44, 0.2),
        rgba(3, 161, 224, 0.6)
    );
    /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(
        90deg,
        rgba(44, 44, 44, 0.2),
        rgba(3, 161, 224, 0.6)
    );
    /* For Firefox 3.6 to 15 */
    background: linear-gradient(
        0deg,
        rgba(44, 44, 44, 0.2),
        rgba(3, 161, 224, 0.6)
    );
    /* Standard syntax */
}

.clear-filter[filter-color="grey"] {
    background: rgba(44, 44, 44, 0.2);
    /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(
        90deg,
        rgba(44, 44, 44, 0.2),
        rgba(0, 0, 0, 0.8)
    );
    /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(
        90deg,
        rgba(44, 44, 44, 0.2),
        rgba(0, 0, 0, 0.8)
    );
    /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(
        90deg,
        rgba(44, 44, 44, 0.2),
        rgba(0, 0, 0, 0.8)
    );
    /* For Firefox 3.6 to 15 */
    background: linear-gradient(
        0deg,
        rgba(44, 44, 44, 0.2),
        rgba(0, 0, 0, 0.8)
    );
    /* Standard syntax */
}

[data-background-color="blue"] {
    background-color: #2ca8ff;
}

.section-nucleo-icons .icons-container i:nth-child(6) {
    color: #2ca8ff;
}

.page-header.page-header-small {
    min-height: 60vh;
    max-height: 640px;
}

@media screen and (max-width: 991px) {
    .sidebar-collapse .navbar-collapse:before {
        background: #000;
        background: linear-gradient(#000, #2c2c2c 100%);
    }
}

@media screen and (max-width: 768px) {
    .nav-tabs {
        padding-left: 10px;
        padding-right: 10px;
    }
}
