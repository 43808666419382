.admin-question-blur {
    filter: blur(5px);
    pointer-events: none;
}

.admin-question-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}
.admin-question-b {
    position: relative;
    line-height: 150%;
    width: 6rem;
}
.admin-question-password {
    visibility: hidden;
}
.admin-question-frame-child {
    border: 1px solid #e4e4e4;
    background-color: #fff;
    border-radius: 12px;
    box-sizing: border-box;
    width: 20rem;
    height: 4rem;
    align-items: center;
    justify-content: flex-start;
    padding: 0.81rem 1rem;
    resize: none;
}
.admin-question-frame-select {
    width: 20rem;
    margin-bottom: 1rem;
    font-family: "Nucleo Outline", sans-serif;
}
.admin-question-parent {
    align-self: stretch;
    align-items: flex-start;
    gap: 1.25rem;
}

.admin-question-frame-item,
.admin-question-inputsection,
.admin-question-parent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.admin-question-frame-item {
    border: 1px solid #e4e4e4;
    background-color: #fff;
    flex: 1;
    border-radius: 12px;
    box-sizing: border-box;
    height: 9.38rem;
    width: 20rem;
    align-items: center;
    padding: 0.81rem 1rem;
    resize: none;
}

.admin-question-input-section {
    align-items: flex-start;
    gap: 1.25rem;
}
.admin-question-input-section {
    top: 0;
    left: 19.69rem;
    flex-direction: column;
    gap: 1rem;
}
.admin-question-save-button-text {
    position: relative;
    font-size: 1rem;
    line-height: 1.13rem;
    font-family: "Nucleo Outline";
    color: #fff;
    text-align: center;
}
.admin-question-save-button {
    cursor: pointer;
    border: 0;
    padding: 1rem 2.5rem;
    background-color: #000;
    top: 24.44rem;
    left: 26.94rem;
    border-radius: 12px;
    width: 27.88rem;
    height: 3.13rem;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    gap: 0.5rem;
}
.admin-reply-button-div {
    display: flex;
    justify-content: center;
}
.admin-reply-save-button {
    cursor: pointer;
    border: 0;
    padding: 1rem 2.5rem;
    width: 27rem;
    background-color: #000;
    border-radius: 12px;
    margin-bottom: 30px;
}

.admin-question-create-form {
    position: relative;
    height: 21.56rem;
    text-align: left;
    font-size: 1rem;
    color: #000;
    font-family: "Nucleo Outline";
}
.admin-question-createFormBody {
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
