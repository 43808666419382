.consulting-section {
    padding: 10%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* 가로로 왼쪽 정렬 */
    align-items: center; /* 세로로 가운데 정렬 */
    gap: 20px; /* 간격 조절, 원하는 값으로 변경하세요 */
    background-color: rgb(43, 42, 42);
    /* 다른 스타일 속성들 */
}
