.board-tr {
    cursor: pointer;
}

.board-table {
    text-align: center;
}

.question-button {
    float: right;
    border-radius: 4px;
}

.question-div {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.question-detail-b {
    position: relative;
    line-height: 150%;
    width: 6rem;
}
.question-detail-password {
    visibility: hidden;
}
.question-detail-frame-child {
    border: 1px solid #e4e4e4;
    background-color: #fff;
    border-radius: 12px;
    box-sizing: border-box;
    width: 20rem;
    height: 4rem;
    align-items: center;
    justify-content: flex-start;
    padding: 0.81rem 1rem;
    resize: none;
}
.question-detail-frame-childresponsive {
    border: 1px solid #e4e4e4;
    background-color: #fff;
    border-radius: 12px;
    box-sizing: border-box;
    height: 4rem;
    align-items: center;
    justify-content: flex-start;
    padding: 0.81rem 1rem;
    resize: none;
}
.question-detail-frame-select {
    width: 20rem;
    margin-bottom: 1rem;
    font-family: "Nucleo Outline", sans-serif;
}
.question-detail-parent {
    align-self: stretch;
    align-items: flex-start;
    gap: 1.25rem;
}

.question-detail-frame-item,
.question-detail-inputsection,
.question-detail-parent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.question-detail-frame-item {
    border: 1px solid #e4e4e4;
    background-color: #fff;
    flex: 1;
    border-radius: 12px;
    box-sizing: border-box;
    height: 9.38rem;
    width: 20rem;
    align-items: center;
    padding: 0.81rem 1rem;
    resize: none;
}

.question-detail-frame-itemresponsive {
    border: 1px solid #e4e4e4;
    background-color: #fff;
    flex: 1;
    border-radius: 12px;
    box-sizing: border-box;
    height: 9.38rem;
    align-items: center;
    padding: 0.81rem 1rem;
    resize: none;
}

.question-detail-input-section {
    align-items: flex-start;
    gap: 1.25rem;
}
.question-detail-input-section {
    top: 0;
    left: 19.69rem;
    flex-direction: column;
    gap: 1rem;
}
.question-detail-save-button-text {
    position: relative;
    font-size: 1rem;
    line-height: 1.13rem;
    font-family: "Nucleo Outline";
    color: #fff;
    text-align: center;
}

.question-detail-delete-button {
    cursor: pointer;
    border: 0;
    padding: 1rem 2.5rem;
    background-color: red;
    color: white;
    top: 24.44rem;
    left: 26.94rem;
    border-radius: 12px;
    width: 100%;
    height: 3.13rem;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    gap: 0.5rem;
    margin-bottom: 40px;
}

.question-detail-create-form {
    position: relative;
    height: 21.56rem;
    text-align: left;
    font-size: 1rem;
    color: #000;
    font-family: "Nucleo Outline";
}
.question-detail-createFormBody {
    display: flex;
    align-items: center;
    justify-content: center;
}

.question-detail-password-check {
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
