.blur {
    filter: blur(5px);
    pointer-events: none;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.image-preview {
    position: relative;
    top: 0;
    left: 0;
    width: 17.19rem;
    height: 17.63rem;
}
.b {
    position: relative;
    line-height: 150%;
    width: 6rem;
}
.frame-child {
    border: 1px solid #e4e4e4;
    background-color: #fff;
    border-radius: 12px;
    box-sizing: border-box;
    width: 25rem;
    height: 4rem;
    align-items: center;
    justify-content: flex-start;
    padding: 0.81rem 1rem;
    resize: none;
}
.frame-select {
    width: 25rem;
    margin-bottom: 1rem;
    font-family: "Nucleo Outline", sans-serif;
}
.parent {
    align-self: stretch;
    align-items: flex-start;
    gap: 1.25rem;
}

.frame-item,
.inputsection,
.parent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.frame-item {
    border: 1px solid #e4e4e4;
    background-color: #fff;
    flex: 1;
    border-radius: 12px;
    box-sizing: border-box;
    height: 9.38rem;
    width: 25rem;
    align-items: center;
    padding: 0.81rem 1rem;
    resize: none;
}

.input-section {
    align-items: flex-start;
    gap: 1.25rem;
}
.input-section {
    position: absolute;
    top: 0;
    left: 19.69rem;
    flex-direction: column;
    gap: 1rem;
}
.save-button-text {
    position: relative;
    font-size: 1rem;
    line-height: 1.13rem;
    font-family: "Nucleo Outline";
    color: #fff;
    text-align: center;
}
.save-button {
    cursor: pointer;
    border: 0;
    padding: 1rem 2.5rem;
    background-color: #000;
    position: absolute;
    top: 24.44rem;
    left: 26.94rem;
    border-radius: 12px;
    width: 24.88rem;
    height: 3.13rem;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    gap: 0.5rem;
}
.delete-button {
    cursor: pointer;
    border: 0;
    padding: 1rem 2.5rem;
    background-color: red;
    position: absolute;
    top: 28.44rem;
    left: 26.94rem;
    border-radius: 12px;
    width: 24.88rem;
    height: 3.13rem;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    gap: 0.5rem;
}
.file-search-button-text {
    position: relative;
    line-height: 1.13rem;
}
.file-search-button {
    position: absolute;
    top: 18.44rem;
    left: 3.13rem;
    border-radius: 12px;
    background-color: #000;
    width: 8.75rem;
    align-items: center;
    justify-content: center;
    padding: 1rem 2.5rem;
    box-sizing: border-box;
    gap: 0.5rem;
    text-align: center;
    color: #fff;
}
.shop-create-form {
    position: relative;
    height: 21.56rem;
    text-align: left;
    font-size: 1rem;
    color: #000;
    font-family: "Nucleo Outline";
}
.createFormBody {
    position: absolute;
    top: 50%;
    left: 30%;
    transform: translate(-50%, -50%);
}
